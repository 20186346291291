import React, { useEffect, useState } from "react";

export default function HandResult({
  result,
  fullResult,
  winState,
}: {
  result: string;
  fullResult: string;
  winState: string;
}) {
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    // Assuming "medium" corresponds to a width of 768px (standard for TailwindCSS)
    if (window.innerWidth >= 768) {
      setIsMediumScreen(true);
    }
  }, []);
  let backGroundColor = "bg-primary-500";
  if (winState === "player") {
    backGroundColor = "bg-green-600";
  } else if (winState === "dealer") {
    backGroundColor = "bg-red-700";
  }
  return (
    <div
      className={`flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white ${backGroundColor} rounded-lg p-2 border-2 border-white shadow-xl shadow-black whitespace-nowrap group`}
    >
      <div className="relative flex items-center justify-center">
        <h1 className="absolute font-bold text-lg md:text-2xl break-keep transform transition-all group-hover:opacity-0 group-hover:scale-0">
          {result ? result : ""}
        </h1>
        <h1 className="absolute text-lg md:text-2xl font-bold break-keep transform scale-0 group-hover:scale-100 opacity-0 group-hover:opacity-100 transition-transform">
          {fullResult ? fullResult : ""}
        </h1>
        <div className="opacity-0 visibility-hidden pl-2 pr-2 text-lg md:text-3xl">
          {result.length > fullResult.length ? result : fullResult}
        </div>
      </div>
    </div>
  );
}