import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { RspsDepositData } from '../../hooks/useRspsDeposits';
import { GoldCoin } from '../Coins';

interface ClaimGoldDepositModalProps {
    open: boolean;
    onClose: () => void;
    deposit: RspsDepositData;
}

const ClaimGoldDepositModal: React.FC<ClaimGoldDepositModalProps> = ({ open, onClose, deposit }) => {
    const { session } = useAuth();
    const [usdAmount, setUsdAmount] = useState('');

    const handleUsdAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsdAmount(event.target.value);
    };


    const handleClaim = async () => {
        try {
            const { data, error } = await supabase.functions.invoke('create-gold-invoice', {
                body: { 
                    user_id: deposit.uuid,
                    vendor_id: session.user.id, 
                    transaction_id: deposit.id,
                    amount: usdAmount
                },
            });

    
            if (data && data.paymentUrl) {
                window.open(data.paymentUrl, '_blank');
                toast.success('Claim process initiated. Please complete the payment.');
                onClose();
            } else {
                throw error;
            }
    
        } catch (error) {
            console.error('Error claiming deposit:', error);
            toast.error('An error occurred while claiming the deposit. Please try again.');
            // Don't close the modal if there's an error
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Claim Gold Deposit
                </Typography>
                <TextField
                    fullWidth
                    type="number"
                    value={usdAmount}
                    onChange={handleUsdAmountChange}
                    label="USD Amount"
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    value={`${deposit.amount} ${deposit.game}`}
                    label="Gold Transaction Amount"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleClaim}>
                        Claim
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ClaimGoldDepositModal;