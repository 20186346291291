import React from 'react';
import ReactMarkdown from 'react-markdown';

interface RulesComponentProps {
    rules: string;
}

const RulesComponent: React.FC<RulesComponentProps> = ({ rules }) => {
    return (
        <div className="rules-container">
            <ReactMarkdown
                components={{
                    h1: ({ node, ...props }) => <h4 {...props} />,
                    h2: ({ node, ...props }) => <h4 {...props} />,
                }}
            >
                {rules}
            </ReactMarkdown>
        </div>
    );
};

export default RulesComponent;