import React from "react";
import GeneralChip from "../GeneralChip";

export default function Twelvebets({
  bets,
  placeBet,
  winningBets
}: Readonly<{
  bets: any;
  placeBet: Function;
  winningBets: string[];
}>) {
  const betTiles = [
    { label: "1st 12", key: "1to12" },
    { label: "2nd 12", key: "13to24" },
    { label: "3rd 12", key: "25to36" },
    { label: "1-18", key: "1to18" },
    { label: "19-36", key: "19to36" },
    { label: "Odd", key: "odd" },
    { label: "Even", key: "even" },
    { label: "", key: "red", className: "bg-red-600", tileClass: "red-tile" },
    { label: "", key: "black", className: "bg-gray-900", tileClass: "black-tile" }
  ];
  const handleMouseEnter = (key) => {
    // Find all elements that should react to hover
    document.querySelectorAll(`.bet-${key}-hover`).forEach(element => {
      element.classList.add('hover-first-row-active');
    });
  };

  const handleMouseLeave = (key) => {
    document.querySelectorAll(`.bet-${key}-hover`).forEach(element => {
      element.classList.remove('hover-first-row-active');
    });
  };

  const renderTile = ({ label, key, className = "bg-neutral-600", tileClass = "grey-tile" }) => (
    <button
      className={`relative ${className} font-bold text-white text-center py-2 border border-neutral-300 cursor-pointer ${winningBets.includes(key) ? "gold-tile" : tileClass}`}
      style={{ width: "100%", height: "50px" }}
      onClick={() => placeBet(key)}
      onMouseEnter={() => handleMouseEnter(key)}
      onMouseLeave={() => handleMouseLeave(key)}
    >
      {label}
      {bets[key] > 0 && <GeneralChip amount={bets[key]} />}
    </button>
  );

  return (
    <>
      <div className="flex flex-col items-center" style={{ width: "calc(33.33%)" }}>
        {renderTile(betTiles[0])}
        <div className="flex w-full">
          {renderTile(betTiles[3])}
          {renderTile(betTiles[5])}
        </div>
      </div>
      <div className="flex flex-col items-center" style={{ width: "calc(33.34%)" }}>
        {renderTile(betTiles[1])}
        <div className="flex justify-between w-full">
          {renderTile(betTiles[7])}
          {renderTile(betTiles[8])}
        </div>
      </div>
      <div className="flex flex-col items-center" style={{ width: "calc(33.33%)" }}>
        {renderTile(betTiles[2])}
        <div className="flex justify-between w-full">
          {renderTile(betTiles[6])}
          {renderTile(betTiles[4])}
        </div>
      </div>
    </>
  );
}

