import React, { useState } from 'react';
import { useVolume } from '../contexts/VolumeContext';
import { MdVolumeOff, MdVolumeDown, MdVolumeUp } from 'react-icons/md';
import { Slider } from '@mui/base/Slider';

interface VolumeControlProps {
    gameId: string;
    disableModification: boolean;
    additionalStyles?: string;
}

const VolumeControl: React.FC<VolumeControlProps> = ({ gameId, disableModification, additionalStyles }) => {
    const { volumes, mutedStates, toggleMute, handleVolumeChange } = useVolume();
    const [showSlider, setShowSlider] = useState(false);

    const getVolumeIcon = () => {
        if (mutedStates[gameId]) return <MdVolumeOff />;
        const volume = volumes[gameId] || 0;
        if (volume < 0.5) return <MdVolumeDown />;
        return <MdVolumeUp />;
    };

    return (
        <div 
            className={`volume-control flex items-center ${additionalStyles}`}
            onMouseEnter={() => setShowSlider(true)}
            onMouseLeave={() => setShowSlider(false)}
        >
            <button 
                onClick={() => toggleMute(gameId)} 
                disabled={disableModification} 
                className={`volume-button mr-2 ${disableModification ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
                {getVolumeIcon()}
            </button>
            {!disableModification && showSlider && (
                <div className="flex-grow">
                    <Slider
                        aria-label="Volume"
                        value={mutedStates[gameId] ? 0 : (volumes[gameId] || 1) * 100}
                        onChange={(_, newValue) => handleVolumeChange(gameId, (newValue as number) / 100)}
                        min={0}
                        max={100}
                        className="volume-slider"
                        slotProps={{
                            root: { className: 'slider-root' },
                            rail: { className: 'slider-rail' },
                            track: { className: 'slider-track' },
                            thumb: { className: 'slider-thumb' },
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default VolumeControl;