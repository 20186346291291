import React from 'react';
import styled from 'styled-components';
import { LuPalmtree } from "react-icons/lu";

// Gold Coin Styles
const GoldCoinStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle at top, #c5a500, #b79500 40%, #a67c00 80%, #c5a500);
  box-shadow: 0px 0px 8px #b79500, inset 0px 0px 8px #b79500;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #503700;
  text-shadow: 0px 0px 2px #000;
`;

// Silver Coin Styles
const SilverCoinStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle at top, #C0C0C0, #A9A9A9 40%, #808080 80%, #C0C0C0);
  box-shadow: 0px 0px 8px #A9A9A9, inset 0px 0px 8px #A9A9A9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #404040;
  text-shadow: 0px 0px 2px #000;
`;

// Gold Coin Component
const GoldCoin: React.FC<{ additionalStyles?: string, style?: React.CSSProperties }> = ({ additionalStyles, style }) => {
  // Calculate icon size based on the height of the container if provided
  const iconSize = style?.height ? parseInt(style.height as string) * 0.75 : "24px"; // Adjust the multiplier as needed
  return (
    <GoldCoinStyle className={additionalStyles} style={style}>
      <LuPalmtree size={iconSize} />
    </GoldCoinStyle>
  );
};

// Silver Coin Component
const SilverCoin: React.FC<{ additionalStyles?: string, style?: React.CSSProperties }> = ({ additionalStyles, style }) => {
  const iconSize = style?.height ? parseInt(style.height as string) * 0.75 : "24px"; // Adjust the multiplier as needed
  return (
    <SilverCoinStyle className={additionalStyles} style={style}>
      <LuPalmtree size={iconSize} />
    </SilverCoinStyle>
  );
};

export { GoldCoin, SilverCoin };