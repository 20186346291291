import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient.js';

const MiscContext = createContext();

export const MiscProvider = ({ children }) => {
    const [userList, setUserList] = useState([]);

    const fetchUserList = async () => {
        const { data, error } = await supabase
            .rpc('get_user_list');
        if (data) setUserList(data);
    };

    useEffect(() => {
        fetchUserList();
    }, []);

    return (
        <MiscContext.Provider value={{ userList }}>
            {children}
        </MiscContext.Provider>
    )
}

export const useMisc = () => {
    return useContext(MiscContext);
}
