import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { IoDiamond } from "react-icons/io5";
import { FaBomb } from "react-icons/fa";
import './Mines.css';
import MinesBettingWindow from './MinesBettingWindow';
import { fetchAndUpdateBalance, supabase } from "../../api/supabaseClient.js";
import withFadeInDelay from "../withFadeInDelay";
import MinesWinningsComponent from './MinesWinningsComponent';
import boom from '../../assets/boom.mp3';
import diamond1 from '../../assets/diamond1.mp3';
import diamond2 from '../../assets/diamond2.mp3';
import diamond3 from '../../assets/diamond3.mp3';
import diamond4 from '../../assets/diamond4.mp3';
import diamond5 from '../../assets/diamond5.mp3';
import diamond6 from '../../assets/diamond6.mp3';
import diamond7 from '../../assets/diamond7.mp3';
import bankpinfail from '../../assets/bankpinfail.mp3';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { toast } from 'react-toastify';
import RulesComponent from '../../components/RulesComponent';
import { minesRules } from '../../components/gameRules';
import { useVolume } from "../../contexts/VolumeContext";
import { useBet } from '../../hooks/useBet';
import { VscDebugRestart } from 'react-icons/vsc';

const FadedInWinningsComponent = withFadeInDelay(MinesWinningsComponent, 100);
const Mines = () => {
  const { signedIn, session } = useAuth();

  const {
    bet,
    setBet,
    handleBetChange,
    handleBetBlur,
    handleHalfBet,
    handleDoubleBet,
  } = useBet(0);

  const [mines, setMines] = useState(6);
  const [gemsLeft, setGemsLeft] = useState(() => 25 - mines);
  const [game, setGame] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [revealed, setRevealed] = useState([]);
  const [multiplier, setMultiplier] = useState(1.0);
  const [nextMultiplier, setNextMultiplier] = useState(1.0);
  const [autoCashoutAmount, setAutoCashoutAmount] = useState(0.0);
  const [autoMultiplier, setAutoMultiplier] = useState(1.0);
  const [winnings, setWinnings] = useState(0.0);
  const [isActive, setIsActive] = useState(false);
  const [tileTypes, setTileTypes] = useState(Array(25).fill(null));
  const [revealedCount, setRevealedCount] = useState(0);
  const [diamondSoundIndex, setDiamondSoundIndex] = useState(0); // New state for tracking diamond sound index
  const [selectedTiles, setSelectedTiles] = useState([]); // New state to track selected tiles
  const [autoSelectedTiles, setAutoSelectedTiles] = useState([]); // New state to store preselected tiles for auto-play mode
  const [mode, setMode] = useState('Manual'); // New state to track the game mode
  const [numberOfGames, setNumberOfGames] = useState(0);
  const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
  const isActiveRef = useRef(false); // New ref to track isActive state
  const [shake, setShake] = useState(false); // New state to manage shake animation
  const [recovering, setRecovering] = useState(true);
  const [showPlayAgain, setShowPlayAgain] = useState(false);

  const diamondSounds = [diamond1, diamond2, diamond3, diamond4, diamond5, diamond6, diamond7];
  const bankPinSound = new Audio(bankpinfail);
  const [shakeButton, setShakeButton] = useState(false);
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();
  const { mutedStates, volumes } = useVolume();
  const [speed, setSpeed] = useState(1);
  const speedRef = useRef(speed);
  useEffect(() => {
    speedRef.current = speed;
  }, [speed])

  const moneyRef = useRef(getActiveBalance());
  useEffect(() => {
    moneyRef.current = getActiveBalance();
  }, [getActiveBalance()]);

  const playSound = (tileType) => {
    let sound;
    if (tileType === 'mine') {
      sound = new Audio(boom);
      sound.volume = 0.25; // Set boom sound volume to 50%
    } else {
      sound = new Audio(diamondSounds[diamondSoundIndex]);
      // Update the diamond sound index for next play
      if (diamondSoundIndex < diamondSounds.length - 1) {
        setDiamondSoundIndex(diamondSoundIndex + 1);
      } else {
        setDiamondSoundIndex(0);
      }
    }
    if (sound) {
      sound.volume = mutedStates['mines'] ? 0 : volumes['mines'];
      if (tileType === 'mine') {
        sound.volume *= 0.25; // Reduce boom sound volume by an additional 50%
      }
      sound.play();
    }
  };

  const showFullGrid = (grid, passedRevelaed = null) => {
    //Grid looks like {type: "gem", index: 0}
    const newRevealed = [...(passedRevelaed || revealed)];
    const newTileTypes = [...tileTypes];
    grid.forEach((element) => {
      newTileTypes[element.index] = element.type;
      newRevealed[element.index].revealed = true;
    });
    setRevealed(newRevealed);
    setTileTypes(newTileTypes);
  }
  const startGame = async () => {
    if (bet > moneyRef.current) {
      toast.error("Insufficient balance. Bet cannot be higher than balance.");
      bankPinSound.play();
      setShakeButton(true);
      setTimeout(() => {
        setShakeButton(false);
      }, 200);
      return;
    }
    try {
      const { data, error } = await supabase.rpc("start_mines", {
        v_bet: bet,
        v_mines: mines,
        v_player_id: session?.user.id,
        v_type: balanceType
      });
      if (error) {
        console.error("Error starting game:", error);
        return;
      }
      setRevealedCount(0);
      setWinnings(0.0);
      setNextMultiplier(0);
      await fetchBalances();
      setGame(data);
      setRevealed(data.revealed);
      setMultiplier(data.multiplier);
      setIsActive(true);
      setGameOver(false);
      setTileTypes(Array(25).fill(null));
      setSelectedTiles([]); // Reset the selected tiles
      setGemsLeft(25 - mines);
    } catch (error) {
      console.error('Error starting game:', error);
    }
  };

  const cashOut = async () => {
    try {
      const { data, error } = await supabase.rpc("mines_cash_out", {
        v_game_id: game.id,
        v_player_id: session?.user.id
      });
      if (error) {
        console.error("Error cashing out:", error);
        return;
      }
      setMultiplier(data.multiplier.toFixed(2));
      setWinnings(data.winnings.toFixed(2));
      await fetchBalances();
      showFullGrid(data.grid);
      setDiamondSoundIndex(0);
      setIsActive(false);
      setRecovering(false);
      setGameOver(true);
    } catch (error) {
      console.error('Error cashing out:', error);
    }
  };

  const handleClick = async (index) => {
    if (mode === 'Auto' && !isActive) {
      if (autoSelectedTiles.length >= 25 - mines && !autoSelectedTiles.includes(index)) {
        toast.error(`You can only select up to ${25 - mines} tiles for auto-play.`);
        bankPinSound.play();
        setShake(true);
        setTimeout(() => setShake(false), 300);
        return;
      }
      setAutoSelectedTiles(prev => {
        if (prev.includes(index)) {
          return prev.filter(i => i !== index);
        } else {
          return [...prev, index];
        }
      });
      return;
    }

    if (!isActive || revealed[index].revealed || gameOver) return;

    setSelectedTiles(prev => [...prev, index]); // Add the clicked tile index to selectedTiles

    try {
      const { data, error } = await supabase.rpc("mines_reveal_tile", {
        v_game_id: game.id,
        v_tile_index: index,
        v_player_id: session?.user.id
      });
      if (error) {
        console.error("Error revealing tile:", error);
        return;
      }
      const newRevealedCount = revealedCount + 1;
      setRevealedCount(newRevealedCount);
      const newRevealed = [...revealed];
      newRevealed[index].revealed = true;
      setRevealed(newRevealed);

      const newTileTypes = [...tileTypes];
      newTileTypes[index] = data.tile_type;
      setTileTypes(newTileTypes);
      setMultiplier(data.multiplier.toFixed(2));
      setNextMultiplier(data.nextmult);

      // Play sound based on the tile type
      playSound(data.tile_type);

      if (data.tile_type === 'mine') {
        showFullGrid(data.grid);
        setDiamondSoundIndex(0);
        setGameOver(true);
        setIsActive(false);
        setRecovering(false);
        return;
      }
      setGemsLeft(prev => prev - 1);
      if (newRevealedCount === 25 - mines) {
        cashOut();
      }
    } catch (error) {
      console.error('Error revealing tile:', error);
      toast.error("Error revealing tile:", error.message);
    }
  };

  const pickRandomUnrevealedTile = () => {
    const unrevealedIndices = revealed
      .map((tile, index) => (tile.revealed ? null : index))
      .filter(index => index !== null);
    if (unrevealedIndices.length > 0) {
      const randomIndex = unrevealedIndices[Math.floor(Math.random() * unrevealedIndices.length)];
      handleClick(randomIndex);
    }
  };

  // Function to handle mode change
  const handleModeChange = (newMode) => {
    setMode(newMode);
    setRevealed([]);
    setSelectedTiles([]);
    setAutoSelectedTiles([]);
    setNumberOfGames(0);
    setGameOver(false);
  };

  const resetGrid = () => {
    setRevealed([]);
    setSelectedTiles([]);
    setAutoSelectedTiles([]);
  };
  const instantPlay = async () => {
    isActiveRef.current = true;
    setIsActive(true);
    let gamesPlayed = 0;

    const intervalId = setInterval(async () => {
      if (gamesPlayed >= numberOfGames || !isActiveRef.current || bet > moneyRef.current) {
        clearInterval(intervalId);
        isActiveRef.current = false;
        setIsActive(false);
        setRevealed([]);
        setSelectedTiles([]);
        setGameOver(true);
        if (bet > moneyRef.current) {
          toast.error("Insufficient balance. Bet cannot be higher than balance.");
          bankPinSound.play();
          setShakeButton(true);
          setTimeout(() => {
            setShakeButton(false);
          }, 200);
        }
        return;
      }

      setGameOver(false);
      setRevealedCount(0);
      setWinnings(0.0);
      setTileTypes(Array(25).fill(null));
      let newRevealed = []
      for (let i = 0; i < 25; i++) {
        newRevealed.push({ revealed: false, index: i })
      }
      setRevealed(newRevealed);
      setSelectedTiles([]); // Reset the selected tiles
      const { data, error } = await supabase.rpc("mines_instant_play", {
        v_player_id: session?.user.id,
        v_bet: bet,
        v_mines: mines,
        v_type: balanceType,
        preselected_grid: autoSelectedTiles,
      });
      if (error) {
        console.error("Error instant playing:", error);
        return;
      }
      setWinnings(data.game.winnings.toFixed(2));
      setMultiplier(data.game.multiplier.toFixed(2));
      playSound(data.tile_type);
      showFullGrid(data.grid, newRevealed);
      await fetchBalances();
      setGameOver(true);

      gamesPlayed++;
    }, 2000 / speedRef.current);


    setAutoPlayIntervalId(intervalId);
  };

  const stopAutoBet = () => {
    if (autoPlayIntervalId) {
      clearInterval(autoPlayIntervalId);
      isActiveRef.current = false;
      setIsActive(false);
      setGameOver(true);
    }
  };

  useEffect(() => {
    return () => {
      if (autoPlayIntervalId) {
        clearInterval(autoPlayIntervalId);
      }
    };
  }, [autoPlayIntervalId]); // Cleanup on component unmount

  useEffect(() => {
    if (autoSelectedTiles.length === 0) {
      setAutoMultiplier(1.0);
      setAutoCashoutAmount(bet);
      return;
    }
    let runningProb = 1.0
    let remainingGems = 25 - mines;
    for (let i = 0; i < autoSelectedTiles.length; i++) {
      const probability = remainingGems / (25 - i);
      runningProb *= probability;
      remainingGems--;
    }
    const newMultiplier = 0.96 / runningProb;
    setAutoMultiplier(newMultiplier.toFixed(2));
    setAutoCashoutAmount((bet * newMultiplier).toFixed(2));
  }, [autoSelectedTiles, bet]);

  useEffect(() => {
    const fetchActiveGame = async () => {
      const { data, error } = await supabase.rpc('mines_fetch_active', {
        v_player_id: session?.user.id,
      });

      if (error) {
        console.error('Error fetching active game:', error);
        return;
      }

      if (data) {
        setGame(data);
        changeBalanceType(data.type)
        setBet(data.bet);
        setMines(data.mine_count);
        setRevealed(data.revealed);
        setMultiplier(data.multiplier);
        setIsActive(true);
        setGameOver(false);
        // Assuming the game grid needs to be reconstructed or updated
        const newTileTypes = Array(25).fill(null);
        const newSelectedTiles = [];
        data.revealed.forEach((reveal, index) => {
          newTileTypes[reveal.index] = reveal.type;
          if (reveal.revealed) {
            newSelectedTiles.push(index); // Add index to selectedTiles if revealed
          }
        });
        setTileTypes(newTileTypes);
        setSelectedTiles(newSelectedTiles); // Update selectedTiles based on revealed data
      } else {
        setRecovering(false);
      }
    };

    if (session?.user.id.length > 0) {
      fetchActiveGame();
    } else {
      setRecovering(false);
    }
  }, [session?.user.id]);

  useEffect(() => {
    setDisableModifications(isActive || isActiveRef.current);
  }, [isActive, isActiveRef.current])
  useEffect(() => {
    return () => {
      setDisableModifications(false);
    }
  }, [])

  // Add this useEffect to handle the delay
  useEffect(() => {
    let timer;
    if (gameOver && mode === 'Manual') {
      timer = setTimeout(() => {
        setShowPlayAgain(true);
      }, 500); // 0.5 second delay
    } else {
      setShowPlayAgain(false);
    }

    return () => clearTimeout(timer);
  }, [gameOver, mode]);

  return (
    <>
      <div className="main-container items-center justify-center">
        <div className="flex md:h-[540px]">
          <MinesBettingWindow
            signedIn={signedIn}
            bet={bet}
            handleAmountChange={handleBetChange}
            handleBetBlur={handleBetBlur}
            handleHalfBet={handleHalfBet}
            handleDoubleBet={handleDoubleBet}
            startGame={startGame}
            gameStarted={isActive}
            mines={mines}
            handleMinesChange={(e) => {
              const newMines = parseInt(e.target.value);
              setMines(newMines);
              setGemsLeft(25 - newMines);
              setAutoSelectedTiles([]);
            }}
            multiplier={multiplier}
            handleCashOut={cashOut}
            selectedTilesLength={selectedTiles.length}
            disableModifications={isActive || recovering}
            pickRandomTile={() => pickRandomUnrevealedTile()}
            onModeChange={(newMode) => handleModeChange(newMode)}
            autoSelectedGridLength={autoSelectedTiles.length}
            resetGrid={() => resetGrid()}
            numberOfGames={numberOfGames}
            setNumberOfGames={(e) => { setNumberOfGames(parseInt(e.target.value)) }}
            startAutoPlay={instantPlay}
            stopAutoPlay={stopAutoBet}
            recovering={recovering}
            shake={shakeButton}
            balanceType={balanceType}
            gemsLeft={gemsLeft}
            minesLeft={mines}
            nextMultiplier={nextMultiplier}
            autoCashoutAmount={autoCashoutAmount}
            autoMultiplier={autoMultiplier}
            speed={speed}
            setSpeed={setSpeed}
          />
        </div>
        <div className="mines-game-grid-container mb-5 md:mb-0 relative">
          {mode === 'Auto' && !isActive && autoSelectedTiles.length === 0 && (
            <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center pointer-events-none">
              <p className="pulsing-text text-xl md:text-3xl text-yellow-400 font-bold">
                Select tiles for auto-play
              </p>
            </div>
          )}
          <div className="mines-game-grid">
            {Array.from({ length: 25 }).map((_, index) => (
              <div
                key={index}
                className={`mines-tile shadow-black shadow-lg 
                  ${revealed[index] && revealed[index].revealed ? 'revealed' : ''} 
                  ${gameOver && selectedTiles.includes(index) ? (tileTypes[index] === 'mine' ? 'bomb-selected' : 'selected') : ''} 
                  ${mode === "Auto" && autoSelectedTiles.includes(index) ? 'auto-selected' : ''} 
                  ${shake ? 'shake-animation' : ''}
                  ${mode === "Auto" && !isActive ? 'auto-selectable' : ''}`}
                onClick={() => handleClick(index)}
              >
                {revealed[index] && revealed[index].revealed ? (
                  tileTypes[index] === 'mine' ? <FaBomb className="mines-icon mines-mine-icon" /> : <IoDiamond className="mines-icon mines-diamond-icon" />
                ) : ''}
              </div>
            ))}
          </div>
          {gameOver && mode === 'Manual' && (
            <div className={`hidden md:block absolute bottom-20 flex items-center justify-center transition-opacity duration-500 ${showPlayAgain ? 'opacity-100' : 'opacity-0'}`}>
              <button
                className="play-again-button"
                onClick={startGame}
                disabled={!showPlayAgain}
              >
                <VscDebugRestart className="mr-2" />
                Play Again
              </button>
            </div>
          )}
          {gameOver && !isNaN(Number(winnings)) && Number(winnings) > 0 && (
            <FadedInWinningsComponent
              multiplier={multiplier}
              winnings={Number(winnings)}
            />
          )}
        </div>
      </div>
      <RulesComponent rules={minesRules} />
    </>
  );
};

export default Mines;
