import React, { useEffect } from "react";
interface TexasWinningsComponentProps {
  winnings: number;
  push: boolean;
  gameOver: boolean;
  name?: string;
  shouldPlaySound?: boolean;
}

const WinningsComponent: React.FC<TexasWinningsComponentProps> = ({
  winnings,
  push,
  gameOver,
  name,
  shouldPlaySound,
}) => {
  const [playSound, setPlaySound] = React.useState(false);
  const [soundPlayed, setSoundPlayed] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const bgColor = push ? "bg-black" : "bg-green-600";
  const audio = new Audio("/winning.mp3");
  useEffect(() => {
    if (gameOver) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [gameOver]);
  useEffect(() => {
    if (playSound) {
      audio.play();
      setSoundPlayed(true);
    }
  }, [playSound]);
  if (!gameOver) return null;
  if (winnings > 0) {
    if (!soundPlayed) {
      setTimeout(() => {
        if (name === "Ante" || (name === "Trips" && shouldPlaySound)) {
          setPlaySound(true);
        }
      }, 200);
    }
    return (
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl font-bold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl shadow-black ${bgColor} border-white border-2 z-10 transition-opacity duration-500 ${
          show ? "opacity-100" : "opacity-0"
        }`}
      >
        ${winnings}
      </div>
    );
  }
};
export default WinningsComponent;
