import React, { useEffect } from "react";
import { useBalance } from "../contexts/BalanceContext.js";
import { GoldCoin, SilverCoin } from "./Coins";
import { text } from "stream/consumers";
import { useVolume } from "../contexts/VolumeContext"; 

interface WinningsComponentProps {
  winnings: number;
  push: boolean;
  gameOver: boolean;
  name?: string;
  shouldPlaySound?: boolean;
  additionalStyles?: string;
}

const WinningsComponent: React.FC<WinningsComponentProps> = ({
  winnings,
  push,
  gameOver,
  name,
  shouldPlaySound,
  additionalStyles
}) => {
  const [playSound, setPlaySound] = React.useState(false);
  const [soundPlayed, setSoundPlayed] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const { balanceType } = useBalance();
  const { mutedStates, volumes, activeGame } = useVolume();
  const textColor = push ? "text-white" : "text-[#05f783]";
  const audio = new Audio("/winning.mp3");
  useEffect(() => {
    if (gameOver) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [gameOver]);
  useEffect(() => {
    if (playSound) {
      audio.volume = mutedStates[activeGame] ? 0 : volumes[activeGame];
      audio.play();
      setSoundPlayed(true);
    }
  }, [playSound]);
  if (!gameOver) return null;
  if (winnings > 0) {
    if (!soundPlayed) {
      setTimeout(() => {
        if (shouldPlaySound) {
          setPlaySound(true);
        }
      }, 200);
    }
    return (
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${textColor} text-2xl font-bold rounded-full w-20 h-20 flex items-center justify-center shadow-2xl shadow-black bg-[#0a0f1c] border-white z-10 select-none transition-opacity duration-500 ${show ? "block" : "hidden"
          } ${additionalStyles}`}
      >
        {/* Render coin component as the first layer with full size */}
        <div className="absolute inset-0 flex items-center justify-center w-full h-full">
          <div className="w-full h-full">
            {balanceType === "gold" ? <GoldCoin style={{ width: '100%', height: '100%' }} /> :
              <SilverCoin style={{ width: '100%', height: '100%' }} />
            }
          </div>
        </div>
        {/* Overlay text on top of the coin */}
        <div className={`absolute inset-0 flex items-center justify-center text-3xl ${textColor}`} style={{ textShadow: '0 0 3px #000, 0 0 5px #000, 0 0 8px #000' }}>
          +{winnings}
        </div>
      </div>
    );
  }
};
export default WinningsComponent;
