
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check active sessions and sets the user
    const initialSession = supabase.auth.getSession();
    setSession(initialSession);
    setLoading(false);

    // Listen for changes to authentication state
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
    });

    // Cleanup listener
    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe();
      }
    };
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error){
        console.error(error);
      };
      setSession(null); // Assuming you want to clear the session on logout
      // Perform any other state updates needed on logout
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const value = {
    session,
    signedIn: !!session,
    loading,
    handleLogout,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
}