import React, { useState } from 'react';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { Modal, Box, Typography, TextField, Button, FormHelperText } from '@mui/material';

interface ChangeEmailModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (currentEmail: string, newEmail: string) => void;
}

const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({ open, onClose, onSave }) => {
    const { session } = useAuth();
    const [currentEmail, setCurrentEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [confirmNewEmail, setConfirmNewEmail] = useState('');
    const [error, setError] = useState('');

    const handleSave = async () => {
        // Existing validations
        if (!currentEmail) {
            setError('Current email is required.');
            return;
        }
        if (!newEmail) {
            setError('New email is required.');
            return;
        }
        if (!newEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setError('Invalid email format');
            return;
        }
        if (newEmail !== confirmNewEmail) {
            setError('New emails do not match.');
            return;
        }

        try {
            // Validate current email
            const { data: { user }, error: userError } = await supabase.auth.getUser();

            if (userError) {
                setError('Failed to get user information.');
                return;
            }

            if (user?.email !== currentEmail) {
                setError('Current email is incorrect.');
                return;
            }

            // If we get here, the current email is correct
            // Proceed with email change
            const { data, error: updateError } = await supabase.auth.updateUser({
                email: newEmail
            });

            if (updateError) {
                setError(updateError.message);
                return;
            }

            // Email changed successfully
            onSave(currentEmail, newEmail);
            onClose();
        } catch (error: any) {
            setError(error.message);
            console.error('Error:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="change-email-modal-title"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box className="bg-background-primary text-text-primary p-8 rounded-lg max-w-md shadow-lg space-y-4">
                <Typography id="change-email-modal-title" variant="h6" className="mb-4">Change Email</Typography>
                <TextField
                    label="Current Email"
                    type="email"
                    value={currentEmail}
                    onChange={(e) => setCurrentEmail(e.target.value)}
                    fullWidth
                    className="mb-4"
                    error={!!error}
                />
                <TextField
                    label="New Email"
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    fullWidth
                    className="mb-4"
                    error={!!error}
                />
                <TextField
                    label="Confirm New Email"
                    type="email"
                    value={confirmNewEmail}
                    onChange={(e) => setConfirmNewEmail(e.target.value)}
                    fullWidth
                    className="mb-1"
                    error={!!error}
                />
                {error && (
                    <FormHelperText
                        error
                        style={{
                            fontSize: '1rem',
                            marginBottom: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
                <div className="flex justify-end">
                    <Button onClick={onClose} className="mr-2">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ChangeEmailModal;