import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { GoldTransactionData } from '../../hooks/useGoldTransactions';
import { GoldCoin } from '../Coins';

interface ClaimModalProps {
    open: boolean;
    onClose: () => void;
    deposit: GoldTransactionData;
}

const ClaimModal: React.FC<ClaimModalProps> = ({ open, onClose, deposit }) => {
    const { session } = useAuth();
    const [usdAmount, setUsdAmount] = useState('');
    const tokenPrice = 0.20; // Price of one RSPS token in USD (adjust as needed)

    const handleUsdAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsdAmount(event.target.value);
    };

    const calculateConversion = () => {
        if (usdAmount === '') return '0';
        const numAmount = parseFloat(usdAmount);
        return (numAmount / tokenPrice).toFixed(2);
    };

    const handleClaim = async () => {
        try {
            const { data, error } = await supabase.functions.invoke('create-rsps-invoice', {
                body: { 
                    user_id: deposit.uuid,
                    vendor_id: session.user.id, 
                    transaction_id: deposit.id,
                    amount: usdAmount
                },
            });
    
            if (data && data.paymentUrl) {
                window.open(data.paymentUrl, '_blank');
                toast.success('Claim process initiated. Please complete the payment.');
                onClose();
            } else {
                console.log('error', error)
                throw error;
            }
    
        } catch (error) {
            console.error('Error claiming deposit:', error);
            toast.error('An error occurred while claiming the deposit. Please try again.');
            // Don't close the modal if there's an error
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Claim RSPS Deposit
                </Typography>
                <TextField
                    fullWidth
                    type="number"
                    value={usdAmount}
                    onChange={handleUsdAmountChange}
                    label="USD Amount"
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    value={`${deposit.amount} ${deposit.game}`}
                    label="RSPS Deposit Amount"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ mb: 2 }}
                />
                <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    ${usdAmount || '0'} USD = {calculateConversion()} <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: '20px', height: '20px' }} /> 
                </Typography>
                <div className='flex items-center'>
                    <GoldCoin additionalStyles='mr-2' />
                    <Typography variant="body2">
                        <span className='text-xl'> = $0.20</span>
                    </Typography>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleClaim}>
                        Claim
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ClaimModal;