import React from 'react';
import './ToggleMenu.css';

interface ToggleMenuProps {
  options: string[];
  selectedOption: string;
  onOptionChange: (option: string) => void;
  disabled?: boolean;
}

const ToggleMenu: React.FC<ToggleMenuProps> = ({ options, selectedOption, onOptionChange, disabled = false }) => {
  return (
    <div className="flex justify-center">
      <div className="homepage-toggle-container">
        {options.map((option, index) => (
          <React.Fragment key={option}>
            <input
              type="radio"
              id={option.toLowerCase()}
              name="mode"
              value={option}
              checked={selectedOption === option}
              onChange={() => onOptionChange(option)}
              disabled={disabled}
            />
            <label htmlFor={option.toLowerCase()} className={`toggle-label ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
              {option}
            </label>
          </React.Fragment>
        ))}
        <div className="homepage-toggle-container toggle-slider"></div>
      </div>
    </div>
  );
};

export default ToggleMenu;