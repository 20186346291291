import React from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: 'hsla(24, 12.82%, 35%, 100%)', // Adjusted to match tailwind config
        },
        secondary: {
            main: 'hsla(204, 19.23%, 45%, 100%)', // Slightly lighter
        },
        info: {
            main: 'hsla(204, 19.23%, 100%, 100%)', // Slightly lighter
        },
        background: {
            default: 'hsla(24, 12.82%, 12%, 100%)', // Adjusted to match tailwind config
            paper: 'hsla(24, 12.82%, 18%, 100%)', // Adjusted to match tailwind config
        },
        text: {
            primary: 'hsla(39, 6.41%, 90%, 100%)', // Adjusted to match tailwind config
            secondary: 'hsla(39, 6.41%, 70%, 100%)', // Adjusted to match tailwind config
        },
    },
    typography: {
        fontFamily: '"CustomFont", "Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 700,
            letterSpacing: '0.05em',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 700,
            letterSpacing: '-0.025em',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 700,
            letterSpacing: '-0.025em',
        },
        body1: {
            fontSize: '1.1rem',
            fontStyle: 'small-caps',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: '8px',
                    padding: '8px 16px',
                    fontWeight: 700,
                },
                outlined: {
                    borderColor: 'hsla(24, 12.82%, 35%, 100%)',
                    '&:hover': {
                        backgroundColor: 'hsla(24, 12.82%, 25%, 100%)',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                },
            },
        },
    },
});

interface CustomThemeProviderProps {
    children: React.ReactNode;
}

const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default CustomThemeProvider;
