import React, { useState, useEffect } from "react";
import { supabase } from "../api/supabaseClient.js";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  Link,
} from "@mui/material";
import background from "../assets/background.png";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import { useBalance } from "../contexts/BalanceContext";
// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'radial-gradient(circle at center, hsla(24, 12.82%, 20%, 100%), hsla(24, 12.82%, 25%, 100%))',
    color: 'hsla(39, 6.41%, 90%, 100%)',
    borderRadius: '12px',
    padding: theme.spacing(3),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: 'hsla(39, 6.41%, 85%, 100%)',
  textAlign: 'center',
  marginBottom: theme.spacing(1),
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-root': {
    color: 'hsla(39, 6.41%, 90%, 100%)',
    backgroundColor: 'hsla(24, 12.82%, 20%, 100%)',
    borderRadius: '8px',
  },
  '& .MuiInputLabel-root': {
    color: 'hsla(39, 6.41%, 85%, 100%)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'hsla(39, 6.41%, 100%, 100%)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'hsla(39, 6.41%, 50%, 50%)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'hsla(39, 6.41%, 70%, 70%)',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'hsla(39, 6.41%, 90%, 100%)',
  },
  // Override autofill styles
  '& .MuiInputBase-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px hsla(24, 12.82%, 20%, 100%) inset',
    '-webkit-text-fill-color': 'hsla(39, 6.41%, 90%, 100%)',
    'transition': 'background-color 5000s ease-in-out 0s',
    'caretColor': 'hsla(39, 6.41%, 90%, 100%)',
  },
  // Remove default focus styles
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: 'hsla(39, 6.41%, 90%, 100%)',
    },
  },
  // New style for helperText
  '& .MuiFormHelperText-root': {
    fontSize: '1.1rem',
    color: 'hsla(39, 6.41%, 90%, 100%)', // Light color for dark mode
  },
  // Style for error state
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'hsla(0, 100%, 78%, 100%)', // Bright red for errors
  },
  // New styles for error state borders and label
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'hsla(0, 100%, 78%, 100%)', // Bright red for error borders
    borderWidth: '2px', // Make the border slightly thicker
  },
  '& .Mui-error.MuiInputLabel-root': {
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'hsla(204, 19.23%, 40%, 100%)',
  color: 'hsla(39, 6.41%, 90%, 100%)',
  fontWeight: 700,
  fontSize: '1.0rem',
  letterSpacing: '0.05em',
  padding: theme.spacing(1, 2),
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'hsla(204, 19.23%, 45%, 100%)',
  },
}));

const StyledLink = styled(Link)({
  color: 'hsla(204, 19.23%, 75%, 100%)',
  '&:hover': {
    color: 'hsla(204, 19.23%, 90%, 100%)',
  },
  fontSize: '1.1rem', // Increased font size
});

function LoginModal({ open, handleClose, isRegisterView = false }) {
  const [isLoginView, setIsLoginView] = useState(!isRegisterView);
  const [isVerificationView, setIsVerificationView] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
  });
  const [submitError, setSubmitError] = useState("");
  const [resetError, setResetError] = useState("");
  const [isResetView, setIsResetView] = useState(false);
  const { referrer } = useBalance();


  const validate = () => {
    let tempErrors = {
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
    };
    let isValid = true;

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      tempErrors.email = "Invalid email format";
      isValid = false;
    }
    if (
      !isLoginView &&
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
    ) {
      tempErrors.password =
        "Password must contain at least one digit, one lowercase, one uppercase letter, and be at least 8 characters long";
      isValid = false;
    }
    if (!isLoginView && password !== confirmPassword) {
      tempErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }
    if (!isLoginView && !username) {
      tempErrors.username = "Username is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  async function isUsernameAvailable(username) {
    const { data, error } = await supabase
      .rpc('is_username_available', { username_to_check: username });

    if (error) {
      console.error('Error checking username:', error);
      return false;
    }

    return data;
  }
  async function isEmailAvailable(email){
    const {data,error} = await supabase.rpc('is_email_available', {email_to_check:email});
    if(error){
      console.error('Error checking email.', error);
      return false;
    }
    return data;
  }
  const handleCloseInternal = () => {
    handleClose();
    setIsResetView(false);
    setIsVerificationView(false);
    setIsLoginView(true);
    setSubmitError("");
    setResetError("");
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        let response;

        if (isLoginView) {
          // User is trying to log in
          response = await supabase.auth.signInWithPassword({
            email,
            password,
          });
        } else {
          // User is trying to register
          const usernameAvailable = await isUsernameAvailable(username);
          if (!usernameAvailable) {
            setSubmitError("Username is already taken.");
            return;
          }
          const emailAvailable = await isEmailAvailable(email);
          if(!emailAvailable){
            setSubmitError("Email already in use.");
            return;
          }
          response = await supabase.auth.signUp({
            email,
            password,
            options: {
              data: {
                username: username,
                referrer: referrer,
              }
            },
          });
        }

        if (response.error) {
          setSubmitError(response.error.message);
        } else {
          if (isLoginView) {
            // Get the user's data after successful login
            const { data: { user } } = await supabase.auth.getUser();
            if (user && user.user_metadata && user.user_metadata.username) {
              updateTawkAttributes(user.user_metadata.username);
            }
            // Handle successful login
            handleCloseInternal();
            // Refresh the page
            window.location.reload();
          } else {
            // For registration, we already have the username
            updateTawkAttributes(username);
            // Show verification view after successful sign up
            setIsVerificationView(true);
          }
        }
      } catch (error) {
        console.error("Authentication Error:", error.message);
        setSubmitError("Incorrect email or password.");
      }
    } else {
      console.log("Validation Failed");
    }
  };

  // Add this new function
  const updateTawkAttributes = (username: string) => {
    if (window.Tawk_API) {
      window.Tawk_API.addTags([username], function(error: any) {
        if (error) {
          console.error('Error adding Tawk tag:', error);
        } else {
          console.log('Tawk tag added successfully');
          console.log(username);
        }
      });

      // Optionally, still set the name attribute for the current session
      window.Tawk_API.setAttributes({
        name: username
      }, function(error: any) {
        if (error) {
          console.error('Error setting Tawk attributes:', error);
        } else {
          console.log('Tawk attributes updated successfully');
        }
      });
    } else {
      console.error('Tawk API not available');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const { error } = await supabase.auth.resetPasswordForEmail(email, {
          redirectTo: 'http://sandcasino.io/reset-password',
        });
        if (error) {
          setResetError(error.message);
        } else {
          setIsVerificationView(true);
        }
      } catch (error) {
        console.error("Reset Password Error:", error.message);
        setResetError("An error occurred while resetting the password.");
      }
    } else {
      setErrors({ ...errors, email: "Email is required" });
    }
  };

  const toggleView = () => {
    setIsLoginView(!isLoginView);
    setErrors({ email: "", password: "", confirmPassword: "", username: "" }); // Reset errors when toggling view
    setSubmitError(""); // Reset submit error when toggling view
    setResetError(""); // Reset reset error when toggling view
  };

  const renderVerificationView = () => (
    <>
      <StyledDialogTitle>Verify Your Email</StyledDialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          We've sent a verification email to:
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center", mb: 3 }}>
          {email}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center", mb: 3 }}>
          Please check your inbox and click on the verification link to complete your registration.
        </Typography>
        <StyledButton
          fullWidth
          onClick={handleCloseInternal}
          sx={{ mt: 2 }}
        >
          Close
        </StyledButton>
      </DialogContent>
    </>
  );
  const renderResetPasswordView = () => (
    <>
      <StyledDialogTitle>Reset Password</StyledDialogTitle>
      {resetError && (
        <Typography variant="body2" sx={{ color: "red", textAlign: "center" }}>
          {resetError}
        </Typography>
      )}
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Enter your email address and we'll send you a link to reset your password.
        </Typography>
        <form onSubmit={handleResetPassword}>
          <StyledTextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <StyledButton
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </StyledButton>
          <Typography variant="body2" align="center">
            <StyledLink
              href="#"
              onClick={() => setIsResetView(false)}
              variant="body2"
            >
              Back to Login
            </StyledLink>
          </Typography>
        </form>
      </DialogContent>
    </>
  );

  const renderLoginSignupView = () => (
    <>
      <StyledDialogTitle>
        {isLoginView ? "Login" : "Sign Up"}
      </StyledDialogTitle>
      {submitError && (
        <Typography variant="body2" sx={{ color: "red", textAlign: "center" }}>
          {submitError}
        </Typography>
      )}
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <StyledTextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          {isLoginView || (
            <StyledTextField
              margin="dense"
              id="Username"
              label="Username"
              type="text"
              fullWidth
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={!!errors.username}
              helperText={errors.username}
            />
          )}
          <StyledTextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          {isLoginView || (
            <StyledTextField
              margin="dense"
              id="confirm-password"
              label="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          )}
          <StyledButton
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            {isLoginView ? "Login" : "Sign Up"}
          </StyledButton>
          <Typography variant="body1" align="center" sx={{ fontSize: '1.1rem' }}> {/* Increased font size */}
            {isLoginView
              ? "Don't have an account?"
              : "Already have an account?"}{" "}
            <StyledLink
              href="#"
              onClick={toggleView}
              variant="body2"
            >
              {isLoginView ? "Sign Up" : "Login"}
            </StyledLink>
          </Typography>
          {isLoginView && (
            <Typography variant="body1" align="center" sx={{ mt: 1, fontSize: '1.1rem' }}> {/* Increased font size */}
              <StyledLink
                href="#"
                onClick={() => setIsResetView(true)}
                variant="body2"
              >
                Forgot Password?
              </StyledLink>
            </Typography>
          )}
        </form>
      </DialogContent>
    </>
  );

  useEffect(() => {
    setIsLoginView(!isRegisterView);
  }, [isRegisterView]);

  return (
    <StyledDialog
      open={open}
      onClose={handleCloseInternal}
      maxWidth="xs"
    >
      {isVerificationView ? renderVerificationView() :
        isResetView ? renderResetPasswordView() :
          renderLoginSignupView()}
    </StyledDialog>
  );
}

export default LoginModal;