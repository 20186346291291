import React from "react";
import { FaEquals } from "react-icons/fa";
import SingleBet from "./SingleBet";


export default function BetsDisplay({
  bet,
  tripsBet,
  playBet,
  gameOver,
  anteWinnings,
  blindWinnings,
  playWinnings,
  tripsWinnings,
  winState
}: {
  bet: number;
  tripsBet: number;
  playBet: number;
  gameOver: boolean;
  anteWinnings: number;
  blindWinnings: number;
  playWinnings: number;
  tripsWinnings: number;
  winState: string;
}) {
  return (
    <div
      id="bets"
      className="hidden lg:block flex flex-col items-center justify-center pb-2 pt-2 min-w-[196px] min-h-[208px]"
    >
      <div id="ante&blind" className="flex items-center justify-center">
        <SingleBet
          key={'ante'}
          bet={bet}
          winnings={anteWinnings}
          gameOver={gameOver}
          name="Ante"
          margin="mr-2"
          anteInfo={true}
          winState={winState}
        />
        <div className="flex items-center justify-center p-2 transform -translate-y-[18px]">
          <FaEquals className="text-lg md:text-4xl text-center text-gray-300" />
        </div>
        <SingleBet
          key={'blind'}
          bet={bet}
          winnings={blindWinnings}
          gameOver={gameOver}
          name="Blind"
          margin="ml-2"
          winState={winState}
        />
      </div>
      <div
        id="play&trips"
        className="flex items-center justify-center relative"
      >
        <SingleBet
          key={'play'}
          bet={playBet}
          winnings={playWinnings}
          gameOver={gameOver}
          name="Play"
          margin="mr-[34px]"
          winState={winState}
        />
        <SingleBet
          key={'trips'}
          bet={tripsBet}
          winnings={tripsWinnings}
          gameOver={gameOver}
          name="Trips"
          margin="ml-[34px]"
          anteInfo={anteWinnings === 0}
          winState={winState}
        />
      </div>
    </div>
  );
}
