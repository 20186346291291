import React, { useEffect, useState, useRef } from "react";
import { useBalance } from "../contexts/BalanceContext.js";
import { GoldCoin, SilverCoin } from "./Coins";
import { useVolume } from "../contexts/VolumeContext";
import confetti from 'canvas-confetti';

interface EnhancedWinningsComponentProps {
  winnings: number;
  push: boolean;
  gameOver: boolean;
  name?: string;
  shouldPlaySound?: boolean;
  additionalStyles?: string;
}

const EnhancedWinningsComponent: React.FC<EnhancedWinningsComponentProps> = ({
  winnings,
  push,
  gameOver,
  name,
  shouldPlaySound,
  additionalStyles
}) => {
  const [show, setShow] = useState(true);
  const [playSound, setPlaySound] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [scale, setScale] = useState(1);
  const textColor = push ? "text-white" : "text-[#05f783]";
  const { balanceType } = useBalance();
  const { volumes, mutedStates, activeGame } = useVolume();

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const coinRef = useRef<HTMLDivElement>(null);


  const ringColor = balanceType === "gold" ? "bg-yellow-400" : "bg-gray-300";
  useEffect(() => {
    // Create audio element only once
    audioRef.current = new Audio("/winning.mp3");
    audioRef.current.preload = "auto";

    return () => {
      // Cleanup
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);


  useEffect(() => {
    if (playSound && audioRef.current) {
      audioRef.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 0.5;
      audioRef.current.play().catch(error => console.error("Audio playback failed:", error));
      setSoundPlayed(true);
    }
  }, [playSound, mutedStates, volumes, activeGame]);

  useEffect(() => {
    if (gameOver && winnings > 0 && coinRef.current) {
      const rect = coinRef.current.getBoundingClientRect();
      const x = (rect.left + rect.width / 2) / window.innerWidth;
      const y = (rect.top + rect.height / 2) / window.innerHeight;

      // Trigger confetti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { x, y }
      });

      // Animate scale and rotation
      let scaleInterval = setInterval(() => {
        setScale(prevScale => prevScale === 1 ? 1.1 : 1);
      }, 300);

      return () => {
        clearInterval(scaleInterval);
      };
    }
  }, [gameOver, winnings]);
  useEffect(() => {
    if (gameOver) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [gameOver]);

  if (!gameOver) return null;
  if (winnings > 0) {
    if (!soundPlayed && shouldPlaySound) {
      setTimeout(() => setPlaySound(true), 200);
    }

    return (
      <div
        ref={coinRef}
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${textColor} text-2xl font-bold rounded-full w-24 h-24 flex items-center justify-center shadow-2xl shadow-black bg-[#0a0f1c] border-white z-10 select-none transition-all duration-300 ${show ? "opacity-100" : "opacity-0"} ${additionalStyles}`}
        style={{ transform: `translate(-50%, -50%) scale(${scale})` }}
      >
        {/* Coin component */}
        <div className="absolute inset-0 flex items-center justify-center w-full h-full">
          <div className="w-full h-full">
            {balanceType === "gold" ? <GoldCoin style={{ width: '100%', height: '100%' }} /> :
              <SilverCoin style={{ width: '100%', height: '100%' }} />
            }
          </div>
        </div>
        {/* Winnings text */}
        <div className={`absolute inset-0 flex items-center justify-center text-4xl ${textColor}`} style={{ textShadow: '0 0 3px #000, 0 0 5px #000, 0 0 8px #000' }}>
          +{winnings}
        </div>
        {/* Pulsing ring effect */}
        <div className={`absolute inset-0 rounded-full animate-ping opacity-75 ${ringColor}`}></div>
      </div>
    );
  }
  return null;
};

export default EnhancedWinningsComponent;