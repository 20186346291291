import React, { useState } from 'react';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { Modal, Box, Typography, TextField, Button, FormHelperText } from '@mui/material';

interface ChangePasswordModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (currentPassword: string, newPassword: string) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ open, onClose, onSave }) => {
    const { session } = useAuth();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSave = async () => {
        // Existing validations
        if (!currentPassword) {
            setError('Current password is required.');
            return;
        }
        if (!newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
            setError('New password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number.');
            return;
        }
        if (newPassword === currentPassword) {
            setError('New password cannot be the same as the current password.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        // Validate current password
        try {
            const { user, error } = await supabase.auth.signInWithPassword({
                email: session?.user?.email, // Assuming you have access to the user's email
                password: currentPassword
            });

            if (error) {
                setError('Current password is incorrect.');
                return;
            }
            const { data, error: updateError } = await supabase.auth.updateUser({
                password: newPassword
            });

            if (updateError) {
                setError('Failed to update password. Please try again.');
                return;
            }
            onSave(currentPassword, newPassword);
            onClose();
        } catch (error) {
            setError('An error occurred. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="change-password-modal-title"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box className="bg-background-primary text-text-primary p-8 rounded-lg max-w-md shadow-lg space-y-4">
                <Typography id="change-password-modal-title" variant="h6" className="mb-4">Change Password</Typography>
                <TextField
                    label="Current Password"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    fullWidth
                    className="mb-4"
                    error={!!error}
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    className="mb-4"
                    error={!!error}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    className="mb-1"
                    error={!!error}
                />
                {error && (
                    <FormHelperText
                        error
                        style={{
                            fontSize: '1rem',
                            marginBottom: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {error}
                    </FormHelperText>
                )}
                <div className="flex justify-end">
                    <Button onClick={onClose} className="mr-2">Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;