import React from "react";
import EnhancedWinningsComponent from "../EnhancedWinningsComponent";

interface WinningsComponentProps {
  gameOver: boolean;
  playerHand: {
    winstate: string;
    blackjack?: boolean;
    bet: number;
    freebet?: boolean;
  };
  index?: number;
}

const WinningsComponent: React.FC<WinningsComponentProps> = ({
  gameOver,
  playerHand,
  index
}) => {
  if (!gameOver) return null;
  if (playerHand.winstate === "Player") {
    return (
      <EnhancedWinningsComponent
        key={gameOver.toString()}
        winnings={playerHand.blackjack
          ? Math.round(
            (Number(playerHand.bet) + Number(playerHand.bet) * (1.5)) * 100
          ) / 100
          : playerHand.bet * 2}
        push={false}
        gameOver={gameOver}
        shouldPlaySound={false}
      />
    );
  }

  if (playerHand.winstate === "Push") {
    return (
      <EnhancedWinningsComponent
        key={gameOver.toString()}
        winnings={playerHand.freebet ? (index && index > 0 ? 0 : playerHand.bet/2) : playerHand.bet}
        push={true}
        gameOver={gameOver}
        shouldPlaySound={false}
      />
    );
  }

  return null;
};

export default WinningsComponent;
