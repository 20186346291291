// DraggableChip.js
import React, { useEffect, useRef } from "react";
import { PiPokerChipFill } from "react-icons/pi";

const DraggableChip = ({ selectedChip, colors, index }) => {
  const chipRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const chip = chipRef.current;
      if (chip) {
        chip.style.position = "fixed";
        chip.style.left = event.clientX - chip.offsetWidth / 2 + "px";
        chip.style.top = event.clientY - chip.offsetHeight / 2 + "px";
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div ref={chipRef} className="fixed pointer-events-none z-20">
      <div className="flex align-center justify-center w-6 lg:w-6 h-6 lg:h-6 rounded-full bg-white relative">
        <PiPokerChipFill
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl lg:text-4xl ${colors[index]} shadow-lg`}
        />
      </div>
      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold z-10 text-xs select-none">
        {selectedChip}
      </p>
    </div>
  );
};

export default DraggableChip;