import React from "react";

export default function MobileBalls() {
  return (
    <>
      <div id="ball00" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[45px] translate-x-[36.5px]"></div>
      <div id="ball0" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[45px] -translate-x-[35px]"></div>
      <div id="ball1" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[37.5px] translate-x-[43.5px]"></div>
      <div id="ball2" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[38px] -translate-x-[42.5px]"></div>
      <div id="ball3" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[3px] translate-x-[56.5px]"></div>
      <div id="ball4" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[4px] -translate-x-[57.5px]"></div>
      <div id="ball5" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[32.5px] translate-x-[47.5px]"></div>
      <div id="ball6" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[32.5px] -translate-x-[47.5px]"></div>
      <div id="ball7" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[53.5px] translate-x-[17.5px]"></div>
      <div id="ball8" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[54px] -translate-x-[17px]"></div>
      <div id="ball9" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[53.5px] -translate-x-[19px]"></div>
      <div id="ball10" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[54px] translate-x-[20px]"></div>
      <div id="ball11" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[56px] translate-x-[8.5px]"></div>
      <div id="ball12" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[56.5px] -translate-x-[8px]"></div>
      <div id="ball13" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[30px] translate-x-[49px]"></div>
      <div id="ball14" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[30px] -translate-x-[47.5px]"></div>
      <div id="ball15" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[6px] translate-x-[56.5px]"></div>
      <div id="ball16" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[5.5px] -translate-x-[57.5px]"></div>
      <div id="ball17" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[40px] translate-x-[42px]"></div>
      <div id="ball18" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[40px] -translate-x-[41.5px]"></div>
      <div id="ball19" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[50px] -translate-x-[25px]"></div>
      <div id="ball20" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[50px] translate-x-[26px]"></div>
      <div id="ball21" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[24px] -translate-x-[52.5px]"></div>
      <div id="ball22" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[25px] translate-x-[52.5px]"></div>
      <div id="ball23" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[13.5px] -translate-x-[56.5px]"></div>
      <div id="ball24" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[12.5px] translate-x-[55px]"></div>
      <div id="ball25" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[56.5px] translate-x-[11px]"></div>
      <div id="ball26" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[56px] -translate-x-[10px]"></div>
      <div id="ball27" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[50px] translate-x-[29px]"></div>
      <div id="ball28" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[50px] -translate-x-[27.5px]"></div>
      <div id="ball29" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[57.5px] translate-x-[1.5px]"></div>
      <div id="ball30" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[57.5px] -translate-x-[1px]"></div>
      <div id="ball31" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[45px] -translate-x-[33.5px]"></div>
      <div id="ball32" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[45px] translate-x-[34px]"></div>
      <div id="ball33" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[15px] -translate-x-[55px]"></div>
      <div id="ball34" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[16px] translate-x-[55px]"></div>
      <div id="ball35" class="absolute w-2 h-2 bg-white rounded-full opacity-0 -translate-y-[22.5px] -translate-x-[52.5px]"></div>
      <div id="ball36" class="absolute w-2 h-2 bg-white rounded-full opacity-0 translate-y-[21.5px] translate-x-[52.5px]"></div>
    </>
  );
}
