import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

export interface RspsDepositData {
  id: number;
  game: string;
  amount: number;
  usd_amount: number | null;
  coins: number | null;
  status: string;
  created_at: string;
  uuid: string;
}

function formatDateTime(isoString: string): string {
  const date = new Date(isoString);
  
  // Format date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  
  // Format time
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatAmount(amount: number, game: string): string {
  const formattedAmount = amount.toLocaleString('en-US', { maximumFractionDigits: 2 });
  switch (game) {
    case 'SpawnPk':
      return `${formattedAmount}T`;
    case 'RuneWild':
      return `${formattedAmount}B`;
    default:
      return formattedAmount;
  }
}

function formatUsdAmount(usd_amount: number | null): string {
  if (usd_amount === null) {
    return "Undecided";
  }
  return `$${usd_amount.toFixed(2)}`;
}

export function useRspsDeposits() {
  const [deposits, setDeposits] = useState<RspsDepositData[]>([]);
  const [mostRecentDeposit, setMostRecentDeposit] = useState<RspsDepositData | null>(null);
  const { session } = useAuth();

  useEffect(() => {
    const fetchRspsDepositData = async () => {
      if (!session?.user?.id) {
        console.error('User not authenticated');
        return;
      }

      const { data, error } = await supabase
        .rpc('get_rsps_deposits', { p_user_id: session.user.id });

      if (error) {
        console.error('Error fetching RSPS deposits:', error);
      } else {
        const formattedDeposits = data.map((deposit: RspsDepositData) => ({
          ...deposit,
          created_at: formatDateTime(deposit.created_at),
          amount: formatAmount(deposit.amount, deposit.game),
          usd_amount: formatUsdAmount(deposit.usd_amount)
        }));
        setDeposits(formattedDeposits);
        
        // Set the most recent deposit
        if (formattedDeposits.length > 0) {
          setMostRecentDeposit(formattedDeposits[0]);
        }
      }
    };

    fetchRspsDepositData();
  }, [session]);

  return { deposits, mostRecentDeposit };
}