import React, { useState } from 'react';
import { Box, Typography, Button, Paper, SvgIcon, Avatar, Chip, Stepper, Step, StepLabel, Link, useMediaQuery, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TagIcon from '@mui/icons-material/Tag';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GoldTransactionModal from '../Wallet/GoldTransactionModal';
import { GoldTransactionData } from '../../hooks/useGoldTransactions';
import boostedMmoLogo from '../../assets/apollologo.gif';

// Custom Discord icon
const DiscordIcon = (props) => (
    <SvgIcon {...props} sx={{ color: 'white' }}>
        <path d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z" />
    </SvgIcon>
);

interface Vendor {
    name: string;
    rating: number;
    totalRatings: number;
    avgDeliveryTime: string;
    minTransaction: string;
    discordLink: string;
    profilePic: string;
}

interface RSVendorListProps {
    mostRecentGoldTransaction: GoldTransactionData | null;
}

const VendorCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: 'hsla(24, 12.82%, 25%, 98%)',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    borderRadius: theme.spacing(3),
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
}));

const VendorHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const VendorInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        textAlign: 'center',
    },
}));

const RatingBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
    },
}));

const DiscordButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#7289da',
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: '#5e73bc',
    },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 80,
    height: 80,
    border: `3px solid ${theme.palette.primary.main}`,
    '& img': {
        objectFit: 'cover',
    },
}));

const StyledStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepLabel-label': {
        color: theme.palette.common.white,
        fontSize: '1rem',  // Increased from 0.9rem to 1rem
        lineHeight: 1.5,   // Added for better readability
    },
    '& .MuiStepIcon-root': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',  // Increased icon size to match larger text
    },
    '& .MuiStepIcon-text': {
        fill: theme.palette.common.white,
        fontSize: '1rem',  // Adjusted number size inside step icon
    },
    '& .MuiStepContent-root': {
        borderColor: theme.palette.primary.main,  // Optional: changes the color of the vertical line
    },
}));

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

const StepLink = styled(Link)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transition: 'background-color 0.3s ease',
    animation: `${pulse} 2s infinite`,
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        animation: 'none',
    },
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-labelContainer': {
        display: 'flex',
        alignItems: 'center',
    },
}));

const VendorItem: React.FC<{ vendor: Vendor; mostRecentGoldTransaction: GoldTransactionData | null; coins: number | null }> = ({ vendor, mostRecentGoldTransaction, coins }) => {
    const [transactionModalOpen, setTransactionModalOpen] = useState(false);
    const [copiedStepIndex, setCopiedStepIndex] = useState<number | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCreateTransaction = () => {
        setTransactionModalOpen(true);
    };

    const handleCloseTransactionModal = () => {
        setTransactionModalOpen(false);
    };

    const handleCopyText = (text: string, stepIndex: number) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedStepIndex(stepIndex);
                setTimeout(() => setCopiedStepIndex(null), 2000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const copyText = mostRecentGoldTransaction
        ? mostRecentGoldTransaction.type === 'deposit'
            ? `Hi, I want to deposit ${mostRecentGoldTransaction.amount} ${mostRecentGoldTransaction.game} on SandCasino.io. My transaction ID is: ${mostRecentGoldTransaction.id}`
            : `Hi, I want to withdraw ${coins} tokens on SandCasino.io. My transaction ID is: ${mostRecentGoldTransaction.id}`
        : "Hi, I want to {deposit gold/withdraw tokens} {Amount} {07 Gold/RS3 Gold/tokens} on SandCasino.io. My transaction ID is: {ID}";

    const steps = [
        { label: 'Create a Gold Transaction Request on Sand Casino', link: null, icon: <AddIcon fontSize="small" sx={{ color: 'white' }} /> },
        { label: 'Join our Discord Server', link: vendor.discordLink, icon: <DiscordIcon fontSize="small" /> },
        { label: 'Navigate to the RS07-gold or RS3-gold Channel', link: 'https://discord.com/channels/827134322414452746/945790739839873125', icon: <LaunchIcon fontSize="small" sx={{ color: '#00b0f4' }} /> },
        { label: 'Create a Ticket and Provide These Transaction Details:', link: null, icon: <ContentCopyIcon fontSize="small" sx={{ color: 'white' }} />, copyText },
        { label: 'Wait for Staff Assistance to Complete Exchange', link: null, icon: null },
    ];

    return (
        <VendorCard elevation={3}>
            <VendorHeader>
                <Box display="flex" alignItems="center" gap={2} flexWrap="wrap" justifyContent={isMobile ? 'center' : 'flex-start'}>
                    <StyledAvatar src={vendor.profilePic} alt={vendor.name} />
                    <VendorInfo>
                        <Typography variant="h5" fontWeight="bold">{vendor.name}</Typography>
                        <Box display="flex" alignItems="center" gap={1} flexWrap="wrap" justifyContent={isMobile ? 'center' : 'flex-start'}>
                            <Chip
                                icon={<VerifiedIcon />}
                                label="Verified Vendor"
                                size="small"
                                color="primary"
                            />
                            <Chip
                                icon={<AccessTimeIcon />}
                                label={`Avg. ${vendor.avgDeliveryTime}`}
                                size="small"
                                variant="outlined"
                            />
                        </Box>
                    </VendorInfo>
                </Box>
                <RatingBox>
                    <StarIcon sx={{ color: 'gold', fontSize: '2rem' }} />
                    <Box>
                        <Typography variant="h6" fontWeight="bold">
                            {vendor.rating.toFixed(1)}%
                        </Typography>
                        <Typography variant="body2">
                            ({vendor.totalRatings} ratings)
                        </Typography>
                    </Box>
                </RatingBox>
            </VendorHeader>
            <Box>
                <Typography variant="h6" gutterBottom>Complete your request in a few clicks:</Typography>
                <StyledStepper activeStep={-1} orientation="vertical">
                    {steps.map(({ label, link, icon, copyText }, index) => (
                        <Step key={label}>
                            <StyledStepLabel>
                                <Box display="flex" alignItems="center">
                                    {label}
                                    {link ? (
                                        <StepLink href={link} target="_blank" rel="noopener noreferrer">
                                            {icon}
                                        </StepLink>
                                    ) : icon ? (
                                        <Box className="copy-wrapper">
                                            <StepLink 
                                                component="button"
                                                onClick={copyText ? () => handleCopyText(copyText, index) : handleCreateTransaction}
                                                aria-label={copyText ? "Copy message" : "Create Transaction Request"}
                                                title={copyText ? "Copy message" : "Create Transaction Request"}
                                            >
                                                {icon}
                                            </StepLink>
                                            {copiedStepIndex === index && (
                                                <Typography variant="caption" className="copy-success">
                                                    Copied!
                                                </Typography>
                                            )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </StyledStepLabel>
                        </Step>
                    ))}
                </StyledStepper>
            </Box>
            {vendor.discordLink !== "null" && (
                <DiscordButton
                    variant="contained"
                    startIcon={<DiscordIcon />}
                    href={vendor.discordLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth
                    size="large"
                >
                    Join Discord to Start Exchange
                </DiscordButton>
            )}
            <GoldTransactionModal open={transactionModalOpen} onClose={handleCloseTransactionModal} />
        </VendorCard>
    );
};

const RSVendorList: React.FC<RSVendorListProps> = ({ mostRecentGoldTransaction, coins }) => {
    const vendors: Vendor[] = [
        {
            name: "Apollo",
            rating: 99,
            totalRatings: 5023,
            avgDeliveryTime: "5 mins",
            minTransaction: "1M Gold",
            discordLink: "https://discord.gg/apollogp",
            profilePic: boostedMmoLogo
        },
        // Add more vendors as needed
    ];

    return (
        <Box>
            <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                Approved Vendors
            </Typography>
            {vendors.map((vendor, index) => (
                <VendorItem 
                    key={index} 
                    vendor={vendor} 
                    mostRecentGoldTransaction={mostRecentGoldTransaction} 
                    coins={coins}
                />
            ))}
        </Box>
    );
};

export default RSVendorList;