import React, { useEffect, useState, useRef } from 'react';
import clickGif from '../assets/red_click.gif';

const ClickGif = () => {
  const [showGif, setShowGif] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      setPosition({ x: event.clientX, y: event.clientY });
      setShowGif(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setShowGif(false);
      }, 200); // Hide after 0.4 seconds
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    showGif && (
      <img
        src={clickGif}
        alt="Click"
        style={{
          position: 'fixed',
          top: position.y,
          left: position.x,
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none',
          zIndex: 1000,
        }}
      />
    )
  );
};

export default ClickGif;