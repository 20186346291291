import React from "react";
import { PiPokerChipFill } from "react-icons/pi";
import determineChipColor from "../../utils/determineChipColor"



export default function Rowbet({
  id,
  text,
  bets,
  placeBet,
  additionalStyles
}: Readonly<{
  id: string;
  text: string;
  bets: any;
  placeBet: Function;
  additionalStyles?: string;
}>) {
  const handleMouseEnter = () => {
    // Find all elements that should react to hover
    document.querySelectorAll(`.bet-${id}-hover`).forEach(element => {
      element.classList.add('hover-first-row-active');
    });
  };

  const handleMouseLeave = () => {
    document.querySelectorAll(`.bet-${id}-hover`).forEach(element => {
      element.classList.remove('hover-first-row-active');
    });
  };

  return (
    <button
      id={id}
      className={`relative min-w-[30px] min-h-[22.5px] md:min-w-[45px] md:min-h-[33.75px] flex items-center justify-center text-white font-bold cursor-pointer bg-neutral-600 aspect-square border border-neutral-300 ${additionalStyles}`}
      onClick={() => placeBet(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
      {bets[id] > 0 && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex align-center justify-center w-6 lg:w-10 h-6 lg:h-10 rounded-full bg-accent-600 relative">
            <PiPokerChipFill
              className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl lg:text-5xl ${determineChipColor(
                bets[id],
              )} shadow-lg`}
            />
          </div>

          <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold z-10 text-sm select-none">
            {bets[id]}
          </p>
        </div>
      )}
    </button>
  );
}
