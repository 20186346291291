import React, { useState, useEffect } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Button,
  AppBarProps,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Menu,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Ensure this is imported
import StyleIcon from "@mui/icons-material/Style";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { GiBatteredAxe, GiCardAceHearts, GiCartwheel, GiDaisy } from "react-icons/gi";
import { LuSwords } from "react-icons/lu";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import { useAuth } from "../contexts/AuthContext";
import { useBalance } from "../contexts/BalanceContext";
import LeftSideBarIcon from "./LeftSideBarIcon";
import background from "../assets/background.png";
import background_brighter from "../assets/background_brighter.png";
import menu from "../assets/menu.png";
import menu_selected from "../assets/menu_selected.png";
import { IoFlowerSharp, IoDiamond } from "react-icons/io5";
import { FaHockeyPuck, FaDice, FaPlus, FaDiscord } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Profile icon
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Exchange icon
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Log Out icon
import accountIcon from "../assets/account_icon.png"; // Import the account icon
import WalletModal from "./Wallet/WalletModal"; // Import the WalletModal component
import logoImage from '../assets/newlogomaybe2.png'; // Import the logo image
import bankpinfail from '../assets/bankpinfail.mp3';
import { SlWallet } from "react-icons/sl";
import { GoldCoin, SilverCoin } from "./Coins";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom'; // Update this import
import { TbCards } from "react-icons/tb";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TbLifebuoy } from "react-icons/tb";
import { usePlayerCount } from "../hooks/usePlayerCount";
import { AiFillGift } from "react-icons/ai";
import { supabase } from "../api/supabaseClient";

// Add this styled component near the top of your file
const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundImage: `url(${background})`, // Dark background color
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
}));

// Define the width of the drawer
const drawerWidth = 280;

// Define the styles for the opened drawer
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Define the styles for the closed drawer
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Define the styles for the drawer header
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

// Define the props for the custom AppBar component
interface CustomAppBarProps extends AppBarProps {
  open?: boolean;
}

// Define the styles for the AppBar component
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<CustomAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


// Define the LeftSidebar component
const LeftSidebar = ({ onToggleSidebar, balanceString }: { onToggleSidebar: () => void; balanceString: string }) => {
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(!isMediumOrSmaller); // Sidebar is initially closed on medium or smaller screens
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { signedIn, handleLogout } = useAuth();
  const { goldBalance, silverBalance, balanceType, changeBalanceType, disableModifications, addSilverBalance } = useBalance();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [walletModalOpen, setWalletModalOpen] = useState(false); // State for Wallet Modal
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [shake, setShake] = useState(false);
  const [acknowledgedFreePlay, setAcknowledgedFreePlay] = useState(false);
  const [addBalanceAcknowledged, setAddBalanceAcknowledged] = useState(false);
  const bankPinSound = new Audio(bankpinfail);
  const navigate = useNavigate(); // Update this line
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const playerCount = usePlayerCount();
  const [isPulsing, setIsPulsing] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      setIsPulsing(prev => !prev);
    }, 1500); // Toggle every 1.5 seconds for a more subtle effect

    return () => clearInterval(interval);
  }, []);

  // Define the styles for the Drawer component
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),
        display: 'flex',
        flexDirection: 'column',
      },
      ...(isMediumOrSmaller && { position: 'absolute' }),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme),
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  }));

  // Function to handle closing the login modal
  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  // Function to handle opening the drawer
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate('/profile'); // Update this line to use navigate
  };

  const handleExchangeClick = () => {
    // Handle exchange click
    setWalletModalOpen(true);
    handleMenuClose();
  };

  const handleLogoutClick = async () => {
    await handleLogout();
    handleMenuClose();
    window.location.reload();
  };

  const handleWalletModalOpen = () => {
    setWalletModalOpen(true);
  };

  const handleWalletModalClose = () => {
    setWalletModalOpen(false);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuAnchorClose = () => {
    setMenuAnchorEl(null);
  };
  const handleSilverAdd = () => {
    if (silverBalance >= 10000) {
      bankPinSound.play();
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 400);
      return
    }
    addSilverBalance();
    setAddBalanceAcknowledged(true);
  }

  const coinSize = isMediumOrLarger ? "26px" : "22px";
  const balanceTextClass = isMediumOrLarger ? "text-[1.75rem]" : "text-[1.25rem]";

  const handleItemClick = () => {
    if (isMediumOrSmaller || open) {
      setOpen(false);
    }
  };

  const handleDiscordClick = async () => {
    window.open('https://discord.gg/QMNgDJVAuX', '_blank');
  };

  const handleRegisterModalClose = () => {
    setIsRegisterModalOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundImage: `url(${background})` }}>
        <Toolbar sx={{ minHeight: '64px', height: '64px', padding: '0 16px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 0, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ backgroundImage: `url(${background})` }} className="flex items-center shadow-lg min-w-[100%] h-16">
            <h1 className="flex-1 font-bold select-none flex items-center">
              <Link
                to="/"
                className="cursor-pointer"
              >
                <img
                  src={logoImage}
                  className="h-auto w-auto object-contain md:ml-4"
                  alt="Sand Casino Logo"
                  style={{
                    padding: '2px 0',
                    maxHeight: isMediumOrLarger ? '64px' : '50px',
                    maxWidth: isMediumOrLarger ? '260px' : '180px',
                  }}
                />
              </Link>
            </h1>

            {signedIn && <div className="flex items-center bg-primary-900 rounded-lg pl-3 h-10 relative">
              {(goldBalance >= 0 || silverBalance >= 0) && (
                <div className="flex items-center h-full pt-1 cursor-pointer" onClick={(event) => { if (menuAnchorEl) handleMenuAnchorClose(); else setMenuAnchorEl(event.currentTarget) }}>
                  <h3 className={`text-[#05f783] leading-none mr-1 ${balanceTextClass} whitespace-nowrap overflow-hidden text-ellipsis max-w-[80px] md:max-w-none`}>
                    {balanceType === 'gold' ? goldBalance : silverBalance}
                  </h3>
                  {balanceType === 'gold' ? (
                    <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                  ) : (
                    <SilverCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                  )}
                  <button
                    aria-controls="balance-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    className={`text-white mt-2 ${disableModifications ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    disabled={disableModifications}
                  >
                    <ArrowDropDownIcon className="align-top" style={{ width: coinSize, height: coinSize, color: '#05f783' }} />
                  </button>
                  <StyledMenu
                    id="balance-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuAnchorClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={() => { changeBalanceType('gold'); handleMenuAnchorClose() }}>
                      <div className="flex items-center w-full justify-end">
                        <h3 className="flex-2 text-[#05f783] leading-none mr-1">
                          {goldBalance}
                        </h3>
                        <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => { changeBalanceType('silver'); handleMenuAnchorClose(); setAcknowledgedFreePlay(true); }}>
                      <div className="flex items-center w-full justify-end">
                        <h3 className="text-[#05f783] leading-none mr-1">
                          {silverBalance}
                        </h3>
                        <SilverCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                      </div>
                    </MenuItem>
                  </StyledMenu>
                </div>
              )}
              {signedIn && balanceType === 'gold' && goldBalance === 0 && !acknowledgedFreePlay && (
                <div
                  className="absolute -bottom-16 left-4 right-0 flex flex-col items-center animate-pulse ml-1"
                  style={{ pointerEvents: 'none' }}
                >
                  <ArrowUpwardIcon sx={{ fontSize: 40, color: '#05f783', fontWeight: 'bold' }} />
                  <Typography variant="caption" sx={{
                    color: '#05f783',
                    fontWeight: 'bold',
                    fontSize: '1.4rem',
                    textShadow: '0 0 3px #000',
                    marginTop: '2px',
                    whiteSpace: 'nowrap',
                  }}>
                    Free Play Available
                  </Typography>
                </div>
              )}
              {signedIn && (
                balanceType === 'gold' ? (
                  <button
                    data-testid='play-button'
                    onClick={handleWalletModalOpen}
                    className="rs-button ml-1 h-full flex items-center justify-center"
                  >
                    <SlWallet />
                  </button>
                ) : (
                  <div className="relative">
                    <button
                      data-testid='play-button'
                      onClick={handleSilverAdd}
                      disabled={disableModifications}
                      className={`rs-button ml-1 h-full flex items-center justify-center ${shake ? "shake-animation" : ""} ${disableModifications ? "cursor-not-allowed" : ""}`}
                      style={{ position: 'relative', width: '40px', height: '40px' }}
                    >
                      <FaPlus />
                    </button>
                    {balanceType === 'silver' && silverBalance === 0 && !addBalanceAcknowledged && (

                      <div className="absolute left-12 top-1/2 -translate-y-1/2 flex items-center animate-pulse">
                        <ArrowBackIcon sx={{ fontSize: 40, color: '#05f783', fontWeight: 'bold' }} />
                        <Typography variant="caption" sx={{
                          color: '#05f783',
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textShadow: '0 0 3px #000',
                          whiteSpace: 'nowrap',
                          marginTop: '2px'
                        }}>
                          Click to add free balance
                        </Typography>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>}
            <div className="flex-1 flex justify-end items-center mr-14">
              {/* Player count display */}
              {playerCount > 0 && (
                <div className="hidden md:block flex justify-end ml-10 mr-8 select-none">
                  <div className="flex items-center bg-primary-900 rounded-full px-2 py-1">
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        marginRight: '8px',
                        backgroundColor: '#4ade80',
                        boxShadow: isPulsing
                          ? '0 0 0 4px rgba(74, 222, 128, 0.1), 0 0 0 2px rgba(74, 222, 128, 0.3)'
                          : '0 0 0 0 rgba(74, 222, 128, 0)',
                        transition: 'all 0.8s ease',
                      }}
                    ></div>
                    <span className="text-white text-md font-medium">{playerCount.toLocaleString()} playing</span>
                  </div>
                </div>
              )}
              {signedIn ? (
                <>
                  <IconButton
                    onClick={handleMenuOpen}
                    color="inherit"
                    sx={{
                      backgroundColor: 'hsla(24, 12.82%, 35%, 100%)', // Tailwind primary-400 color
                      '&:hover': {
                        backgroundColor: 'hsla(204, 19.23%, 45%, 100%)', // Tailwind accent-500 color
                      },
                    }}
                  >
                    <img src={accountIcon} alt="Account" style={{ width: 24, height: 24 }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{ "aria-labelledby": "basic-button" }}
                  >
                    <MenuItem onClick={handleProfileClick}>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={handleExchangeClick}>
                      <ListItemIcon>
                        <SwapHorizIcon />
                      </ListItemIcon>
                      Exchange
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      Log Out
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <div className="flex items-center">
                  <Button
                    variant="outlined"
                    onClick={() => setIsLoginModalOpen(true)}
                    color="inherit"
                    sx={{
                      marginRight: 2,
                      "&:hover": {
                        backgroundColor: "hsla(204, 19.23%, 55%, 100%)",
                      },
                    }}
                  >
                    <span className="font-bold">Login</span>
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setIsRegisterModalOpen(true)}
                    sx={{
                      backgroundColor: "hsla(204, 19.23%, 45%, 100%)",
                      "&:hover": {
                        backgroundColor: "hsla(204, 19.23%, 55%, 100%)",
                      },
                    }}
                  >
                    <span className="font-bold">Register</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <LoginModal open={isLoginModalOpen} handleClose={handleLoginModalClose} />
      <LoginModal open={isRegisterModalOpen} handleClose={handleRegisterModalClose} isRegisterView={true} />
      <Drawer
        variant="permanent"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          display: isMediumOrSmaller && !open ? "none" : "flex",
          "& .MuiDrawer-paper": {
            backgroundImage: `url(${background})`,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "#fff" }}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundImage: `url(${background_brighter})`, height: "4px" }} />
        <List sx={{ flexGrow: 1 }}>
          <Link to="/blackjack" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Blackjack" Icon={StyleIcon} />
          </Link>
          <Link to="/free-bet-blackjack" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Free Bet Blackjack" Icon={TbCards} />
          </Link>
          <Link to="/ultimate-texas" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Ultimate Texas Hold'em" Icon={GiCardAceHearts} />
          </Link>
          <Link to="/duel-arena" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Duel Arena" Icon={LuSwords} />
          </Link>
          <Link to="/dharoks" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Dharoks Deathmatch" Icon={GiBatteredAxe} />
          </Link>
          <Link to="/flower-poker" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Flower Poker" Icon={GiDaisy} />
          </Link>
          <Link to="/roulette" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Roulette" Icon={GiCartwheel} />
          </Link>
          <Link to="/dice" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Dice" Icon={FaDice} />
          </Link>
          <Link to="/plinko" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Plinko" Icon={FaHockeyPuck} />
          </Link>
          <Link to="/mines" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Mines" Icon={IoDiamond} />
          </Link>
        </List>
        <Divider sx={{ backgroundImage: `url(${background_brighter})`, height: "4px" }} />
        <div className="mt-auto">
          <Link to="/exchanges" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Exchanges" Icon={SwapHorizIcon} />
          </Link>
          <Link to="/rewards" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Rewards" Icon={AiFillGift} />
          </Link>
          <Link to="/fairness" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Fairness" Icon={GoLaw} />
          </Link>
          <div onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Support" Icon={TbLifebuoy} />
          </div>
          <div onClick={handleDiscordClick}>
            <LeftSideBarIcon open={open} name="Discord" Icon={FaDiscord} />
          </div>
        </div>
      </Drawer>
      <WalletModal open={walletModalOpen} onClose={handleWalletModalClose} />
    </Box>
  );
};

export default LeftSidebar;