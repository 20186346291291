import React, { useEffect, useState } from "react";

export default function HandResult({
  result,
  winState,
}: {
  result: string;
  winState: string;
}) {
  const [isMediumScreen, setIsMediumScreen] = useState(false);


  let backGroundColor = "bg-primary-500";
  if (winState === "player") {
    backGroundColor = "bg-green-600";
  } else if (winState === "dealer") {
    backGroundColor = "bg-red-700";
  }
  let finalResult = result === '1 Pair' ? 'One Pair' : result === '2 Pair' ? 'Two Pair' : result === '3 of a Kind' ? 'Three Oak' : result === '4 of a Kind' ? 'Four Oak' : result === '5 of a Kind' ? 'Five Oak' : result;

  return (
    <div
      className={`flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white ${backGroundColor} rounded-lg p-2 border-2 border-white shadow-xl shadow-black whitespace-nowrap group select-none`}
    >
      <h1 className="relative font-bold text-lg md:text-2xl break-keep">
        {result ? finalResult : ""}
      </h1>
    </div>
  );
}