import React from 'react'
import Dice from './Dice.css'
import { FaTimes } from "react-icons/fa";

export default function Odds({ multiplier, value }) {
    return (
        <div className="flex justify-between items-end w-full">
            <div className="box-container flex-1 mr-1 text-center">
                <h1 className="text-xs sm:text-sm mb-0.5">Multiplier</h1>
                <div className="w-1/2 h-[1px] bg-gray-400 mx-auto mb-0.5"></div>
                <div className="flex items-center justify-center">
                    <h3 className="text-sm sm:text-base">{(multiplier * 1).toFixed(2)}</h3>
                    <FaTimes className="text-[#ffcc00] text-sm align-middle mb-0.5 md:text-lg" />
                </div>
            </div>
            <div className="box-container flex-1 mx-1 text-center">
                <h1 className="text-xs sm:text-sm mb-0.5">Roll Above</h1>
                <div className="w-1/2 h-[1px] bg-gray-400 mx-auto mb-0.5"></div>
                <h3 className="text-sm sm:text-base font-bold">{value}</h3>
            </div>
            <div className="box-container flex-1 ml-1 text-center">
                <h1 className="text-xs sm:text-sm mb-0.5">Win Chance</h1>
                <div className="w-1/2 h-[1px] bg-gray-400 mx-auto mb-0.5"></div>
                <h3 className="text-sm sm:text-base">
                    {(100 - value)}%
                </h3>
            </div>
        </div>
    )
}