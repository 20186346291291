export const blackjackRules = `
### Blackjack Rules and Gameplay

## Objective
- The goal is to have a hand value closer to 21 than the dealer's without exceeding 21.

## Card Values
- Number cards (2-10) are worth their face value.
- Face cards (Jack, Queen, King) are worth 10 points each.
- Aces can be worth 1 or 11 points, whichever benefits the hand more.

## Gameplay
1. **Initial Deal**
   - The game is played with a total of 4 decks, reshuffled after each hand.
   - The player and the dealer receive two cards each.
   - Players' cards are typically dealt face-up, while the dealer has one card face-up (the "upcard") and one face-down (the "hole card").

2. **Player's Turn**
   - Players decide how to play their hands based on the value of their cards and the dealer's upcard.
   - **Hit** &nbsp; Take another card from the dealer.
   - **Stand** &nbsp; Keep the current hand and end your turn.
   - **Double Down** &nbsp; Double the initial bet and receive one more card.
   - **Split** &nbsp; If the first two cards are of equal value, split them into two separate hands and play each hand individually. Re-splitting Aces is not allowed, however you can hit or double down on them after splitting.

3. **Dealer's Turn**
   - After all players have finished their turns, the dealer reveals their hole card.
   - The dealer must hit until their hand totals 17 or higher. Dealer hits on soft 17.

4. **Outcome**
   - If the player's hand exceeds 21, they bust and lose the bet.
   - If the dealer busts, the player wins.
   - If neither busts, the hand closest to 21 wins.
   - A tie, or "push," results in no loss or gain for the player.

## Winning
- **Blackjack** &nbsp; An Ace and a 10-point card dealt initially result in a "Blackjack," paying out 3 to 2.
- **Regular Win** &nbsp; If the player's hand is closer to 21 than the dealer's, the player wins even money (1 to 1).

## Insurance
- If the dealer's upcard is an Ace, players can take "insurance" against the dealer having a Blackjack. This side bet pays 2 to 1 if the dealer has Blackjack.

## Side Bets
- **21+3** &nbsp; Combines the player's two cards and the dealer's upcard to form a three-card poker hand. Payouts vary based on the poker hand formed, such as flush, straight, three of a kind, etc.
- **Perfect Pairs** &nbsp; Pays out if the player's initial two cards are a pair. Payouts vary depending on whether the pair is mixed, colored, or a perfect pair.

## Basic Strategy Tips
- Always stand on a hand of 17 or higher.
- Hit on a hand of 11 or lower.
- Double down on 11 against a dealer's lower card.
- Split Aces and 8s, but avoid splitting 10s or 5s.
`;

export const freebetRules = `### Free Bet Blackjack Rules and Gameplay

## Objective
- The goal is to have a hand value closer to 21 than the dealer's without exceeding 21.

## Card Values
- Number cards (2-10) are worth their face value.
- Face cards (Jack, Queen, King) are worth 10 points each.
- Aces can be worth 1 or 11 points, whichever benefits the hand more.

## Gameplay
1. **Initial Deal**
   - The game is played with multiple decks, reshuffled after each hand.
   - The player and the dealer receive two cards each.
   - Players' cards are dealt face-up, while the dealer has one card face-up (the "upcard") and one face-down (the "hole card").

2. **Player's Turn**
   - Players decide how to play their hands based on the value of their cards and the dealer's upcard.
   - **Hit** &nbsp; Take another card from the dealer.
   - **Stand** &nbsp; Keep the current hand and end your turn.
   - **Free Double Down** &nbsp; Double your bet for free on hard 9, 10, or 11.
   - **Free Split** &nbsp; Split pairs for free, except 10-value cards.
   - **Regular Double Down/Split** &nbsp; Available for hands not eligible for free options.

3. **Dealer's Turn**
   - After all players have finished their turns, the dealer reveals their hole card.
   - The dealer must hit until their hand totals 17 or higher.

4. **Outcome**
   - If the player's hand exceeds 21, they bust and lose the bet.
   - If the dealer busts, the player wins, unless the dealer has 22 (see Push 22 rule).
   - If neither busts, the hand closest to 21 wins.
   - A tie, or "push," results in no loss or gain for the player.
   - Free bets are NOT paid on a push.

## Special Rules
- **Push 22** &nbsp; If the dealer's hand totals 22, all player bets push (tie) except for Blackjack.
- **Free Bet Blackjack** &nbsp; Pays 3 to2 and beats all other hands, including dealer 22.

## Winning
- **Blackjack** &nbsp; An Ace and a 10-point card dealt initially, pays 3 to 2.
- **Regular Win** &nbsp; If the player's hand is closer to 21 than the dealer's, the player wins even money (1 to 1).

## Side Bets
1. **Pot of Gold**
   Payout based on the number of Free Bets used during the hand
   - **5 or more Free Bets** &nbsp; 100 to 1
   - **4 Free Bets** &nbsp; 50 to 1
   - **3 Free Bets** &nbsp; 25 to 1
   - **2 Free Bets** &nbsp; 12 to 1
   - **1 Free Bet** &nbsp; 3 to 1

2. **Push 22**
   - **Dealer 22** &nbsp; 11 to 1

## Insurance
- If the dealer's upcard is an Ace, players can take "insurance" against the dealer having a Blackjack. This side bet pays 2 to 1 if the dealer has Blackjack.`;
export const rouletteRules = `### Roulette Rules and Gameplay

## Objective
- The goal is to predict where the ball will land on the spinning roulette wheel.

## Roulette Wheel and Table Layout
- The wheel has numbered pockets ranging from 1 to 36, alternating in red and black colors.
- There are also one or two green pockets for 0 and 00.
- The table layout corresponds to the numbers on the wheel, with various betting options.

## Types of Bets
1. **Inside Bets**
   - **Straight Up** &nbsp; Bet on a single number &nbsp; **pays 35 to 1**.

2. **Outside Bets**
   - **Red or Black** &nbsp; Bet on the color of the winning number &nbsp; **pays 1 to 1**.
   - **Odd or Even** &nbsp; Bet on whether the winning number is odd or even &nbsp; **pays 1 to 1**.
   - **Low or High** &nbsp; Bet on low (1-18) or high (19-36) numbers &nbsp; **pays 1 to 1**.
   - **Dozens** &nbsp; Bet on one of three groups of twelve numbers (1-12, 13-24, 25-36) &nbsp; **pays 2 to 1**.
   - **Columns** &nbsp; Bet on one of the three columns of twelve numbers &nbsp; **pays 2 to 1**.

## Gameplay
1. **Placing Bets**
   - Players place their chips on the table to make their bets before the croupier (dealer) spins the wheel and ball.
   - Bets can be placed on individual numbers, colors, and other categories.

2. **Spinning the Wheel**
   - The croupier spins the wheel in one direction and the ball in the opposite direction.

3. **Determining the Winner**
   - The ball eventually lands in one of the numbered pockets.
   - The winning number and color are announced, and the croupier places a marker on the winning number on the table.
   - Winning bets are paid out according to the payout rates, and losing bets are collected by the house.
`;

export const flowerPokerRules = `### Flower Poker Rules and Gameplay

## Objective
- The goal is to have a more valuable set of flowers than your opponent, following the planting and comparison of flowers.

## Gameplay
1. **Initial Setup**
   - Players place a bet on the Player Bet and an optional 3 Oak side bet.
   - Each player receives 5 seeds.

2. **Planting Seeds**
   - Players take turns planting their seeds. 
   - The sequence typically follows Player 1 plants all their seeds, then Player 2 plants theirs.

3. **Growing Flowers**
   - After planting, each seed grows into a flower, which can be one of several colors. The possible colors are Red, Blue, Yellow, Orange, Pink, and Violet.

4. **Comparing Hands**
   - Once all flowers are grown, players compare their sets based on the value of the flower combinations.

## Flower Combinations and Rankings
1. **Five of a Kind** &nbsp; Five flowers of the same color.
2. **Four of a Kind** &nbsp; Four flowers of the same color.
3. **Full House** &nbsp; Three flowers of one color and two flowers of another color.
4. **Three of a Kind** &nbsp; Three flowers of the same color.
5. **Two Pair** &nbsp; Two flowers of one color and two flowers of another color.
6. **One Pair** &nbsp; Two flowers of the same color.
7. **No Pair** &nbsp; No flowers of the same color.

## Winning
- The player with the higher-ranked combination of flowers wins the game.
- **Player Bet Payout** 
  - Winning the player bet pays out at 1.92x the bet amount.
- **3 Oak Side Bet Payout** 
  - The 3 Oak side bet pays out regardless of the House's flowers
  - **Five of a Kind** &nbsp; 50 to 1
  - **Four of a Kind** &nbsp; 10 to 1
  - **Full House** &nbsp; 4 to 1
  - **Three of a Kind** &nbsp; 2 to 1
`;

export const texasHoldemRules = `### Ultimate Texas Hold'em Rules and Gameplay

## Objective
- The goal is to form the best possible five-card poker hand using your two hole cards and the five community cards, and to beat the dealer's hand.

## Betting Structure
1. **Initial Bets**
   - Players place two equal bets an "Ante" and a "Blind."
   - Players can also place an optional "Trips" bonus bet.

## Gameplay
1. **Hole Cards**
   - Each player and the dealer receive two hole cards.

2. **Pre-Flop Play**
   - After viewing their hole cards, players can either
     - **Check** Do nothing and move to the next round.
     - **Bet** Place a "Play" bet of 3x or 4x the Ante.

3. **The Flop**
   - The dealer reveals the first three community cards (the "Flop").
   - Players who have not yet made a Play bet can now either
     - **Check** Do nothing and move to the next round.
     - **Bet** Place a Play bet of 2x the Ante.

4. **The Turn and River**
   - The dealer reveals the final two community cards (the "Turn" and the "River").
   - Players who have not yet made a Play bet must now either
     - **Fold** Forfeit the Ante and Blind bets.
     - **Bet** Place a Play bet equal to the Ante.

5. **Showdown**
   - The dealer reveals their hole cards and compares hands with each player.
   - The best five-card poker hand wins.

## Hand Rankings (High to Low)
1. Royal Flush
2. Straight Flush
3. Four of a Kind
4. Full House
5. Flush
6. Straight
7. Three of a Kind
8. Two Pair
9. One Pair
10. High Card

## Payouts
- **Ante and Play Bets**
  - If the player's hand beats the dealer's hand, these bets pay 1 to 1.
  - If the dealer does not have at least a pair, the Ante bet pushes (returns to the player).

- **Blind Bet**
  - If the player wins, this bet pays based on the player's hand ranking.
  - **Payouts**
    - **Royal Flush** &nbsp; 200 to 1
    - **Straight Flush** &nbsp; 50 to 1
    - **Four of a Kind** &nbsp; 10 to 1
    - **Full House** &nbsp; 3 to 1
    - **Flush** &nbsp; 3 to 2
    - **Straight** &nbsp; 1 to 1
    - **Less than a Straight** &nbsp; Push

- **Trips Bonus Bet**
  - Pays based on the player's final hand regardless of the dealer's hand.
  - **Payouts**
    - **Royal Flush** &nbsp; 50 to 1
    - **Straight Flush** &nbsp; 40 to 1
    - **Four of a Kind** &nbsp; 30 to 1
    - **Full House** &nbsp; 8 to 1
    - **Flush** &nbsp; 7 to 1
    - **Straight** &nbsp; 4 to 1
    - **Three of a Kind** &nbsp; 3 to 1
`;
export const duelArenaRules = `
### Duel Arena Rules and Gameplay

## Objective
- The goal is to reduce your opponent's health to zero before your own health runs out.

## Gameplay
1. **Initial Setup**
   - Players place a bet on the Player Bet and an optional Big 25 side bet.
   - Each player starts with 99 health points.

2. **Combat**
   - Players take turns attacking each other.
   - Each attack reduces the opponent's health by a random amount between 0 and 25.
   - The house gets the first hit.
   - The player on the left is the player, and the player on the right is the house.

3. **Winning**
   - The game ends when one player's health reaches zero.
   - If the player's health reaches zero first, the house wins.
   - If the house's health reaches zero first, the player wins.

## Payouts
- **Player Bet Payout** 
  - If the player wins, they receive 2.15x their Player Bet.
- **Big 25 Side Bet Payout** 
  - The Big 25 side bet pays out based on the number of 25s hit during the fight
  - **Three 25s** &nbsp; 20 to 1
  - **Two 25s** &nbsp; 6 to 1
  - **One 25** &nbsp; 1 to 1
  - **Player Win Push** &nbsp; If the player wins, the Big 25 side bet pushes (returns to the player).
`;
export const dharokDeathmatchRules = `### Dharok Deathmatch Rules and Gameplay

## Objective
- The goal is to reduce your opponent's health to zero before your own health runs out.

## Gameplay
1. **Initial Setup**
   - Players place a bet on the Player Bet.
   - Each player starts with 99 health points.
   - Both players wear full Dharok's equipment, which increases damage as health decreases.

2. **Combat**
   - Players take turns attacking each other.
   - The first hit is determined randomly.
   - Each attack reduces the opponent's health by a random amount.
   - Damage increases as the attacker's health decreases, following the Dharok's set effect.
   - The player on the left is the player, and the player on the right is the house.

3. **Dharok's Set Effect**
   - The lower the attacker's health, the higher the potential damage.
   - Maximum damage potential is reached when health is at its lowest.

4. **Winning**
   - The game ends when one player's health reaches zero.
   - If the player's health reaches zero first, the house wins.
   - If the house's health reaches zero first, the player wins.

## Payouts
- **Player Bet Payout** 
  - If the player wins, they receive 1.92x their Player Bet.
`;

export const plinkoRules = `
### Plinko Rules and Gameplay

**Objective**
- The goal is to drop a disc from the top of a peg-filled board and have it land in a slot at the bottom, aiming for the highest-value slot to win the most points or prizes.

**Plinko Board Layout**
- The board consists of a series of pegs arranged in a triangular pattern.
- At the bottom of the board are slots with different point values or prize amounts.

**Gameplay**
1. **Disc Drop**
   - Players are given a certain number of discs to drop (usually between 1 to 5).
   - The player selects a starting position at the top of the board and releases the disc.

2. **Navigating the Pegs**
   - As the disc falls, it bounces off the pegs, creating a random path down the board.
   - The direction of each bounce is unpredictable, making each drop unique.

3. **Landing in a Slot**
   - The disc eventually lands in one of the slots at the bottom.
   - Each slot has a different point value or prize amount.

**Winning**
- The player's score or prize is determined by the slots in which their discs land.
- The objective is to land the discs in the highest-value slots to maximize the score or win the best prizes.

**Strategy Tips**
- **Starting Position** Choose the starting position carefully, as it can influence the initial path of the disc.
- **Observe Patterns** Watch how previous discs fall to identify any patterns or tendencies.
- **Stay Calm** Each drop is random, so remain calm and enjoy the game without focusing too much on control.

**Variations**
- **Point-Based** Players accumulate points based on the slots the discs land in, and the highest score wins.
- **Prize-Based** Each slot corresponds to a specific prize, and the disc determines which prize the player wins.

**Excitement Factor**
- Plinko is known for its randomness and the excitement of watching the disc's unpredictable journey down the board.
- The anticipation builds as the disc bounces off the pegs, making each drop thrilling.
`;

export const minesRules = `
### Mines Rules and Gameplay

## Objective
- The goal is to reveal as many safe tiles as possible without hitting a mine to maximize your winnings.

## Gameplay
1. **Initial Setup**
   - Players start by selecting the number of mines hidden within the grid.
   - The grid is a series of hidden tiles, some of which contain mines.

2. **Starting the Game**
   - Players make an initial click to reveal a tile.

3. **Revealing Tiles**
   - Each subsequent click reveals more tiles.
   - Players can choose to cash out their winnings at any time based on the number of safe tiles revealed.

## Winning
- The game is won by successfully revealing all safe tiles without detonating any mines.
- Players can choose to cash out their winnings at any time based on the number of safe tiles revealed.

## Losing
- The game ends if a mine is revealed, resulting in a loss.

## Autoplay
- Players can use the autoplay feature to automate the game.
- **Setup** &nbsp; Select the tiles you want to reveal and the number of games you want to play.
- **Execution** &nbsp; The game will automatically play the selected number of games, revealing the chosen tiles in each game.
- **Outcome** &nbsp; Autoplay will stop if the play clicks Stop Autobet or the selected number of games is completed.

`;
