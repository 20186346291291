import React from "react";
import determineChipColor from "../utils/determineChipColor";
import { PiPokerChipFill } from "react-icons/pi";

export default function GeneralChip({amount}:{amount:number}, {additionalStyles}:{additionalStyles:string}) {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className={`flex align-center justify-center w-6 lg:w-10 h-6 lg:h-10 rounded-full bg-gray-300 relative ${additionalStyles}`}>
        <PiPokerChipFill
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl lg:text-5xl ${determineChipColor(
            amount
          )} shadow-lg`}
        />
      </div>

      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold text-sm select-none">
        {amount}
      </p>
    </div>
  );
}
