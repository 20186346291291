import React, { useEffect, useRef, useState } from "react";
import { Hand } from "../../objects/Ihand";
import CardComponent from "./CardComponent";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../GameBoard.css";
import { useVolume } from "../../contexts/VolumeContext"; // Import useVolume hook

interface HandComponentProps {
  name: string; // 'dealer' or 'player'
  hand?: Hand;
  dealerHand?: Hand;
  active_hand_index?: number;
  handsLength?: number;
  onDealerFinishing?: () => void;
  dealersTurn?: boolean;
  cardImages: any;
  freeBet?: boolean;
  soft?: boolean;
  speed?: number;
}

const HandComponent: React.FC<HandComponentProps> = ({
  name,
  hand,
  dealerHand,
  onDealerFinishing,
  active_hand_index,
  handsLength,
  dealersTurn,
  cardImages,
  freeBet,
  soft,
  speed
}) => {
  const { volumes, mutedStates,activeGame } = useVolume(); // Use the useVolume hook to get volumes
  const audio = useRef(new Audio("/flipcard2.mp3")); // Use useRef to create the audio element
  const [displayedCards, setDisplayedCards] = useState<number>(0);
  const [dealerDisplayedCards, setDealerDisplayedCards] = useState<number>(0);
  const [cardAnimations, setCardAnimations] = useState<string[]>([]);
  const [restored, setRestored] = useState<boolean>(false);
  let currentIndex = useRef<number>(1);
  let currentLength = useRef<number>(1);
  let delay = speed ? 500 / speed : 500;

  useEffect(() => {
    setCardAnimations(new Array(2).fill("card-slide"));
  }, []);

  useEffect(() => {}, [active_hand_index]);

  useEffect(() => {
    if (dealersTurn && name === "dealer") {
      const updatedAnimations = [...cardAnimations];
      updatedAnimations[1] = "card-flip"; // updating for the dealer's first card
      setCardAnimations(updatedAnimations);
    }
  }, [dealersTurn, name]);

  useEffect(() => {
    if (hand?.winstate !== "") {
      return;
    }
    let currentDisplay = displayedCards;
    if (
      (active_hand_index && active_hand_index > currentIndex.current) ||
      (handsLength && handsLength > currentLength.current)
    ) {
      setDisplayedCards(1);
      currentDisplay = 1;
      currentIndex.current = active_hand_index
        ? active_hand_index
        : currentIndex.current;
      currentLength.current = handsLength ? handsLength : currentLength.current;
    }
    if (name === "player" && hand) {
      if (hand.cards.length > currentDisplay) {
        if (hand.cards.length > 2 && !restored) {
          // If restoring an old game state, set displayedCards to the current hand length
          setDisplayedCards(hand.cards.length);
          setRestored(true);
        } else {
          setRestored(true);
          for (let idx = currentDisplay; idx < hand.cards.length; idx++) {
            setTimeout(
              () => {
                setDisplayedCards(idx + 1); // to reveal the next card

                // Play audio for other conditions
                audio.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 1; // Set the volume for blackjack
                audio.current.play();
              },
              idx < 2 ? idx * delay : 300
            );
          }
        }
      }
    }
  }, [hand, active_hand_index, handsLength, volumes]);

  useEffect(() => {
    if (name === "dealer" && dealerHand) {
      if (dealerHand.cards.length > dealerDisplayedCards) {
        for (
          let idx = dealerDisplayedCards;
          idx < dealerHand.cards.length;
          idx++
        ) {
          setTimeout(() => {
            setDealerDisplayedCards(idx + 1); // to reveal the next card
            if (idx < 2) return;
            audio.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 1; // Set the volume for blackjack
            audio.current.play();
          }, idx * delay);
        }
      }
    }
  }, [dealerHand, volumes]);

  useEffect(() => {
    if (
      dealerHand &&
      dealerHand.cards.length === dealerDisplayedCards &&
      name === "dealer" &&
      dealersTurn
    ) {
      if (onDealerFinishing) {
        setTimeout(() => {
          onDealerFinishing();
        }, delay);
      }
    }
  }, [dealerDisplayedCards, dealerHand, dealersTurn]);

  const currentHand = name === "dealer" ? dealerHand : hand;
  return (
    <TransitionGroup component={null}>
      {currentHand?.cards.map((card, index) => {
        const animationClass = cardAnimations[index] || "card-slide";
        let displayed =
          name === "dealer" ? dealerDisplayedCards : displayedCards;

        // Conditional check for rendering
        if (index + 1 > displayed) return null;
        return (
          <CSSTransition
            unmountOnExit
            key={`${name}${index}${animationClass}`}
            timeout={5000}
            classNames={animationClass}
          >
            <CardComponent
              key={`${name}${index}${Math.random()}${
                active_hand_index ? active_hand_index : "null"
              }}`}
              card={card}
              lastCard={index === currentHand.cards.length - 1}
              showCount={true}
              count={currentHand.count}
              active={true}
              cardImages={cardImages}
              freeBet={freeBet}
              soft={soft}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

export default HandComponent;