import { useState } from 'react';

const MAXBET = 5000;

export const useBet = (initialBet = 0) => {
  const [bet, setBet] = useState(initialBet);

  const roundToTwoDecimals = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  };

  const handleBetChange = (e: { target: { value: any; }; }) => {
    let value = Number(e.target.value);
    if (value > MAXBET) {
      value = MAXBET;
    }
    setBet(value); // Set the exact value during input
  };

  const handleBetBlur = () => {
    setBet(roundToTwoDecimals(bet)); // Round when input loses focus
  };

  const handleHalfBet = () => {
    const newBet = bet / 2 < 1 ? 1 : bet / 2;
    setBet(roundToTwoDecimals(newBet));
  };

  const handleDoubleBet = () => {
    const newBet = bet * 2 > MAXBET ? MAXBET : bet * 2;
    setBet(roundToTwoDecimals(newBet));
  };

  return {
    bet,
    setBet,
    handleBetChange,
    handleBetBlur,
    handleHalfBet,
    handleDoubleBet,
  };
};