import React, { useEffect } from "react";
import { PiPokerChipFill } from "react-icons/pi";
import WinningsComponent from "./GenericWinningsComponent";
import withFadeInDelay from "./withFadeInDelay";

const FadedInWinningsComponent = withFadeInDelay(WinningsComponent, 500);
export default function SingleBet({
  bet,
  winnings,
  gameOver,
  name,
  margin,
  anteInfo,
  winState,
  sideBet,
  push
}: {
  bet: number;
  winnings: number;
  gameOver: boolean;
  name: string;
  margin?: string;
  anteInfo?: boolean;
  winState?: string;
  sideBet?: boolean;
  push?: boolean;
}) {
  const determineChipColor = (bet: number) => {
    if (bet < 5) return "text-blue-400";
    if (bet < 25) return "text-red-600";
    if (bet < 100) return "text-green-600";
    if (bet < 500) return "text-gray-800";
    if (bet >= 500) return "text-purple-500";
  };
  const [show, setShow] = React.useState(true);
  const [borderColor, setBorderColor] = React.useState("border-gray-400");
  useEffect(() => {
    if (gameOver) {
      if (bet > 0) {
        if (winnings > 0) {
          setTimeout(() => {
            setShow(false);
          }, 300);
          setTimeout(() => {
            setShow(true);
          }, 3300);
        } else {
          setTimeout(() => {
            setBorderColor("border-red-600");
          }, 200);
          setTimeout(() => {
            setBorderColor("border-gray-400");
          }, 3500);
        }
      }
    }
  }, [gameOver]);
  useEffect(() => {
    if (winState === "") {
      setBorderColor("border-gray-400");
      setShow(true);
    }
  }, [winState]);

  return (
    <div
      id={name}
      className={`flex flex-col items-center justify-center ${margin} relative`}
    >
      <div
        className={`flex items-center justify-center ${borderColor} border-4 rounded-full w-14 lg:w-16 h-14 lg:h-16 relative`}
      >
        <div>
          {bet && show ? (
            <>
              <div className="flex align-center justify-center w-10 lg:w-12 h-10 lg:h-12 rounded-full bg-gray-300 relative">
                <PiPokerChipFill
                  className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-5xl lg:text-6xl ${determineChipColor(
                    bet
                  )} shadow-lg`}
                />
              </div>
              {show && bet > 0 && (
                <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold text-sm select-none">
                  {bet}
                </p>
              )}
            </>
          ) : null}
          {gameOver && (
            <FadedInWinningsComponent
              winnings={winnings}
              push={winnings === bet || push}
              gameOver={gameOver}
              name={name}
              shouldPlaySound={typeof (anteInfo) === "undefined" ? true : anteInfo}
            />
          )}
        </div>
      </div>
      <h1 className="text-white text-xl font-bold whitespace-nowrap">{name}</h1>
    </div>
  );
}
