import React, { useEffect } from 'react'
import { Grid, Box, Typography, LinearProgress } from '@mui/material';
import { useProfile } from '../contexts/ProfileContext';
import { useAuth } from '../contexts/AuthContext';

export default function LevelUpBar() {
    const { session } = useAuth();
    const { level, xp, updateXpAndLevel } = useProfile();
    const currentLevel = level;
    const currentXP = xp;
    const levelXP = {
        "1": 500, "2": 2000, "3": 5000, "4": 10000, "5": 20000,
        "6": 35000, "7": 55000, "8": 75000, "9": 100000, "10": 200000
    };

    const getProgress = () => {
        const prevLevelXP = levelXP[currentLevel as keyof typeof levelXP] || 0;
        const nextLevelXP = levelXP[(currentLevel + 1) as keyof typeof levelXP] || prevLevelXP;
        return ((currentXP - prevLevelXP) / (nextLevelXP - prevLevelXP)) * 100;
    };
    useEffect(() => {
        if (session?.user?.id) {
            updateXpAndLevel();
        }
    }, [session?.user?.id]);
    return (
        <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ mr: 2, width: 48, height: 54 }}>
                    {currentLevel > 0 ? (
                        <img src={`levels/lvl${currentLevel}.png`} alt={`Level ${currentLevel}`} style={{ width: '100%', height: '100%' }} />
                    ) : (
                        <div style={{ width: '100%', height: '100%' }} />
                    )}
                    <Typography variant="body1" color="text.secondary" align="center" >
                        {currentLevel === 0 ? "" : `Level ${currentLevel}`}
                    </Typography>
                </Box>

                <Box sx={{ flexGrow: 1, mr: 2 }}>
                    <LinearProgress variant="determinate" value={currentLevel < 10 ? getProgress() : 100} sx={{ height: 10, borderRadius: 5, mt: 4 }} />
                    <Typography variant="body1" color="text.secondary" align="center">
                        {currentLevel === 10 ? "200000 XP" : `${currentXP} / ${levelXP[(currentLevel + 1) as keyof typeof levelXP]} XP`}
                    </Typography>
                </Box>
                <Box sx={{ mr: 2, width: 48, height: 54 }}>
                    {(currentLevel + 1 > 0 && currentLevel + 1 <= 10) ? (
                        <img src={`levels/lvl${currentLevel + 1}.png`} alt={`Level ${currentLevel}`} style={{ width: '100%', height: '100%' }} />
                    ) : (
                        <div style={{ width: '100%', height: '100%' }} />
                    )}
                    <Typography variant="body1" color="text.secondary" align="center">
                        {currentLevel === 10 ? "" : `Level ${currentLevel + 1}`}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    )
}
