import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import { GameData } from '../objects/GameData';

export function useHighRoller() {
    const [rows, setRows] = useState<GameData[]>([]);
    useEffect(() => {
        // Function to fetch high roller data
        const fetchHighRollerData = async () => {
            const { data, error } = await supabase
                .from('recent_bets')
                .select('*')
                .eq('type', 'gold')
                .gt('amount', 1000)
                .order('time', { ascending: false })
                .limit(10);

            if (error) {
                console.error('Error fetching high roller games:', error);
            } else {
                setRows(formatGameData(data));
            }
        };

        // Function to handle real-time updates
        const handleRealtimeUpdate = (payload: any) => {
            if (payload.new.type === 'gold' && payload.new.amount > 1000) {
                setRows(currentRows => {
                    const updatedRows = [formatGameData([payload.new])[0], ...currentRows.slice(0, 9)];
                    return updatedRows;
                });
            }
        };

        // Fetch initial data
        fetchHighRollerData();

        // Set up real-time subscription
        const subscription = supabase
            .channel('high_roller_bets')
            .on('INSERT', handleRealtimeUpdate)
            .subscribe();

        // Cleanup function
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return rows;
}

// Helper function to format the game data
function formatGameData(data: any[]): GameData[] {
    return data.map(item => ({
        game: item.game,
        user: item.hide_username ? 'Hidden' : (item.username || 'Hidden'),
        time: new Date(item.time).toLocaleTimeString(),
        amount: item.amount.toFixed(2),
        multiplier: item.multiplier.toFixed(2) + 'x',
        result: item.result.toFixed(2),
        type: item.type
    }));
}