import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

export interface GoldTransactionData {
  id: number;
  type: 'deposit' | 'withdraw';
  game: string;
  amount: number;
  usd_amount: number | 'undecided';
  coins: number | null;
  status: string;
  created_at: string;
  uuid: string;
}

function formatDateTime(isoString: string): string {
  const date = new Date(isoString);
  
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatAmount(amount: number, game: string): string {

  const formattedAmount = amount.toLocaleString('en-US', { maximumFractionDigits: 0 });
  switch (game) {
    case '07 Gold':
        return `${formattedAmount}M`;
    case 'RS3 Gold':
      return `${formattedAmount}M`;
    default:
      return formattedAmount;
  }
}

export function useGoldTransactions() {
  const [deposits, setDeposits] = useState<GoldTransactionData[]>([]);
  const [withdrawals, setWithdrawals] = useState<GoldTransactionData[]>([]);
  const [mostRecentTransaction, setMostRecentTransaction] = useState<GoldTransactionData | null>(null);
  const { session } = useAuth();

  useEffect(() => {
    const fetchGoldTransactions = async () => {
      if (!session?.user?.id) {
        console.error('User not authenticated');
        return;
      }

      const { data, error } = await supabase
        .rpc('get_gold_transactions', { p_user_id: session.user.id });

      if (error) {
        console.error('Error fetching gold transactions:', error);
      } else {
        const formattedData = data.map((transaction: GoldTransactionData) => ({
          ...transaction,
          created_at: formatDateTime(transaction.created_at),
          amount: formatAmount(transaction.amount, transaction.game)
        }));

        setDeposits(formattedData.filter(t => t.type === 'deposit'));
        setWithdrawals(formattedData.filter(t => t.type === 'withdraw'));

        // Set the most recent transaction
        if (formattedData.length > 0) {
          setMostRecentTransaction(formattedData[0]);
        }
      }
    };

    fetchGoldTransactions();
  }, [session]);

  return { deposits, withdrawals, mostRecentTransaction };
}