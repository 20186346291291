import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient.js';
import { useAuth } from '../contexts/AuthContext.js';

export function usePlayerCount() {
  const { session } = useAuth();
  const [playerCount, setPlayerCount] = useState(0);

  useEffect(() => {
    const getPlayerCount = async () => {
      const { data, error } = await supabase.rpc('update_last_seen_and_count_active');
      if (error) {
        console.error('Error fetching player count:', error);
      } else {
        setPlayerCount(data);
      }
    };

    getPlayerCount();
  }, []);

  return playerCount;
}