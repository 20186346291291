import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { fetchAndUpdateBalance, supabase } from "../../api/supabaseClient.js";
import { IoFlowerSharp } from 'react-icons/io5';
import { GiDaisy } from "react-icons/gi";
import { FaQuestionCircle } from "react-icons/fa";
import GeneralBettingWindow from "../GeneralBettingWindow";
import PayoutDisplay from '../Ultimate-Texas/PayoutDisplay';
import withFadeInDelay from "../withFadeInDelay";
import FpResult from './FpResult';
import ActionButton from '../Blackjack-component/ActionButtons';
import { VscDebugRestart } from "react-icons/vsc";
import { toast } from 'react-toastify';
import bankpinfail from '../../assets/bankpinfail.mp3';
import './FlowerPoker.css';
import RulesComponent from '../../components/RulesComponent';
import { flowerPokerRules } from '../../components/gameRules';
import { useVolume } from "../../contexts/VolumeContext";
import { useBet } from '../../hooks/useBet';
import EnhancedWinningsComponent from "../EnhancedWinningsComponent.tsx";
import fp_pick from '../../assets/fp_pick.mp3';
import confetti from 'canvas-confetti';

const FadedInWinningsComponent = withFadeInDelay(EnhancedWinningsComponent, 50);

const threeOakPayout = [
  "5 Oak: 50 to 1",
  "4 Oak: 10 to 1",
  "Full House: 4 to 1",
  "3 Oak: 2 to 1"
];

const flowerTypes = [
  { color: 'red', label: 'R', hex: 'hsla(0, 70%, 60%, 1)' },
  { color: 'blue', label: 'B', hex: 'hsla(177, 60%, 60%, 1)' },
  { color: 'yellow', label: 'Y', hex: 'hsla(50, 100%, 50%, 1)' }, // Adjusted yellow
  { color: 'orange', label: 'O', hex: 'hsla(30, 100%, 50%, 1)' }, // Adjusted orange
  { color: 'pastel', label: 'P', hex: 'hsla(300, 50%, 70%, 1)' }, // Adjusted pastel
  { color: 'purple', label: 'V', hex: 'hsla(270, 80%, 60%, 1)' }, // Adjusted purple
];
const FadedInFpResult = withFadeInDelay(FpResult, 500);
const FlowerPoker = () => {
  const { session, signedIn } = useAuth();
  const {
    bet,
    setBet,
    handleBetChange: handleMainBetChange,
    handleBetBlur: handleMainBetBlur,
    handleHalfBet: handleMainHalfBet,
    handleDoubleBet: handleMainDoubleBet,
  } = useBet(!signedIn ? 1 : 0);

  const {
    bet: threeOakBet,
    setBet: setThreeOakBet,
    handleBetChange: handleThreeOakBetChange,
    handleBetBlur: handleThreeOakBetBlur,
    handleHalfBet: handleThreeOakHalfBet,
    handleDoubleBet: handleThreeOakDoubleBet,
  } = useBet(0);

  const [game_id, setGameId] = useState(0);
  const [winnings, setWinnings] = useState(0);
  const [threeOakWinnings, setThreeOakWinnings] = useState(0);
  const [playerFlowers, setPlayerFlowers] = useState(Array(5).fill(null));
  const [houseFlowers, setHouseFlowers] = useState(Array(5).fill(null));
  const [revealedPlayerFlowers, setRevealedPlayerFlowers] = useState(Array(5).fill(false));
  const [houseRevealed, setHouseRevealed] = useState(false);
  const [revealedHouseFlowers, setRevealedHouseFlowers] = useState(Array(5).fill(false));
  const [result, setResult] = useState('');
  const [playerHandResult, setPlayerHandResult] = useState('');
  const [houseHandResult, setHouseHandResult] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [planting, setPlanting] = useState(false);

  const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
  const [numberOfGames, setNumberOfGames] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [recovering, setRecovering] = useState(true);
  const isActiveRef = useRef(false);
  const [mode, setMode] = useState('Manual');
  const [shake, setShake] = useState(false);
  const bankPinSound = new Audio(bankpinfail);


  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();
  const { mutedStates, volumes, setActiveGame } = useVolume();
  const [speed, setSpeed] = useState(1);
  const speedRef = useRef(speed);

  useEffect(() => {
    speedRef.current = speed;
  }, [speed])

  const moneyRef = useRef(getActiveBalance());
  useEffect(() => {
    moneyRef.current = getActiveBalance();
  }, [getActiveBalance()]);

  const flowerMap = {
    'red': flowerTypes[0],
    'blue': flowerTypes[1],
    'yellow': flowerTypes[2],
    'orange': flowerTypes[3],
    'pastel': flowerTypes[4],
    'purple': flowerTypes[5]
  };

  const mapFlowerColors = (colors) => {
    return colors.map(color => flowerMap[color]);
  };


  const startGame = async () => {
    if (bet + threeOakBet > moneyRef.current && signedIn) {
      toast.error("Insufficient balance. Bet cannot be higher than balance.");
      bankPinSound.play();
      setGameStarted(false);
      isActiveRef.current = false;
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 200);
      return false;
    }
    let { data, error } = {};
    if (signedIn) {
      ({ data, error } = await supabase.rpc("start_flower_poker", {
        v_bet: bet,
        v_threeoakbet: threeOakBet,
        v_player_id: session?.user.id,
        v_type: balanceType
      }));
    } else {
      ({ data, error } = await supabase.rpc("start_flower_poker_guest", {
        v_bet: bet,
        v_threeoakbet: threeOakBet,
      }));
    }

    if (error) {
      console.error("Error starting game:", error);
      return false;
    } else {
      confetti.reset();
      setResult('');
      if (signedIn) {
        setPlayerHandResult('');
        setHouseHandResult('');
        setWinnings(0);
        setThreeOakWinnings(0);
        setRevealedPlayerFlowers(Array(5).fill(false));
        setRevealedHouseFlowers(Array(5).fill(false));
        setHouseRevealed(false);
        setGameId(data.id);
        setPlayerFlowers(mapFlowerColors(data.player_flowers));
        setHouseFlowers(mapFlowerColors(data.house_flowers));
        setPlayerHandResult(data.player_result);
        setHouseHandResult(data.house_result);
        setWinnings(data.winnings);
        setThreeOakWinnings(data.threeoakwinnings);
        setResult(data.status === 'completed' ? 'Game Over' : '');
        setGameStarted(true);
        setGameOver(false);
        setTimeout(() => {
          setPlanting(false);
        }, 500);
        await fetchBalances();
        return true;
      } else {
        setPlayerHandResult('');
        setHouseHandResult('');
        setWinnings(0);
        setThreeOakWinnings(0);
        setRevealedPlayerFlowers(Array(5).fill(false));
        setRevealedHouseFlowers(Array(5).fill(false));
        setHouseRevealed(false);
        setGameId(data.id);
        setPlayerFlowers(mapFlowerColors(data.f2));
        setHouseFlowers(mapFlowerColors(data.f3));
        setPlayerHandResult(data.f4);
        setHouseHandResult(data.f5);
        setWinnings(data.f9);
        setThreeOakWinnings(data.f10);
        setGameStarted(true);
        setGameOver(false);
        setTimeout(() => {
          setPlanting(false);
        }, 500);
        return true;
      }
    }
  };
  const endGame = async () => {
    if (signedIn) {
      const { data, error } = await supabase.rpc("end_flower_poker", {
        game_id: game_id,
        v_user_id: session?.user.id
      });
      if (error) {
        console.error("Error ending game:", error);
      }
      await fetchBalances();
    }
  }
  const fpPickSound = useRef(new Audio(fp_pick));

  const playFpPickSound = useCallback(() => {
    fpPickSound.current.volume = mutedStates['flowerpoker'] ? 0 : volumes['flowerpoker'];
    fpPickSound.current.currentTime = 0; // Reset the audio to start
    fpPickSound.current.play();
  }, [mutedStates, volumes]);

  const revealFlower = useCallback((setRevealedFlowers, index) => {
    setRevealedFlowers(prev => {
      const newRevealed = [...prev];
      newRevealed[index] = true;
      playFpPickSound();
      return newRevealed;
    });
  }, [playFpPickSound]);

  const handlePlayerClick = useCallback((index) => {
    if (revealedPlayerFlowers[index] || gameOver || !gameStarted || planting) return;

    revealFlower(setRevealedPlayerFlowers, index);

    if (revealedPlayerFlowers.filter(Boolean).length === 4) {
      revealHouseFlowers();
    }
  }, [revealedPlayerFlowers, gameOver, gameStarted, planting, revealFlower]);

  const handlePlantAll = useCallback(() => {
    setPlanting(true);
    for (let i = 0; i < 5; i++) {
      setTimeout(() => revealFlower(setRevealedPlayerFlowers, i), i * (200 / speedRef.current));
    }
    setTimeout(revealHouseFlowers, 1500 / speedRef.current);
  }, [revealFlower]);

  const revealHouseFlowers = useCallback(() => {
    setPlanting(true);
    setHouseRevealed(true);
    for (let i = 0; i < 5; i++) {
      setTimeout(() => revealFlower(setRevealedHouseFlowers, i), i * (200 / speedRef.current));
    }

    setTimeout(() => {
      setResult('Game Over');
      setGameOver(true);
      setRecovering(false);
      setPlanting(false);
    }, 2000 / speedRef.current);
  }, [revealFlower]);

  const handleReplay = () => {
    if (mode === 'Auto') {
      startAutoPlay();
    } else {
      setPlanting(true);
      startGame();
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setNumberOfGames(0);
  };

  const startAutoPlay = async () => {
    //if (gameStarted || gameOver) return; // Prevent starting auto-play if a game is active or over
    isActiveRef.current = true;
    setGameStarted(true);
    let success = await startGame();
    if (success) {
      handlePlantAll();
    } else {
      return;
    }
    let gamesCount = 1;
    const intervalId = setInterval(async () => {
      if (gamesCount >= numberOfGames || !isActiveRef.current || (bet + threeOakBet > moneyRef.current && signedIn)) {
        clearInterval(intervalId);
        setAutoPlayIntervalId(null);
        setGameStarted(false);
        isActiveRef.current = false;
        if (bet + threeOakBet > moneyRef.current) {
          toast.error("Insufficient balance. Bet cannot be higher than balance.");
          bankPinSound.play();
          setShake(true);
          setTimeout(() => {
            setShake(false);
          }, 200);
        }
        return;
      }
      success = await startGame();
      if (success) handlePlantAll();


      gamesCount++;
      setGamesPlayed(gamesCount);
    }, 4000 / speedRef.current + 1000); // Adjust interval as needed

    setAutoPlayIntervalId(intervalId);
  };

  const stopAutoPlay = () => {
    if (autoPlayIntervalId) {
      clearInterval(autoPlayIntervalId);
      setAutoPlayIntervalId(null);
      isActiveRef.current = false;
      setGameStarted(false);
      setGameOver(true);
    }
  };
  useEffect(() => {
    const fetchActiveGame = async () => {
      const { data, error } = await supabase.rpc('fp_fetch_active', {
        v_player_id: session?.user.id,
      });

      if (error) {
        console.error('Error fetching active game:', error);
        return;
      }

      if (data) {
        const activeGame = data;
        setGameId(activeGame.id);
        changeBalanceType(activeGame.type);
        setPlayerFlowers(mapFlowerColors(activeGame.player_flowers));
        setHouseFlowers(mapFlowerColors(activeGame.house_flowers));
        setPlayerHandResult(activeGame.player_result);
        setHouseHandResult(activeGame.house_result);
        setBet(activeGame.bet);
        setThreeOakBet(activeGame.threeoakbet);
        setWinnings(activeGame.winnings);
        setThreeOakWinnings(activeGame.threeoakwinnings);
        setResult(activeGame.status === 'completed' ? 'Game Over' : '');
        setGameStarted(true);
        setGameOver(activeGame.status === 'completed');
        await fetchBalances();
      } else {
        setRecovering(false);
      }
    };
    if (session?.user.id.length > 0) {
      fetchActiveGame();
    } else {
      setRecovering(false);
    }
  }, [session?.user.id]);

  useEffect(() => {
    if (gameOver) {
      endGame();
    }
  }, [gameOver]);
  useEffect(() => {
    return () => {
      stopAutoPlay(); // Cleanup on component unmount
    };
  }, [autoPlayIntervalId]);
  useEffect(() => {
    setDisableModifications((!gameOver && gameStarted) || isActiveRef.current || recovering);
  }, [gameStarted, gameOver, isActiveRef.current, recovering])
  useEffect(() => {
    setActiveGame('flowerpoker');
    return () => {
      setDisableModifications(false);
    }
  }, [])
  return (
    <>
      <div className="main-container">
        <GeneralBettingWindow
          key='flowerpoker'
          bets={[
            {
              name: "Amount",
              bet: bet,
              handleAmountChange: handleMainBetChange,
              handleBlur: handleMainBetBlur,
              handleHalfBet: handleMainHalfBet,
              handleDoubleBet: handleMainDoubleBet,
              valid: bet > 0,
              winnings: winnings,
              winState: result
            },
            {
              name: "3 Oak",
              bet: threeOakBet,
              handleAmountChange: handleThreeOakBetChange,
              handleBlur: handleThreeOakBetBlur,
              handleHalfBet: handleThreeOakHalfBet,
              handleDoubleBet: handleThreeOakDoubleBet,
              valid: threeOakBet >= 0,
              winnings: threeOakWinnings,
              anteInfo: winnings === 0,
              sideBet: true,
              winState: result
            },
          ]}
          startGame={startGame}
          gameStarted={(!gameOver && gameStarted) || isActiveRef.current || recovering}
          signedIn={signedIn}
          guest={!signedIn}
          gameOver={gameOver}
          showChips={true}
          autoPlay={true}
          numberOfGames={numberOfGames}
          onModeChange={(newMode) => handleModeChange(newMode)}
          setNumberOfGames={(e) => { setNumberOfGames(parseInt(e.target.value)) }}
          startAutoPlay={startAutoPlay}
          stopAutoPlay={stopAutoPlay}
          autoBetTimeOut={planting}
          shake={shake}
          balanceType={balanceType}
          gameId="flowerpoker"
          speed={speed}
          setSpeed={setSpeed}
        />
        <div className="bg-gray-800 text-white flex flex-col items-center p-10 rounded-lg min-w-[60%] relative game-container">
          <FadedInWinningsComponent
            key={result}
            name="Flower Poker"
            winnings={winnings + threeOakWinnings}
            push={false}
            gameOver={gameOver}
            shouldPlaySound={false}
            additionalStyles={'w-24 h-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'}
          />
          <div className='absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <PayoutDisplay name="3 Oak" payouts={threeOakPayout} />
          </div>
          <h1 className="hidden md:block mb-5 text-2xl">Flower Poker</h1>
          <div className="flex justify-between w-full">
            <div className="flex flex-col items-center relative">
              <h2 className="text-xl mb-2 text-[#eaeef6] w-full text-center">Player's Flowers</h2>
              {playerFlowers.map((flower, index) => (
                <div
                  key={index}
                  className={`w-20 h-20 bg-[#1C2833] border border-[#344050] rounded-lg flex justify-center items-center font-bold cursor-pointer transition duration-300 ease-in-out mb-2 ${(!gameOver && gameStarted) && !revealedPlayerFlowers[index] ? "border border-yellow-500 fpPulse" : ""}`}
                  onClick={() => handlePlayerClick(index)}
                  style={{ color: revealedPlayerFlowers[index] ? flower.hex : '#333', position: 'relative' }}
                >
                  {revealedPlayerFlowers[index] ? <GiDaisy className='select-none' size={50} /> : <FaQuestionCircle className='select-none' size={50} style={{ color: '#ffffff' }} />}
                  {revealedPlayerFlowers[index] && (
                    <span className="absolute bottom-1 right-1 text-s text-white">{flower.label}</span>
                  )}
                </div>
              ))}
              {playerHandResult && gameOver && (
                <FadedInFpResult
                  result={playerHandResult}
                  winState={winnings === bet ? "Push" : winnings > bet ? "player" : "dealer"}
                />
              )}
              {mode !== 'Auto' && (
                <button
                  onClick={gameOver ? handleReplay : handlePlantAll}
                  className={`flower-poker-button ${(!gameStarted || planting || isActiveRef.current) ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={!gameStarted || planting  || isActiveRef.current}
                >
                  <span className="text-center">
                    {gameOver ? 'Play Again' : 'Plant All'}
                  </span>
                </button>
              )}
            </div>
            <div className="flex flex-col items-center relative">
              <h2 className="text-xl mb-2 text-[#eeeceb] w-full text-center">House's Flowers</h2>
              {houseFlowers.map((flower, index) => (
                <div
                  key={index}
                  className="w-20 h-20 bg-[#2E1B1B] border border-[#503634] rounded-lg flex justify-center items-center font-bold mb-2"
                  style={{ color: houseRevealed && revealedHouseFlowers[index] ? flower.hex : '#333', position: 'relative' }}
                >
                  {houseRevealed && revealedHouseFlowers[index] ? <GiDaisy size={50} /> : <FaQuestionCircle size={50} style={{ color: '#ffffff' }} />}
                  {houseRevealed && revealedHouseFlowers[index] && (
                    <span className="absolute bottom-1 right-1 text-s text-white">{flower.label}</span>
                  )}
                </div>
              ))}
              {houseHandResult && gameOver && (
                <FadedInFpResult
                  result={houseHandResult}
                />
              )}
            </div>
          </div>
        </div>
      </div >
      <RulesComponent rules={flowerPokerRules} />
    </>
  );
};

export default FlowerPoker;
