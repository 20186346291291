import { supabase } from "../../api/supabaseClient.js";
import { useEffect, useRef, useState } from "react";
import { Card, CardValue, Suit } from "../../objects/card";
import CardDeal from "../CardDeal";
import TexasBettingWindow from "./TexasBettingWindow";
import ActionButton from "../Blackjack-component/ActionButtons";
import { BsFillSuitSpadeFill } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import HandResult from "./HandResult";
import withFadeInDelay from "../withFadeInDelay";
import PayoutDisplay from "./PayoutDisplay";
import { PiCoinFill, PiCoinsFill } from "react-icons/pi";
import { IoIosHand } from "react-icons/io";
import { TbHandFinger } from "react-icons/tb";
import BetsDisplay from "./BetsDisplay";
import TexasWinningsComponent from "./TexasWinningsComponent";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { preloadCardImages } from "../../utils/cardUtils.js";
import { toast } from "react-toastify";
import bankpinfail from '../../assets/bankpinfail.mp3';
import RulesComponent from '../../components/RulesComponent';
import { texasHoldemRules } from '../../components/gameRules';
import { useVolume } from "../../contexts/VolumeContext"; 
import { useBet } from '../../hooks/useBet';
import EnhancedWinningsComponent from "../EnhancedWinningsComponent.tsx";
import confetti from 'canvas-confetti';
import { VscDebugRestart } from "react-icons/vsc";


const FadedInWinningsComponent = withFadeInDelay(TexasWinningsComponent, 500);
const FadedInHandResult = withFadeInDelay(HandResult, 1000);
const blindPayouts = [
  "Royal Flush: 200 to 1",
  "Straight Flush: 50 to 1",
  "Quads: 10 to 1",
  "Full House: 3 to 1",
  "Flush: 3 to 2",
  "Straight: 1 to 1",
  "Player win: Push",
];
const tripsPayouts = [
  "Royal Flush: 50 to 1",
  "Straight Flush: 40 to 1",
  "Quads: 30 to 1",
  "Full House: 8 to 1",
  "Flush: 7 to 1",
  "Straight: 4 to 1",
  "Trips: 3 to 1",
];
const cardMinHeight = "min-h-[91.0833px] md:min-h-[136.617px] lg:min-h-[159.383px]";

export default function UltimateTexas() {
  const {
    bet: anteBet,
    setBet: setAnteBet,
    handleBetChange: handleAnteBetChange,
    handleBetBlur: handleAnteBetBlur,
    handleHalfBet: handleAnteHalfBet,
    handleDoubleBet: handleAnteDoubleBet,
  } = useBet(0);

  const {
    bet: tripsBet,
    setBet: setTripsBet,
    handleBetChange: handleTripsBetChange,
    handleBetBlur: handleTripsBetBlur,
    handleHalfBet: handleTripsHalfBet,
    handleDoubleBet: handleTripsDoubleBet,
  } = useBet(0);

  const [deck, setDeck] = useState<Card[]>([]);
  const [game_id, setGameId] = useState<number>(0);
  const [playerHand, setPlayerHand] = useState<Card[]>([]);
  const [playerRank, setPlayerRank] = useState<number>(0); // 0 = no hand, 1 = high card, 2 = pair, 3 = two pair, 4 = trips, 5 = straight, 6 = flush, 7 = full house, 8 = quads, 9 = straight flush, 10 = royal flush
  const [playerResult, setPlayerResult] = useState<string>("");
  const [playerFullResult, setPlayerFullResult] = useState<string>("");

  const [communityCards, setCommunityCards] = useState<Card[]>([]);
  const [dealerHand, setDealerHand] = useState<Card[]>([]);
  const [dealerResult, setDealerResult] = useState<string>("");
  const [dealerFullResult, setDealerFullResult] = useState<string>("");
  //const [showFlop, setShowFlop] = useState<boolean>(false);

  const [checkedPreflop, setCheckedPreflop] = useState<boolean>(false);
  const [checkedFlop, setCheckedFlop] = useState<boolean>(false);
  const [fullPlay, setFullPlay] = useState<boolean>(false);

  const [recovering, setRecovering] = useState<boolean>(true);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [cardAnimations, setCardAnimations] = useState<string[]>();
  const [folded, setFolded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [firstGame, setFirstGame] = useState<boolean>(true);
  const [showActionButtons, setShowActionButtons] = useState<boolean>(true);

  const [playBet, setPlayBet] = useState<number>(0);
  const [winState, setWinState] = useState<string>("");
  const [anteWinnings, setAnteWinnings] = useState<number>(0);
  const [blindWinnings, setBlindWinnings] = useState<number>(0);
  const [playWinnings, setPlayWinnings] = useState<number>(0);
  const [tripsWinnings, setTripsWinnings] = useState<number>(0);
  const [cardImages, setCardImages] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [shake, setShake] = useState(false);
  const [speed, setSpeed] = useState(1);
  const bankPinSound = new Audio(bankpinfail);


  const betsDisplayRef = useRef(null);

  let totalAttempts = 0;
  const { session, signedIn } = useAuth();
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();
  const { setActiveGame } = useVolume();

  const restartGame = () => {
    setShowActionButtons(true);
    setFirstGame(false);
    setLoading(true);
    setPlayerHand([]);
    setDealerHand([]);
    setPlayerResult("");
    setDealerResult("");
    setCommunityCards([]);
    setCheckedFlop(false);
    setCheckedPreflop(false);
    setFullPlay(false);
    setFolded(false);
    setPlayBet(0);
    setAnteWinnings(0);
    setBlindWinnings(0);
    setTripsWinnings(0);
    setPlayWinnings(0);
    setWinState("");
    setGameStarted(true);
    setGameOver(false);
    totalAttempts = 0;
  };
  const dealInitialCards = () => {
    setCardAnimations(new Array(5).fill("texas-slide"));
    //setPlayerHand([new Card(Suit.Clubs,CardValue.Ace,true),new Card(Suit.Clubs,CardValue.King,true)]);
    setCommunityCards([
      new Card(undefined, undefined, false),
      new Card(undefined, undefined, false),
      new Card(undefined, undefined, false),
      new Card(undefined, undefined, false),
      new Card(undefined, undefined, false),
    ]);
    setGameStarted(true);
  };
  const updateFoldStatus = async () => {
    const { data, error } = await supabase.rpc("handlefold", {
      game_id: game_id,
      v_player_id: session?.user.id,
    });
    if (error) {
      console.error("Error updating fold status:", error);
    }
  };
  const handleDeals = (data: Card[], fulldeal: boolean, check?: boolean) => {
    if (data.length === 3) {
      setCheckedPreflop(true);
      setCommunityCards([
        ...data.map(Card.fromPlainObject),
        new Card(undefined, undefined, false),
        new Card(undefined, undefined, false),
      ] as Card[]);
      setCardAnimations([
        "card-flip",
        "card-flip",
        "card-flip",
        "texas-slide",
        "texas-slide",
      ]);
    } else if (!fulldeal) {
      setCheckedFlop(true);
      setTimeout(() => {
        setCommunityCards([...data.map(Card.fromPlainObject)] as Card[]);
        setCardAnimations(new Array(5).fill("card-flip"));
      }, 500/speed);
      if (!check) {
        setTimeout(() => {
          setFullPlay(true);
        }, 1500/speed);
      }
    } else {
      setTimeout(() => {
        setCommunityCards([
          ...data.slice(0, 3).map(Card.fromPlainObject),
          new Card(undefined, undefined, false),
          new Card(undefined, undefined, false),
        ] as Card[]);
        setCardAnimations([
          "card-flip",
          "card-flip",
          "card-flip",
          "texas-slide",
          "texas-slide",
        ]);
      }, 500/speed);
      setTimeout(() => {
        setCommunityCards([...data.map(Card.fromPlainObject)] as Card[]);
        setCardAnimations(new Array(5).fill("card-flip"));
      }, 1500/speed);
      setTimeout(() => {
        setFullPlay(true);
      }, 2000/speed);
    }
  };

  const handleActions = async (action: string) => {
    if (action === "4x" || action === "3x") {
      setShowActionButtons(false);
      const { data, error } = await supabase.rpc("handlex", {
        game_id: game_id,
        v_player_id: session?.user.id,
        multiplier: Number(action[0]),
      });

      if (error) {
        console.error("Error 4x or 3x game:", error);
        return null;
      }
      setPlayBet(data.playbet);
      await fetchBalances();
      handleDeals(data.communitycards, true);
    }
    if (action === "2x") {
      setShowActionButtons(false);
      const { data, error } = await supabase.rpc("handlex", {
        game_id: game_id,
        v_player_id: session?.user.id,
        multiplier: Number(action[0]),
      });

      if (error) {
        console.error("Error 2x bet:", error);
        return null;
      }
      setPlayBet(data.playbet);
      await fetchBalances();
      handleDeals(data.communitycards, false);
    }
    if (action === "Check") {
      const { data, error } = await supabase.rpc("handlecheck", {
        game_id: game_id,
        v_player_id: session?.user.id,
      });

      if (error) {
        console.error("Error checing:", error);
        return null;
      }
      handleDeals(data, false, true);
    }
    if (action === "Play") {
      const { data, error } = await supabase.rpc("handlex", {
        game_id: game_id,
        v_player_id: session?.user.id,
        multiplier: 1,
      });

      if (error) {
        console.error("Error playing:", error);
        return null;
      }
      setShowActionButtons(false);
      setPlayBet(data.playbet);
      await fetchBalances();
      setTimeout(() => {
        setFullPlay(true);
      }, 1500);
    }

    if (action === "Fold") {
      setShowActionButtons(false);
      setTimeout(async () => {
        await updateFoldStatus();
        setFolded(true);
        setFullPlay(true);
      }, 1500);
    }
  };
  useEffect(() => {
    if (playerHand.length === 2) {
      dealInitialCards();
      setLoading(false);
    }
  }, [playerHand]);
  useEffect(() => {
    // Check if all community cards have been dealt
    const endGame = async () => {
      try {
        let { data, error } = await supabase.functions.invoke(
          "texas-hand-evaluate",
          {
            body: { game_id: game_id.toString(), player_id: session?.user.id },
          }
        );
        if (error) {
          console.error("Error evaluating hand:", error);
        }
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        setWinState(data.winner);
        data.hands.forEach((hand: any) => {
          if (hand.owner === "player") {
            setPlayerResult(hand.name);
            setPlayerFullResult(hand.descr);
            setPlayerRank(hand.rank);
          } else {
            setDealerResult(hand.name);
            setDealerFullResult(hand.descr);
            setDealerHand(data.dealerOriginalHand.map(Card.fromPlainObject));
          }
        });
        setAnteWinnings(data.winnings.antewinnings);
        setBlindWinnings(data.winnings.blindwinnings);
        setTripsWinnings(data.winnings.tripswinnings);
        setPlayWinnings(data.winnings.playbet_winnings);
        setRecovering(false);
      } catch (err) {
        console.error(err);
        totalAttempts++;
        if (totalAttempts < 25) {
          endGame();
        } else {
          console.error(err);
        }
      }
    };
    if (
      communityCards.length === 5 &&
      communityCards.every((card) => card.suit && card.value) &&
      fullPlay
    ) {
      endGame();
    }
  }, [communityCards, fullPlay]);
  useEffect(() => {
    async function createAndFetchGame() {
      if ((anteBet * 2) + tripsBet >= getActiveBalance()) {
        toast.error("Insufficient balance. Bet cannot be higher or equal to balance.");
        bankPinSound.play();
        setFirstGame(true);
        setGameStarted(false);
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return;
      }
      const { data, error } = await supabase.rpc("start_ultimate_texas", {
        bet: anteBet,
        trips_bet: tripsBet,
        v_player_id: session?.user.id,
        v_type: balanceType
      });

      if (error) {
        setGameStarted(false);
        setFirstGame(true);
        toast.error(error.message);
        bankPinSound.play();
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        console.error("Error creating game:", error);
        return null;
      }
      setGameId(data.id);
      setPlayerHand(data.player_hand.map(Card.fromPlainObject));
      confetti.reset();
      await fetchBalances();
      return data;
    }
    if (gameStarted && !recovering) {
      createAndFetchGame();
    }
  }, [gameStarted]);
  useEffect(() => {
    if (gameOver) {
      fetchBalances();
      setGameStarted(false);
    }
  }, [gameOver]);
  useEffect(() => {
    setActiveGame('ultimatetexas');
    preloadCardImages()
      .then((images) => {
        setCardImages(images);
        setImagesLoaded(true);
      })
      .catch((error) => {
        console.error("Failed to load card images:", error);
      });
  }, []);
  useEffect(() => {
    const fetchActiveGame = async () => {
      setLoading(true);
      const { data, error } = await supabase.rpc('ut_fetch_active', {
        v_player_id: session?.user.id,
      });

      if (error) {
        console.error('Error fetching active game:', error);
        return;
      }
      if (data) {
        setGameId(data.id);
        changeBalanceType(data.type);
        setPlayerHand(data.player_hand.map(Card.fromPlainObject));
        setAnteBet(data.bet);
        setTripsBet(data.trips_bet);
        setPlayBet(data.playbet);
        setCheckedPreflop(data.checkedpreflop);
        setCheckedFlop(data.checkedflop);
        setGameStarted(true);
        setShowActionButtons(data.playbet === 0 && !data.folded);
        if (data.community_cards.length > 0) {
          setTimeout(() => {
            handleDeals(data.community_cards, data.playbet > 0, (data.playbet === 0 && !data.folded));
          }, 2000);
        }
      } else {
        setRecovering(false);
      }
    };
    if (session?.user.id.length > 0) {
      fetchActiveGame();
    } else {
      setRecovering(false);
    }
  }, [session?.user.id]);
  useEffect(() => {
    setDisableModifications((!gameOver && gameStarted) || recovering);
  }, [gameOver, gameStarted, recovering])
  useEffect(() => {
    return () => {
      setDisableModifications(false);
    }
  }, [])

  const getElement = () => {
    return (
      <div ref={betsDisplayRef} className="hidden md:block">
        <BetsDisplay
          bet={anteBet}
          playBet={playBet}
          tripsBet={tripsBet}
          gameOver={gameOver}
          anteWinnings={anteWinnings}
          blindWinnings={blindWinnings}
          playWinnings={playWinnings}
          tripsWinnings={tripsWinnings}
          winState={winState}
        />
      </div>
    );
  };
  return (
    <>
      <div className="main-container">
        <TexasBettingWindow
          bet={anteBet}
          handleAmountChange={handleAnteBetChange}
          handleBetBlur={handleAnteBetBlur}
          handleHalfBet={handleAnteHalfBet}
          handleDoubleBet={handleAnteDoubleBet}
          tripsBet={tripsBet}
          handleTripsChange={handleTripsBetChange}
          handleTripsBetBlur={handleTripsBetBlur}
          handleHalfTripsBet={handleTripsHalfBet}
          handleDoubleTripsBet={handleTripsDoubleBet}
          startGame={restartGame}
          gameStarted={(!gameOver && gameStarted) || recovering}
          signedIn={signedIn}
          element={getElement()}
          shake={shake}
          balanceType={balanceType}
          speed={speed}
          setSpeed={setSpeed}
        />
        {loading && (
          <div className="flex flex-grow relative">
            <div
              className="game-container flex flex-col flex-grow items-center justify-center relative min-w-[366px] min-h-[559.85px]"
            >
              {firstGame && imagesLoaded ? (
                <div className="flex flex-col items-center justify-center">
                  <h1 className="text-3xl md:text-7xl text-white text-center animate-pulse font-bold mb-4">
                    {signedIn ? "Place Bet to Start!" : "Sign in to Play!"}
                  </h1>
                  <BsFillSuitSpadeFill className="text-6xl md:text-9xl text-white text-center animate-pulse" />
                </div>
              ) : (
                <AiOutlineLoading3Quarters className="text-8xl text-white text-center animate-spin" />
              )}
              <>
                <div className="hidden lg:block absolute top-2/3 left-1/4 transform -translate-x-1/2 -translate-y-1/4 flex flex-col items-center justify-center z-10">
                  <PayoutDisplay name="Blind" payouts={blindPayouts} />
                </div>

                <div className="hidden lg:block absolute top-2/3 right-1/4 transform translate-x-1/2 -translate-y-1/4 flex flex-col items-center justify-center z-10">
                  <PayoutDisplay name="Trips" payouts={tripsPayouts} />
                </div>
              </>
            </div>
          </div>
        )}
        {/* lg:min-w-[862.85px] lg:min-h-[902.25px] */}
        {!loading && (
          <div
            className="game-container flex flex-grow flex-col items-center justify-center bg-primary-100 relative min-w-[366px] min-h-[559.85px]"
            id="game-board"
            data-testid="game-board"
          >
            {!loading && (
              <>
                <div className="hidden lg:block absolute top-2/3 left-1/4 transform -translate-x-1/2 -translate-y-1/4 flex flex-col items-center justify-center z-10">
                  <PayoutDisplay name="Blind" payouts={blindPayouts} />
                </div>

                <div className="hidden lg:block absolute top-2/3 right-1/4 transform translate-x-1/2 -translate-y-1/4 flex flex-col items-center justify-center z-10">
                  <PayoutDisplay name="Trips" payouts={tripsPayouts} />
                </div>
              </>
            )}
            <div
              className="mt-2 flex flex-row"
              id={`dealer-hand`}
              data-testid="dealer-hand"
            >
              {fullPlay ? (
                <div className={`flex relative ${cardMinHeight}`}>
                  <CardDeal
                    key={`dealer${deck ? deck.length === 52 : 0}`}
                    cards={dealerHand}
                    name="dealer"
                    onDealerFinishing={() => {
                      setGameOver(true);
                    }}
                    cardImages={cardImages}
                    speed={speed}
                  />
                  {dealerResult && gameOver && (
                    <FadedInHandResult
                      result={dealerResult}
                      fullResult={dealerFullResult}
                    />
                  )}
                </div>
              ) : (
                <div className={`${cardMinHeight}`} />
              )}
            </div>
            <div
              className={`mb-7 lg:mb-6 mt-7 lg:mt-6 flex flex-row items-center justify-center lg:min-w-[608px] ${cardMinHeight}`}
              id={`community-hand`}
              data-testid="community-hand"
            >
              {communityCards && (
                <>
                  <CardDeal
                    key={`community${deck ? deck.length === 52 : 0}`}
                    cards={communityCards}
                    name="community"
                    animationStyle={cardAnimations}
                    cardImages={cardImages}
                    speed={speed}
                  />
                  <div className="block">
                    <EnhancedWinningsComponent
                      winnings={
                        anteWinnings +
                        blindWinnings +
                        playWinnings +
                        tripsWinnings
                      }
                      push={false}
                      gameOver={gameOver}
                      name={"ante"}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`flex justify-center items-center mb-2 ${cardMinHeight}`}
              id={`player-hand`}
              data-testid="player-hand"
            >
              {playerHand && (
                <div className="flex relative">
                  <CardDeal
                    key={`player${deck[1] ? deck[1].suit : ""}${deck[2] ? deck[2].suit : ""
                      }${deck[1] ? deck[1].value : ""}${deck[2] ? deck[1].value : ""
                      }`}
                    cards={playerHand}
                    cardImages={cardImages}
                    speed={speed}
                  />
                  {playerResult && gameOver && (
                    <FadedInHandResult
                      result={playerResult}
                      fullResult={playerFullResult}
                      winState={winState}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              className="flex items-center justify-center"
              id="action-buttons"
            >
              {gameOver ? (
                <div className="min-w-[264px] min-h-[68px] lg:min-h-[112px] flex items-center justify-center">
                <button
                  className="play-again-button"
                  onClick={restartGame}
                >
                  <VscDebugRestart className="mr-2" />
                  Play Again
                </button>
              </div>
              ) : showActionButtons ? (
                <>
                  {!checkedPreflop && (
                    <ActionButton
                      name="3x"
                      action={() => {
                        handleActions("3x");
                      }}
                      Icon={PiCoinFill}
                    />
                  )}
                  {!checkedPreflop && (
                    <ActionButton
                      name="4x"
                      action={() => {
                        handleActions("4x");
                      }}
                      Icon={PiCoinsFill}
                    />
                  )}
                  {checkedPreflop && !checkedFlop && (
                    <ActionButton
                      name="2x"
                      action={() => {
                        handleActions("2x");
                      }}
                      Icon={PiCoinFill}
                    />
                  )}
                  {checkedPreflop && checkedFlop && (
                    <ActionButton
                      name="Play"
                      action={() => {
                        handleActions("Play");
                      }}
                      Icon={PiCoinFill}
                    />
                  )}
                  {checkedPreflop && checkedFlop && (
                    <ActionButton
                      name="Fold"
                      action={() => {
                        handleActions("Fold");
                      }}
                      Icon={IoIosHand}
                      additionalIconStyles="text-2xl lg:text-5xl mr-1"
                    />
                  )}
                  {!(checkedFlop && checkedPreflop) && (
                    <ActionButton
                      name="Check"
                      action={() => {
                        handleActions("Check");
                      }}
                      Icon={TbHandFinger}
                    />
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center min-w-[264px] min-h-[68px] lg:min-h-[112px]" />
              )}
            </div>
          </div>
        )}
      </div>
      <RulesComponent rules={texasHoldemRules} />
    </>
  );
}
