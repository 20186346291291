import React from 'react';
import CustomThemeProvider from './ThemeProvider';

interface ThemeWrapperProps {
    children: React.ReactNode;
}

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
    return (
        <CustomThemeProvider>
            <div className="font-custom text-default bg-background-primary min-h-screen">
                {children}
            </div>
        </CustomThemeProvider>
    );
};

export default ThemeWrapper;