import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Paper, Typography, Button, Box } from '@mui/material';
import { supabase } from '../api/supabaseClient.js';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

export default function VerifyDiscord() {
  const { session, signedIn } = useAuth();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const discordUsername = searchParams.get('d');
  const discordUid = searchParams.get('uid');

  const handleVerification = async () => {
    if (!discordUsername || !discordUid || !session?.user?.id) {
      setError('Missing required information');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.rpc('update_discord_info', {
        v_player_id: session.user.id,
        v_discord_name: discordUsername,
        v_discord_uuid: discordUid
      });

      if (error) throw error;
      console.log(data,error);

      if (!data.success) {
        setError(data.message);
        return;
      }

      setSuccess(true);
      toast.success('Discord account linked successfully!');
    } catch (err) {
      console.error('Verification error:', err);
      setError('Failed to verify Discord account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const ErrorView = () => (
    <Container maxWidth="sm" sx={{ 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 400 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontSize: '2rem', color: '#fff', mb: 3 }}>
          Invalid Link
        </Typography>
        <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem', color: '#ccc' }}>
          This verification link appears to be invalid. Please request a new link from Discord.
        </Typography>
      </Paper>
    </Container>
  );

  if (!discordUsername || !discordUid) {
    return <ErrorView />;
  }

  return (
    <Container maxWidth="sm" sx={{ 
      height: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Paper elevation={3} sx={{ p: 4, width: '100%', maxWidth: 400 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ 
          fontSize: '2rem', 
          color: '#fff',
          mb: 4
        }}>
          Verify Discord Account
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ color: '#ccc', mb: 1, fontSize: '1.2rem' }}>
            Discord Username:
          </Typography>
          <Paper sx={{ p: 2, bgcolor: '#2B2D31', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ color: '#fff', fontSize: '1.2rem' }}>
              {discordUsername}
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" sx={{ color: '#ccc', mb: 1, fontSize: '1.2rem' }}>
            Discord ID:
          </Typography>
          <Paper sx={{ p: 2, bgcolor: '#2B2D31', display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ color: '#fff', fontSize: '1.2rem' }}>
              {discordUid}
            </Typography>
          </Paper>
        </Box>

        {error && (
          <Paper sx={{ p: 3, mb: 3, bgcolor: 'rgba(255,0,0,0.1)' }}>
            <Typography variant="body1" sx={{ color: '#ff6b6b', fontSize: '1.1rem', textAlign: 'center' }}>
              {error}
            </Typography>
          </Paper>
        )}

        {success ? (
          <Paper sx={{ p: 3, mb: 3, bgcolor: 'rgba(0,255,0,0.1)' }}>
            <Typography variant="body1" sx={{ color: '#05f783', fontSize: '1.1rem', textAlign: 'center' }}>
              Successfully verified Discord account!
            </Typography>
          </Paper>
        ) : (
          <Button
            onClick={handleVerification}
            disabled={isLoading || !signedIn}
            variant="contained"
            fullWidth
            sx={{
              fontSize: '1.1rem',
              py: 1.5,
              bgcolor: '#536BF2',
              '&:hover': { bgcolor: '#666' },
              '&:disabled': { bgcolor: '#2c2c2c', color: '#666' },
            }}
          >
            {isLoading ? 'Verifying...' : signedIn ? 'Verify Discord Account' : 'Login to Verify'}
          </Button>
        )}
      </Paper>
    </Container>
  );
}
