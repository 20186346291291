const bucketPointsConfig = {
    High: {
      8: [
        24, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 24
      ],
      10: [
        55, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 55
      ],
      12: [
        150, 20, 8.5, 2, 0.6, 0.2, 0.2, 0.2, 0.6, 2, 8.5, 20, 150
      ],
      14: [
        300, 52, 15, 5, 2, 0.3, 0.2, 0.2, 0.2, 0.3, 2, 5, 15, 52, 300
      ],
      16: [
        1000, 100, 20, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 20, 100, 1000
      ]
    },
    Medium: {
      8: [
        9, 2.8, 1.3, 0.7, 0.4, 0.7, 1.3, 2.8, 9
      ],
      10: [
        10, 4, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 4, 10
      ],
      12: [
        20, 10, 4, 2, 1, 0.6, 0.3, 0.6, 1, 2, 4, 10, 20
      ],
      14: [
        50, 12, 8, 4, 1.5, 1, 0.5, 0.2, 0.5, 1, 1.5, 4, 8, 12, 50
      ],
      16: [
        100, 20, 10, 5, 2.5, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 2.5, 5, 10, 20, 100
      ]
    },
    Low: {
      8: [
        4, 2, 1.2, 0.9, 0.5, 0.9, 1.2, 2, 4
      ],
      10: [
        7, 2, 1.4, 1.2, 0.9, 0.5, 0.9, 1.2, 1.4, 2, 7
      ],
      12: [
        10, 3, 1.6, 1.4, 1.1, 0.9, 0.5, 0.9, 1.1, 1.4, 1.6, 3, 10
      ],
      14: [
        7, 3, 1.5, 1.4, 1.3, 1.1, 0.9, 0.5, 0.9, 1.1, 1.3, 1.4, 1.5, 3, 7
      ],
      16: [
        16, 9, 2, 1.4, 1.4, 1.2, 1.1, 0.9, 0.5, 0.9, 1.1, 1.2, 1.4, 1.4, 2, 9, 16
      ]
    }
  };
  
  export default bucketPointsConfig;
  