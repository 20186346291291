import { useState } from "react";
import { IoIosHand } from "react-icons/io";

interface ButtonProps {
  name: string;
  action: () => void;
  Icon: React.ComponentType<any>;
  disable?: boolean;
  disableDuration?: number;
  shouldntDisable?: boolean;
  additionalStyles?: string;
  additionalButtonStyles?: string;
  additionalIconStyles?: string;
  additionalTextStyles?: string;
}

const ActionButton: React.FC<ButtonProps> = ({
  name,
  action,
  Icon,
  disable,
  disableDuration,
  shouldntDisable,
  additionalStyles,
  additionalButtonStyles,
  additionalIconStyles,
  additionalTextStyles
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const disableButton = !isButtonEnabled || (disable && disable === true)
  setTimeout(() => {
    setIsButtonEnabled(true);
  }, disableDuration ? disableDuration : 1);

  const handleButtonClick = () => {
    if (!isButtonEnabled) return;
    action();
    if (shouldntDisable) return;

    // Immediately disable the button
    setIsButtonEnabled(false);

    // Re-enable the button after a delay (e.g., 500ms)
    setTimeout(() => {
      setIsButtonEnabled(true);
    }, 1000);
  };
  return (
    <div
      className={`flex flex-col items-center justify-center z-10 ${additionalStyles}`}
    >
      <button
        className={`flex items-center justify-center w-10 lg:w-14 h-10 lg:h-14 border-4 rounded-full m-1 mr-0 lg:m-4 mb-1 lg:mb-2 bg-bovada hover:shadow-lg hover:bg-black ${disableButton ? "cursor-not-allowed opacity-50" : "cursor-pointer"} ${additionalButtonStyles}`}
        onClick={handleButtonClick}
        disabled={!isButtonEnabled || disableButton}
      >
        <Icon className={`text-white text-1xl lg:text-4xl text-center text-white} ${additionalIconStyles}`} />
      </button>
      <h1 className={`text-sm lg:text-2xl font-bold text-center select-none ${disableButton ? "opacity-50" : "text-white"} ${additionalTextStyles}`}>
        {name}
      </h1>
    </div>
  );
};
export default ActionButton;
