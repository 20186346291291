import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface RSPSDepositModalProps {
    open: boolean;
    onClose: () => void;
}

const RSPSDepositModal: React.FC<RSPSDepositModalProps> = ({ open, onClose, onCloseWalletModal }) => {
    const [rspsGoldAmount, setRspsGoldAmount] = useState('');
    const [rspsServer, setRspsServer] = useState('');
    const { session } = useAuth();
    const navigate = useNavigate();
    const handleRspsGoldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRspsGoldAmount(event.target.value);
    };

    const handleRspsServerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRspsServer(event.target.value as string);
    };

    const handleSubmit = async () => {
        if(!session.user.id) return;
        try {
            const { data, error } = await supabase.rpc('create_rsps_deposit', { p_game: rspsServer, p_amount: parseFloat(rspsGoldAmount), v_player_id: session.user.id })
    
            if (error) {
                throw error;
            } else {
                toast.success('RSPS Gold deposit request submitted successfully!');
                onClose();
                if(onCloseWalletModal) {
                    onCloseWalletModal();
                }
                window.location.href = '/exchanges?view=rsps';
            }
    
        } catch (error) {
            console.error('Error creating RSPS gold deposit request:', error);
            toast.error('An error occurred while submitting the deposit request. Please try again.');
        }
    };

    const getServerIcon = () => {
        if (rspsServer === 'RuneWild') {
            return <Typography component="span" sx={{ color: '#05f783', fontWeight: 'bold', mr: 0.5 }}>B</Typography>;
        } else if (rspsServer === 'SpawnPk') {
            return <Typography component="span" sx={{ color: '#05f783', fontWeight: 'bold', mr: 0.5 }}>T</Typography>;
        } else {
            return null;
        }
    };

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white', // This is the default unfocused label color
        },
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    RSPS Gold Deposit Request
                </Typography>
                <FormControl fullWidth sx={{ mb: 2, ...textFieldSx }}>
                    <InputLabel>RSPS Server</InputLabel>
                    <Select
                        value={rspsServer}
                        label="RSPS Server"
                        onChange={handleRspsServerChange}
                    >
                        <MenuItem value="RuneWild">RuneWild</MenuItem>
                        <MenuItem value="SpawnPk">SpawnPk</MenuItem>
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        fullWidth
                        type="number"
                        value={rspsGoldAmount}
                        onChange={handleRspsGoldChange}
                        label="RSPS Gold Amount"
                        variant="outlined"
                        sx={textFieldSx}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {getServerIcon()}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    After submitting your request, you'll be provided with a list of approved vendors to contact via Discord for negotiation.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit} disabled={!rspsGoldAmount || !rspsServer}>
                        Submit Request
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RSPSDepositModal;