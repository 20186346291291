import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "./contexts/AuthContext";
import NewBlackjack from "./components/Blackjack-component/NewBlackjack";
import { supabase } from "./api/supabaseClient.js";
import LeftSidebar from "./components/LeftSidebar";
import Homepage from "./components/Homepage";
import UltimateTexas from "./components/Ultimate-Texas/UltimateTexas";
import DuelArena from "./components/Duel-Arena/DuelArena";
import Dharoks from "./components/Dharoks/Dharoks";
import Roulette from "./components/Roulette/Roulette.jsx";
import Dice from "./components/Dice/Dice";
import Exchanges from "./components/Exchange/Exchange";
import Plinko from "./components/Plinko/Plinko.jsx";
import FlowerPoker from "./components/Flower-Poker/FlowerPoker.jsx";
import FreeBetBlackjack from "./components/Blackjack-component/FreeBetBlackjack";
import ClickGif from './components/ClickAnimation';
import Footer from "./components/Footer"
import Mines from "./components/Mines/Mines.jsx";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilePage from "./components/Profile/ProfilePage.tsx";
import PasswordRecoveryPage from "./components/PasswordRecoveryPage";
import Fairness from "./components/Fairness/Fairness";
import Rewards from "./components/Rewards";
import { useBalance } from "./contexts/BalanceContext";
import VerifyDiscord from "./components/VerifyDiscord.tsx";

declare global {
  interface Window {
    Tawk_API: any;
  }
}

function App() {
  const [appLoading, setAppLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const { session, signedIn } = useAuth();
  const { setReferrer } = useBalance();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refCode = queryParams.get("r");
    if (refCode) {
      setReferrer(refCode);
    }
  }, []);
  useEffect(() => {
    const handleRefresh = () => {
      setRefreshKey((prevKey) => prevKey + 1);
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  useEffect(() => {
    if (session?.user?.id || !signedIn) {
      setAppLoading(false);
    }
  }, [signedIn, session]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/664fab72981b6c564773b981/1hujjbdn2';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);
  }, []);

  return (
    !appLoading ? (
      <Router>
        <div style={{ width: "min-content", position: "relative" }} className="block min-h-screen min-w-[100%] bg-primary-900 max-w-[100%]">
          <Helmet>
            <html lang="en" />
            <title>Sand Casino | Runescape Gambling Site</title>
            <meta name="description" content="Sand Casino - Runescape Gambling Site. Experience thrilling Runescape gambling games in a safe and fun environment." />
            <meta property="og:title" content="Sand Casino | Runescape Gambling Site" />
            <meta property="og:description" content="Experience thrilling Runescape gambling games in a safe and fun environment." />
            <meta property="og:url" content="https://sandcasino.io" />
            <meta property="og:type" content="website" />
            <meta name="language" content="English" />
            <link rel="canonical" href="https://sandcasino.io" />
            <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "WebSite",
                  "name": "Sand Casino",
                  "url": "https://sandcasino.io",
                  "description": "Runescape Gambling Site offering thrilling games in a safe environment."
                }
              `}
            </script>
          </Helmet>
          <ClickGif />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="flex min-h-screen w-full" style={{ position: "relative", zIndex: 2 }}>
            <LeftSidebar />
            <div style={{ overflow: 'auto', height: '100vh' }} className="flex-auto pt-16 md:pt-10">
              <Routes>
                <Route
                  path="/blackjack"
                  element={
                    <>
                      <Helmet>
                        <title>Blackjack | Sand Casino</title>
                        <meta name="description" content="Play Blackjack at Sand Casino. Experience the thrill of Runescape-style Blackjack in a safe and fun environment." />
                        <link rel="canonical" href="https://sandcasino.io/blackjack" />
                      </Helmet>
                      <NewBlackjack />
                    </>
                  }
                />
                <Route
                  path="/free-bet-blackjack"
                  element={
                    <>
                      <Helmet>
                        <title>Free Bet Blackjack | Sand Casino</title>
                        <meta name="description" content="Try Free Bet Blackjack at Sand Casino. Enjoy this exciting variant of Blackjack with special free bets." />
                        <link rel="canonical" href="https://sandcasino.io/free-bet-blackjack" />
                      </Helmet>
                      <FreeBetBlackjack />
                    </>
                  }
                />
                <Route
                  path="/ultimate-texas"
                  element={
                    <>
                      <Helmet>
                        <title>Ultimate Texas Hold'em | Sand Casino</title>
                        <meta name="description" content="Play Ultimate Texas Hold'em at Sand Casino. Test your poker skills in this exciting casino game variant." />
                        <link rel="canonical" href="https://sandcasino.io/ultimate-texas" />
                      </Helmet>
                      <UltimateTexas />
                    </>
                  }
                />
                <Route
                  path="/duel-arena"
                  element={
                    <>
                      <Helmet>
                        <title>Duel Arena | Sand Casino</title>
                        <meta name="description" content="Enter the Duel Arena at Sand Casino. Challenge other players in intense one-on-one battles." />
                        <link rel="canonical" href="https://sandcasino.io/duel-arena" />
                      </Helmet>
                      <DuelArena />
                    </>
                  }
                />
                <Route
                  path="/dharoks"
                  element={
                    <>
                      <Helmet>
                        <title>Dharok's | Sand Casino</title>
                        <meta name="description" content="Experience Dharok's at Sand Casino. Engage in this unique Runescape-inspired gambling game." />
                        <link rel="canonical" href="https://sandcasino.io/dharoks" />
                      </Helmet>
                      <Dharoks />
                    </>
                  }
                />
                <Route
                  path="/roulette"
                  element={
                    <>
                      <Helmet>
                        <title>Roulette | Sand Casino</title>
                        <meta name="description" content="Spin the Roulette wheel at Sand Casino. Try your luck in this classic casino game." />
                        <link rel="canonical" href="https://sandcasino.io/roulette" />
                      </Helmet>
                      <Roulette />
                    </>
                  }
                />
                <Route
                  path="/dice"
                  element={
                    <>
                      <Helmet>
                        <title>Dice | Sand Casino</title>
                        <meta name="description" content="Roll the Dice at Sand Casino. Simple, fast-paced gambling action awaits." />
                        <link rel="canonical" href="https://sandcasino.io/dice" />
                      </Helmet>
                      <Dice />
                    </>
                  }
                />
                <Route
                  path="/plinko"
                  element={
                    <>
                      <Helmet>
                        <title>Plinko | Sand Casino</title>
                        <meta name="description" content="Play Plinko at Sand Casino. Watch the ball drop and win big in this exciting game of chance." />
                        <link rel="canonical" href="https://sandcasino.io/plinko" />
                      </Helmet>
                      <Plinko />
                    </>
                  }
                />
                <Route
                  path="/flower-poker"
                  element={
                    <>
                      <Helmet>
                        <title>Flower Poker | Sand Casino</title>
                        <meta name="description" content="Try Flower Poker at Sand Casino. A unique Runescape-inspired poker variant for gambling enthusiasts." />
                        <link rel="canonical" href="https://sandcasino.io/flower-poker" />
                      </Helmet>
                      <FlowerPoker />
                    </>
                  }
                />
                <Route
                  path="/mines"
                  element={
                    <>
                      <Helmet>
                        <title>Mines | Sand Casino</title>
                        <meta name="description" content="Play Mines at Sand Casino. Navigate the minefield and uncover big wins in this thrilling game." />
                        <link rel="canonical" href="https://sandcasino.io/mines" />
                      </Helmet>
                      <Mines />
                    </>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <>
                      <Helmet>
                        <title>Profile | Sand Casino</title>
                        <meta name="description" content="View and manage your Sand Casino profile. Check your stats, history, and more." />
                        <link rel="canonical" href="https://sandcasino.io/profile" />
                      </Helmet>
                      <ProfilePage />
                    </>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <>
                      <Helmet>
                        <title>Reset Password | Sand Casino</title>
                        <meta name="description" content="Reset your Sand Casino password. Forgot your password? No problem! We've got you covered." />
                        <link rel="canonical" href="https://sandcasino.io/reset-password" />
                      </Helmet>
                      <PasswordRecoveryPage />
                    </>
                  }
                />
                <Route
                  path="/verify-discord"
                  element={
                    <VerifyDiscord />
                  }
                />
                <Route
                  path="/exchanges"
                  element={
                    <>
                      <Helmet>
                        <title>Exchanges | Sand Casino</title>
                        <meta name="description" content="Explore our exchanges at Sand Casino. Buy and sell RSPS Gold and other items." />
                        <link rel="canonical" href="https://sandcasino.io/exchanges" />
                      </Helmet>
                      <Exchanges initialView="gold" />
                    </>
                  }
                />
                <Route
                  path="/rewards"
                  element={
                    <>
                      <Helmet>
                        <title>Rewards | Sand Casino</title>
                        <meta name="description" content="Explore our rewards at Sand Casino. Redeem your rewards and earn points." />
                        <link rel="canonical" href="https://sandcasino.io/rewards" />
                      </Helmet>
                      <Rewards />
                    </>
                  }
                />
                <Route
                  path="/fairness"
                  element={
                    <>
                      <Helmet>
                        <title>Fairness | Sand Casino</title>
                        <meta name="description" content="Learn about the fairness of Sand Casino. We're committed to transparency and fairness in our operations." />
                        <link rel="canonical" href="https://sandcasino.io/fairness" />
                      </Helmet>
                      <Fairness />
                    </>
                  }
                />
                <Route
                  path="/"
                  element={
                    <>
                      <Helmet>
                        <title>Sand Casino | Runescape Gambling Site</title>
                        <meta name="description" content="Welcome to Sand Casino, the premier Runescape gambling site. Explore our wide range of games and start your adventure." />
                        <link rel="canonical" href="https://sandcasino.io" />
                      </Helmet>
                      <div className="">
                        <Homepage />
                      </div>
                    </>
                  }
                />
              </Routes>
              <FooterWithLocation />
            </div>
          </div>
        </div>
      </Router>
    ) : (
      <div>Loading...</div>
    )
  );
}

function FooterWithLocation() {
  const location = useLocation();
  const isExchangesPage = location.pathname === '/exchanges';
  return <Footer removeTopMargin={isExchangesPage} />;
}

export default App;