import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, IconButton, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import { useBalance } from '../../contexts/BalanceContext';

interface CryptoTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const CryptoTransactionModal: React.FC<CryptoTransactionModalProps> = ({ open, onClose, type }) => {
    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [amountError, setAmountError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [isUSD, setIsUSD] = useState(true);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const tokenPrice = 0.20;
    const { session } = useAuth();
    const { goldBalance } = useBalance();

    const validateAmount = (value: string) => {
        const numAmount = parseFloat(value);
        if (isNaN(numAmount) || numAmount <= 0) {
            setAmountError('Please enter a valid amount greater than 0');
            return false;
        }
        
        if (type === 'withdraw') {
            const tokensToWithdraw = isUSD ? numAmount / tokenPrice : numAmount;
            if (tokensToWithdraw > goldBalance) {
                setAmountError(`Insufficient balance. Maximum available: ${isUSD ? (goldBalance * tokenPrice).toFixed(2) + ' USD' : goldBalance + ' tokens'}`);
                return false;
            }
        }
        
        if (isUSD && numAmount < 10) {
            setAmountError('Minimum amount is $10 USD');
            return false;
        }
        
        setAmountError('');
        return true;
    };

    const validateWalletAddress = (address: string) => {
        const ltcRegex = /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/;
        if (!address) {
            setAddressError('Wallet address is required');
            return false;
        }
        if (!ltcRegex.test(address)) {
            setAddressError('Invalid Litecoin wallet address');
            return false;
        }
        setAddressError('');
        return true;
    };

    const getMaxAmount = () => {
        if (type === 'withdraw') {
            return isUSD 
                ? (goldBalance * tokenPrice).toFixed(2)  // Max USD
                : goldBalance.toString();                // Max tokens
        }
        return '';  // No max for deposits
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        // Allow empty value or valid numbers
        if (value === '' || (!isNaN(Number(value)) && Number(value) >= 0)) {
            if (type === 'withdraw') {
                const numValue = Number(value);
                const maxAmount = isUSD 
                    ? goldBalance * tokenPrice
                    : goldBalance;
                
                // If input exceeds max, set to max
                if (numValue > maxAmount) {
                    setAmount(maxAmount.toString());
                    return;
                }
            }
            setAmount(value);
            if (value !== '') validateAmount(value);
        }
    };

    const handleSwap = () => {
        setIsUSD(!isUSD);
        setAmount(''); // Reset amount when swapping
    };

    const calculateConversion = () => {
        if (amount === '') return '0';
        const numAmount = parseFloat(amount);
        return isUSD
            ? (numAmount / tokenPrice).toFixed(2)
            : (numAmount * tokenPrice).toFixed(2);
    };

    const openChatWithMessage = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize();
            window.Tawk_API.setAttributes({
                name: session?.user?.user_metadata?.username
            }, function(error: any) {
                if (error) {
                    console.error('Error setting Tawk attributes:', error);
                } else {
                    // Send message after attributes are set successfully
                    window.Tawk_API.addEvent('crypto-transaction', {
                        type: 'withdrawal',
                        amount: isUSD ? `$${amount} USD` : `${amount} Gold Tokens`,
                    }, function(error: any) {
                        if (error) {
                            console.error('Error sending Tawk event:', error);
                        }
                    });
                }
            });
        } else {
            console.error('Tawk API not available');
        }
    };

    const handleSubmit = async () => {
        const usdAmount = isUSD ? amount : calculateConversion();
        if (!validateAmount(usdAmount)) {
            return;
        }

        if (type === 'deposit') {
            try {
                const { data, error } = await supabase.functions.invoke('create-invoice', {
                    body: { 
                        amount: parseFloat(usdAmount), 
                        userId: session.user.id,
                        type: 'deposit'
                    },
                });
                if (data && data.paymentUrl) {
                    window.open(data.paymentUrl, '_blank');
                    onClose();
                    window.location.href = '/exchanges?view=crypto';
                } else {
                    throw error;
                }
            } catch (error) {
                console.error('Error creating invoice:', error);
                toast.error('An error occurred while creating the invoice. Enter valid amount between 10 and 5000 USD');
            }
        } else {
            try {
                // Calculate coins amount based on whether input is USD or coins
                const coinsAmount = isUSD ? parseFloat(calculateConversion()) : parseFloat(amount);

                const { data, error } = await supabase.functions.invoke('create-withdrawal', {
                    body: { 
                        userId: session.user.id,
                        amount: parseFloat(usdAmount),
                        walletAddress: walletAddress
                    },
                });
    
                if (error) throw error;
    
                if (data?.success) {
                    toast.success('Withdrawal request created successfully');
                    setInfoDialogOpen(true);
                    onClose();
                } else {
                    throw new Error('Failed to create withdrawal request');
                }
            } catch (error) {
                console.error('Error creating withdrawal:', error);
                toast.error('An error occurred while creating the withdrawal request. If you already have a pending withdrawal, please wait for admin approval.');
            }
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        onClose();
        window.location.href = '/exchanges?view=crypto';
    };

    const handleMaxClick = () => {
        const maxAmount = getMaxAmount();
        setAmount(maxAmount);
        validateAmount(maxAmount);
    };

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white', // This is the default unfocused label color
        },
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        {type === 'deposit' ? 'Deposit Crypto' : 'Withdraw Litecoin'}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                            fullWidth
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                            onKeyPress={(e) => {
                                // Allow only numbers and control keys
                                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                    e.preventDefault();
                                }
                            }}
                            label={isUSD ? 'Amount in USD' : 'Amount in Gold tokens'}
                            variant="outlined"
                            error={!!amountError}
                            helperText={amountError}
                            sx={textFieldSx}
                            InputProps={{
                                endAdornment: type === 'withdraw' && (
                                    <Button 
                                        onClick={handleMaxClick}
                                        sx={{ 
                                            minWidth: 'auto',
                                            px: 1,
                                            mr: -1
                                        }}
                                    >
                                        MAX
                                    </Button>
                                )
                            }}
                        />
                        <IconButton onClick={handleSwap} sx={{ ml: 1 }}>
                            <SwapHorizIcon />
                        </IconButton>
                    </Box>

                    {type === 'withdraw' && (
                        <TextField
                            fullWidth
                            value={walletAddress}
                            onChange={(e) => {
                                setWalletAddress(e.target.value);
                                if (e.target.value) validateWalletAddress(e.target.value);
                            }}
                            label="Wallet Address"
                            variant="outlined"
                            error={!!addressError}
                            helperText={addressError}
                            sx={{ ...textFieldSx, mb: 2 }}
                            placeholder="Enter your litecoin wallet address"
                        />
                    )}

                    <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {isUSD
                            ? <>${amount || '0'} USD = {calculateConversion()} <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: '20px', height: '20px' }} /> </>
                            : (
                                <>
                                    {amount || '0'} <GoldCoin additionalStyles="mx-1 mb-1" style={{ width: '20px', height: '20px' }} /> = ${calculateConversion()} USD
                                </>
                            )
                        }
                    </Typography>
                    <div className='flex items-center'>
                        <GoldCoin additionalStyles='mr-2' />
                        <Typography variant="body2">
                            <span className='text-xl'> = $0.20</span>
                        </Typography>
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleSubmit}>
                            {type === 'deposit' ? 'Deposit' : 'Withdraw'}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Dialog
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        backgroundImage: `url(${background})`,
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                        border: '8px solid black',
                        borderImage: `url(${border}) 2 stretch`,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: 'text.primary', fontWeight: 'bold', textAlign: 'center', pt: 3 }}>
                    Crypto Withdrawal Notice
                </DialogTitle>
                <DialogContent sx={{ px: 4, pb: 4 }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.2rem' }}>
                            Crypto withdrawals currently require admin approval (this may take some time, we apologize). This is a temporary measure to ensure the integrity of the system.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        className='rs-button' 
                        variant='contained' 
                        onClick={handleInfoDialogClose} 
                        sx={{ 
                            bgcolor: 'secondary.main',
                            color: 'text.primary',
                            fontWeight: 'bold',
                            px: 3,
                            '&:hover': {
                                bgcolor: 'secondary.dark',
                            }
                        }} 
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CryptoTransactionModal;