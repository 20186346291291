export enum Suit {
  Clubs = "Clubs",
  Diamonds = "Diamonds",
  Hearts = "Hearts",
  Spades = "Spades",
}

export enum CardValue {
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  Six = "6",
  Seven = "7",
  Eight = "8",
  Nine = "9",
  Ten = "10",
  Jack = "Jack",
  Queen = "Queen",
  King = "King",
  Ace = "Ace",
}

export class Card {
  suit?: Suit;
  value?: CardValue;
  faceup: boolean;

  constructor(suit?: Suit, value?: CardValue, faceUp = true) {
    this.suit = suit;
    this.value = value;
    this.faceup = faceUp;
  }

  getFaceValue() {
    return this.faceup ? this.value : "back";
  }
  static fromPlainObject(obj: any): Card {
    const card = new Card(obj.suit, obj.value, obj.faceup);
    return card;
  }
  public toPokerString(): string | null {
    if (!this.value || !this.suit) return null;
    const suitChar = this.suit.charAt(0).toLowerCase();
    let valueChar = this.value.length === 1
      ? this.value
      : this.value.charAt(0).toUpperCase();
    valueChar = this.value === "10" ? "T" : valueChar;
    return `${valueChar}${suitChar}`;
  }
  static fromPokerString(pokerStr: string): Card {
    const valueChar = pokerStr.charAt(0);
    const suitChar = pokerStr.charAt(1).toUpperCase();

    let value: CardValue;
    switch (valueChar) {
      case "T":
        value = CardValue.Ten;
        break;
      case "J":
        value = CardValue.Jack;
        break;
      case "Q":
        value = CardValue.Queen;
        break;
      case "K":
        value = CardValue.King;
        break;
      case "A":
        value = CardValue.Ace;
        break;
      default:
        value = valueChar as CardValue; // This assumes valid input for 2-9
    }

    let suit: Suit;
    switch (suitChar) {
      case "c":
        suit = Suit.Clubs;
        break;
      case "d":
        suit = Suit.Diamonds;
        break;
      case "h":
        suit = Suit.Hearts;
        break;
      case "s":
        suit = Suit.Spades;
        break;
      default:
        throw new Error("Invalid suit character");
    }

    return new Card(suit, value);
  }
}
