import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { fetchAndUpdateBalance, supabase } from "../../api/supabaseClient.js";
import GeneralBettingWindow from "../GeneralBettingWindow";
import Phaser from "phaser";
import "./Dharoks.css";
import { toast } from 'react-toastify';
import bankpinfail from '../../assets/bankpinfail.mp3';
import RulesComponent from '../../components/RulesComponent';
import { dharokDeathmatchRules } from '../../components/gameRules';
import PayoutDisplay from '../Ultimate-Texas/PayoutDisplay';
import { useVolume } from '../../contexts/VolumeContext'; // Import useVolume hook
import { useBet } from '../../hooks/useBet';
import EnhancedWinningsComponent from '../EnhancedWinningsComponent';
import withFadeInDelay from '../withFadeInDelay';
import confetti from 'canvas-confetti';
const FadedInWinningsComponent = withFadeInDelay(EnhancedWinningsComponent, 50);

let player1, player2;
let player1HealthBar, player2HealthBar;
let player1HitSplat, player2HitSplat;
let attackSound; // Declare a global variable outside of the component function

export default function Dharoks() {
  const { session, signedIn } = useAuth();
  const {
    bet,
    setBet,
    handleBetChange: handleMainBetChange,
    handleBetBlur: handleMainBetBlur,
    handleHalfBet: handleMainHalfBet,
    handleDoubleBet: handleMainDoubleBet,
  } = useBet(!signedIn ? 1 : 0);

  const [game, setGame] = useState(null); // To hold the Phaser game instance
  const [gameStarted, setGameStarted] = useState(false);
  const [winnings, setWinnings] = useState(0);
  const [player1Hits, setPlayer1Hits] = useState([]);
  const [player2Hits, setPlayer2Hits] = useState([]);
  const [duelId, setDuelId] = useState(null);
  const [gameFetched, setGameFetched] = useState(false);
  const [firstHit, setFirstHit] = useState(0);
  const [result, setResult] = useState("");
  const [speed, setSpeed] = useState(1);
  const gameOver = useRef(false);
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();

  const [shake, setShake] = useState(false);
  const bankPinSound = new Audio(bankpinfail);

  const show = useRef(false);

  const { volumes, mutedStates, setActiveGame } = useVolume(); // Use the useVolume hook to get volumes


  // Phaser game configuration
  const config = {
    type: Phaser.AUTO,
    width: 800,
    height: 600,
    parent: "gameContainer",
    // scale: {
    //   mode: Phaser.Scale.FIT, // Scale the game to fit the parent
    //   autoCenter: Phaser.Scale.CENTER_BOTH, // Center the game
    // },
    scene: {
      preload: preload,
      create: create,
    },
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
      },
    },
    transparent: true, // Duel Arena color
    autoFocus: false,
  };
  // Game variables

  function preload() {
    this.load.image(
      "player",
      "/dharoksprites/new1.png",
    );
    this.load.image(
      "whipAnimation1",
      "/dharoksprites/new2.png",
    ); // Loading the whip animation frame
    this.load.image(
      "whipAnimation2",
      "/dharoksprites/new3.png",
    ); // Loading the whip animation frame
    this.load.image(
      "whipAnimation3",
      "/dharoksprites/new4.png",
    ); // Loading the whip animation frame
    this.load.image(
      "hit",
      "/dharoksprites/hit.png",
    );
    this.load.image(
      "hitSplat",
      "/dasprites/red_hitsplat.webp",
    );
    this.load.image(
      "hitSplat2",
      "/dasprites/red_hitsplat_tinted.webp",
    );
    this.load.image(
      "blueSplat",
      "/dasprites/blue_hitsplat.webp",
    );
    this.load.image(
      "blueSplat2",
      "/dasprites/blue_hitsplat_tinted.webp",
    );
    this.load.image(
      "background",
      "/dharoksprites/gebackground.png",
    );
    this.load.audio("attackSound", "/dharoksprites/dhattack.mp3");
    this.load.image("arrow", "/dasprites/arrow_down.png");
  }

  function create() {
    // let background = this.add.image(0, 0, "background").setOrigin(0, 0);
    // let scaleX = config.width / background.width;
    // let scaleY = config.height / background.height;
    // let scale = Math.max(scaleX, scaleY);
    // background.setScale(scale).setScrollFactor(0);

    this.game.events.on('hidden', () => {
      this.scene.resume();
      this.game.sound.resumeAll();
    });

    // this.game.events.on('visible', () => {
    //   this.scene.resume();
    //   this.game.sound.resumeAll();
    // });
    var playerScale = 0.6;
    
    // Create player1 first
    player1 = this.physics.add.sprite(350, 300, "player").setScale(playerScale);
    
    // Create the highlighted tile at player1's position
    let highlightedTile = this.add.graphics();
    highlightedTile.fillStyle(0x00FFFF, 0.1); // Cyan fill with very low opacity (10%)
    highlightedTile.fillRect(player1.x - 165, player1.y + 5, 100, 100);
    
    highlightedTile.lineStyle(2, 0x00FFFF, 1); // Cyan border with full opacity
    highlightedTile.strokeRect(player1.x - 165, player1.y + 5, 100, 100);
    
    // Ensure the highlighted tile is behind player1
    highlightedTile.setDepth(player1.depth - 1);
    
    player2 = this.physics.add.sprite(400, 300, "player").setScale(playerScale);
    player2.setFlipX(true);

    // Create an arrow sprite and position it above Player 2
    let arrow = this.add.sprite(player2.x + 105, player2.y - 170, "arrow");
    arrow.setScale(1.5); // Adjust scale as needed

    player1.health = 99;
    player2.health = 99;

    var hitSplatScale = 2.5;
    player1HitSplat = this.add
      .image(player1.x, player1.y, "hitSplat")
      .setScale(hitSplatScale)
      .setVisible(false);
    player2HitSplat = this.add
      .image(player2.x, player2.y, "hitSplat")
      .setScale(hitSplatScale)
      .setVisible(false);
    player1HitSplat.text = this.add
      .text(player1.x, player1.y - 50, "", { fontSize: "20px", fill: "#FFF" })
      .setVisible(false)
      .setOrigin(0.5, 0.5);
    player2HitSplat.text = this.add
      .text(player2.x, player2.y - 50, "", { fontSize: "20px", fill: "#FFF" })
      .setVisible(false)
      .setOrigin(0.5, 0.5);




    // Health bars
    player1HealthBar = createHealthBar(
      this,
      player1.x - 120,
      player1.y - player1.displayHeight / 2 - 20,
    );
    player2HealthBar = createHealthBar(
      this,
      player2.x + 80,
      player2.y - player2.displayHeight / 2 - 20,
    );
    attackSound = this.sound.add('attackSound'); // Load and store the sound globally
    startCombat(this);
  }

  function attack(scene, attacker, target, targetHitSplat, targetHealthBar, damage) {
    if (!gameOver.current) {
      target.health -= damage;
      target.health = Math.max(target.health, 0); // Prevent negative health
      attacker.setTexture("player");

      // Play attack sound using the global sound object
      attackSound.volume = mutedStates["dharoks"] ? 0 : volumes["dharoks"] || 1; // Set the volume for duel
      attackSound.play();

      if (damage === 0) {
        targetHitSplat.setTexture(target === player1 ? "blueSplat2" : "blueSplat");
      } else {
        targetHitSplat.setTexture(target === player1 ? "hitSplat2" : "hitSplat");
      }

      let animationSequence = [
        { delay: 0, action: () => attacker.setTexture("player").setScale(0.6) },
        { delay: 100/speed, action: () => attacker.setTexture("whipAnimation1").setScale(0.6) },
        { delay: 300/speed, action: () => attacker.setTexture("whipAnimation2").setScale(0.6) },
        { delay: 400/speed, action: () => {
          attacker.setTexture("whipAnimation3").setScale(0.6);
          if (attacker === player1) {
            player2.setTexture("hit").setScale(0.7);
          } else {
            player1.setTexture("hit").setScale(0.7);
          }
        }},
        { delay: 500/speed, action: () => attacker.setTexture("player").setScale(0.6) },
        { delay: 800/speed, action: () => {
          if (attacker === player1) {
            player2.setTexture("player").setScale(0.6);
          } else {
            player1.setTexture("player").setScale(0.6);
          }
        }}
      ];

      animationSequence.forEach(({ delay, action }) => {
        scene.time.delayedCall(delay, action);
      });
      
      // Positioning and showing the hit splat centered on the target
      targetHitSplat.x = attacker === player2 ? target.x - 110 : target.x + 110;
      targetHitSplat.y = target.y - 20; // Adjusted for visual effect
      targetHitSplat.setVisible(true);

      // Update and position the hit splat text
      targetHitSplat.text.setText(damage.toString()); // Set the hit number
      targetHitSplat.text.x = targetHitSplat.x;
      targetHitSplat.text.y = targetHitSplat.y;
      targetHitSplat.text.setVisible(true);

      scene.time.delayedCall(600, () => {
        targetHitSplat.setVisible(false);
        targetHitSplat.text.setVisible(false);
      });

      // Update health display and health bar
      if (damage > 0) updateHealthBar(targetHealthBar, target.health);

      if (target.health <= 0 && !gameOver.current) {
        gameOver.current = true;
        show.current = true;
        setGameStarted(false);
        let winner = target === player1 ? "House Wins" : "Player Wins";
        setResult(winner);
        scene.combatTimer.remove(); // Stop the combat when the game is over
      }
    }
  }

  function startCombat(scene) {
    let turn = firstHit; // 1 for player1's turn (player), 2 for player2's turn (house)
    let p1Idx = 0;
    let p2Idx = 0;
    let damage;
    
    scene.combatTimer = scene.time.addEvent({
      delay: 1500/speed,
      callback: () => {
        if (!gameOver.current) {
          if (turn === 1) {
            // Player's turn (player1)
            damage = player1Hits[p1Idx];
            p1Idx++;
            attack(scene, player1, player2, player2HitSplat, player2HealthBar, damage);
            turn = 2; // Next turn will be house's
          } else {
            // House's turn (player2)
            damage = player2Hits[p2Idx];
            p2Idx++;
            attack(scene, player2, player1, player1HitSplat, player1HealthBar, damage);
            turn = 1; // Next turn will be player's
          }
        } else {
          scene.combatTimer.remove(); // Stop the combat when the game is over
        }
      },
      loop: true
    });
  }

  function createHealthBar(scene, x, y) {
    // Create the background bar for lost health
    let backgroundBar = scene.add.graphics({ x: x, y: y });
    backgroundBar.fillStyle(0xff0000, 1);
    backgroundBar.fillRect(0, 0, 50, 8); // Draw at the Graphics object's top-left corner

    // Create the foreground green bar for current health
    let healthBar = scene.add.graphics({ x: x, y: y });
    healthBar.fillStyle(0x00ff00, 1);
    healthBar.fillRect(0, 0, 50, 8); // Similarly, draw at the Graphics object's top-left corner

    return { bar: healthBar, background: backgroundBar };
  }

  function updateHealthBar(healthBar, health) {
    // Clear only the green part of the health bar (current health)
    healthBar.bar.clear();

    // Redraw the green part based on the current health
    healthBar.bar.fillStyle(0x00ff00, 1);
    healthBar.bar.fillRect(0, 0, 50 * (health / 100), 8);
  }
  async function createAndFetchGame() {
    setResult("");
    let {data, error} = {};
    if (signedIn) {
      ({data, error} = await supabase.rpc("start_dharoks", {
        v_bet: bet,
      v_player_id: session?.user.id,
        v_type: balanceType
      }));
    } else {
      ({data, error} = await supabase.rpc("start_dharoks_guest", {
        v_bet: bet,
      }));
    }
    await fetchBalances();

    if (error) {
      setGameStarted(false);
      toast.error(error.message);
      bankPinSound.play();
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 200);
      console.error("Error creating game:", error);
      return null;
    }

    return data;
  }

  useEffect(() => {
    const startGame = async () => {
      if (bet > getActiveBalance() && signedIn) {
        toast.error("Insufficient balance. Bet cannot be higher than balance.");
        bankPinSound.play();
        setGameStarted(false);
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return;
      }
      const newGameState = await createAndFetchGame();
      if (newGameState) {
        confetti.reset();
        show.current = false;
        if (signedIn) {
          setDuelId(newGameState.id);
          setFirstHit(newGameState.first_hit);
          setWinnings(newGameState.winnings);
          setPlayer1Hits(newGameState.player1_hits);
          setPlayer2Hits(newGameState.player2_hits);
        } else {
          setFirstHit(newGameState.f9);
          setWinnings(newGameState.f8);
          setPlayer1Hits(newGameState.f6);
          setPlayer2Hits(newGameState.f7);
        }
      }
    };
    if (gameStarted && !gameFetched) {
      startGame();
    }
  }, [gameStarted]);
  useEffect(() => {
    const fetchActiveGame = async () => {
      const { data, error } = await supabase.rpc("dharok_fetch_active", {
        v_player_id: session?.user.id,
      });
      if (error) {
        console.error("Error fetching active game:", error);
        return null;
      }
      if (data) {
        setGameFetched(true)
        setDuelId(data.id);
        changeBalanceType(data.type);
        setBet(data.bet);
        setFirstHit(data.first_hit);
        setWinnings(data.winnings);
        setPlayer1Hits(data.player1_hits);
        setPlayer2Hits(data.player2_hits);
        setGameStarted(true);
      }
    }
    if (session?.user.id.length > 0) {
      fetchActiveGame();
    }
  }, [session?.user.id]);
  useEffect(() => {
    if (player1Hits.length > 0 && player2Hits.length > 0) {
      gameOver.current = false;
      if (game) {
        game.destroy(true);
      }
      const newGame = new Phaser.Game(config);
      setGame(newGame);
    }
  }, [player1Hits, player2Hits]);
  useEffect(() => {
    return () => {
      if (game) {
        game.destroy(true);
      }
    };
  }, [game]);

  useEffect(() => {
    const endGame = async () => {
      const { data, error } = await supabase.rpc("end_dharok", {
        deathmatch_id: duelId,
        v_player_id: session?.user.id,
      });
      if (error) {
        console.error("Error ending game:", error);
      }
      setGameFetched(false);
      await fetchBalances();
    };
    if (gameOver.current && signedIn) {
      endGame();
    }
  }, [gameOver.current]);
  useEffect(() => {
    setDisableModifications(gameStarted);
  }, [gameStarted])
  useEffect(() => {
    setActiveGame('dharoks');
    return () => {
      setDisableModifications(false);
    }
  }, [])


  const backgroundImage = "/dharoksprites/gebackground.png";
  return (
    <>
      <div
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
        className="main-container">
        <GeneralBettingWindow
          bets={[
            {
              name: "Amount",
              bet: bet,
              handleAmountChange: handleMainBetChange,
              handleBlur: handleMainBetBlur,
              handleHalfBet: handleMainHalfBet,
              handleDoubleBet: handleMainDoubleBet,
              valid: bet > 0,
              winnings: winnings,
              winState: result
            }
          ]}
          startGame={() => {
            setGameStarted(true);
          }}
          gameStarted={(!gameOver.current && gameStarted) || gameFetched}
          signedIn={signedIn}
          guest={!signedIn}
          gameOver={show.current}
          showChips={true}
          shake={shake}
          balanceType={balanceType}
          gameId="dharoks"
          speed={speed}
          setSpeed={setSpeed}
        />
        <div
          className="flex flex-grow items-center justify-center relative"
        >
          <div id="gameContainer">
            {gameOver.current && (
              <FadedInWinningsComponent
                winnings={winnings}
                push={false}
                gameOver={show.current}
                shouldPlaySound={false}
                additionalStyles={'w-24 h-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'}
              />
            )}
          </div>
        </div>
      </div>
      <RulesComponent rules={dharokDeathmatchRules} />
    </>
  );
}
