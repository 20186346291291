import React, { useState, useEffect } from "react";
import { PiPokerChipDuotone, PiPokerChipFill } from "react-icons/pi";
import { FaEquals } from "react-icons/fa";

export default function PayoutDisplay({
  name,
  payouts,
  additionalStyles,
  disableWidth
}: {
  name: string;
  payouts: string[];
  additionalStyles?: string;
  disableWidth?: number;
}) {
  // State to store the width of the screen
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const finalDisableWidth = disableWidth ? disableWidth : 1442;

  // Effect hook to update the width on screen resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Conditionally render content based on the screen width
  return (
    <div>
      {screenWidth >= finalDisableWidth && (
        <div className={`hidden lg:flex flex-col items-center justify-center p-4 rounded-md z-0 
          
          shadow-[inset_0_2px_4px_rgba(0,0,0,0.9)]
          ${additionalStyles}`}>
          <h1 className="font-bold text-center text-2xl select-none text-yellow-500 mb-1">
            {name}
          </h1>
          <div className="text-white text-center">
            {payouts.map((payout, index) => (
              <p
                key={index}
                className="text-lg whitespace-nowrap select-none mb-0.5"
              >
                {payout}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}