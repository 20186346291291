import React, { useEffect, useState } from 'react';
import PayoutDisplay from '../Ultimate-Texas/PayoutDisplay';
import { GoldCoin } from '../Coins';
import { useVolume } from '../../contexts/VolumeContext';
import './FreeBetSideBets.css';

export default function SidebetsDisplay({freebets}: {freebets: number}) {
    const potofgold = [
        "Free Bet Count",
        "5 or more: 100 to 1",
        "4: 50 to 1",
        "3: 25 to 1",
        "2: 12 to 1",
        "1: 3 to 1",
    ]
    const Push22 = [
        "Dealer 22: 11 to 1"
    ]
    const { mutedStates, volumes, activeGame } = useVolume();
    const audio = new Audio("/freebet.mp3");
    const [firstFreeBet, setFirstFreeBet] = useState(true);

    useEffect(() => {
        if (freebets > 0) {
            audio.volume = mutedStates[activeGame] ? 0 : volumes[activeGame];
            audio.play();
            if (firstFreeBet) {
                setTimeout(() => {
                    setFirstFreeBet(false);
                }, 2000);
            }
        }
    }, [freebets]);

    return (
        <>
            <div className="hidden lg:block absolute bottom-0 left-0 ml-4 mb-4 flex flex-col items-center justify-center z-10">
                <div className="relative">
                    <PayoutDisplay name="Pot of Gold" payouts={potofgold}/>
                    {freebets > 0 && (
                        <div className={`absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ${firstFreeBet ? 'animate-from-top-left' : ''}`}>
                            <GoldCoin style={{width: "60px", height: "60px"}}/>
                            <div className={`absolute inset-0 flex items-center justify-center text-4xl text-white font-bold`}>
                                {freebets}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="hidden lg:block absolute bottom-0 right-0 mr-4 mb-4 flex flex-col items-center justify-center z-10">
                <PayoutDisplay name="Push 22" payouts={Push22} additionalStyles='min-w-[237.43px] min-h-[200px] px-2'/>
            </div>
        </>
    )
}