import { createClient } from '@supabase/supabase-js';
export const supabase = createClient(
    "https://iisyepdfwnlkkixdyevb.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlpc3llcGRmd25sa2tpeGR5ZXZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ0MjM2MzAsImV4cCI6MjAwOTk5OTYzMH0.o5XC4ky48WrlnCRoqJBscg8J7HAyt-HXW7i7fxjHEbY"
);
export const fetchAndUpdateBalance = async (playerId, setMoney) => {
    try {
      const { data, error } = await supabase
        .from("players")
        .select("balance")
        .eq("uuid", playerId)
        .single();
  
      if (error) {
        console.error("Error fetching balance:", error);
        return;
      }
  
      if (data && data.balance !== undefined) {
        setMoney(data.balance);
        return data.balance;
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };
