import React, { useState } from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';

interface SpeedControlProps {
    gameId: string;
    disableModification: boolean;
    additionalStyles?: string;
    speed: number;
    setSpeed: Function;
    maxSpeed?: number;
}

const SpeedControl: React.FC<SpeedControlProps> = ({ gameId, disableModification, additionalStyles, speed, setSpeed, maxSpeed }) => {
    const toggleSpeed = () => {
        setSpeed((prevSpeed) => (prevSpeed % (maxSpeed || 3)) + 1);
    };

    return (
        <div className={`speed-control ${additionalStyles}`}>
            <button 
                onClick={toggleSpeed} 
                disabled={disableModification} 
                className={`${disableModification ? 'cursor-not-allowed' : 'cursor-pointer'} speed-button flex items-center text-white font-bold py-1 px-2 rounded`}
                title="Adjust game speed"
            >
                <FaAngleDoubleRight className="mr-1" size={14} />
                <span className="text-xs">{speed}x</span>
            </button>
        </div>
    );
};

export default SpeedControl;