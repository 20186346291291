import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { useBalance } from "../../contexts/BalanceContext.js";
import { toast } from 'react-toastify';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";

interface GoldTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const GoldTransactionModal: React.FC<GoldTransactionModalProps> = ({ open, onClose, type }) => {
    const [method, setMethod] = useState('07 Gold');
    // Remove the world state
    // const [world, setWorld] = useState('Members world');
    const [gameGoldAmount, setGameGoldAmount] = useState('');
    const [tokenAmount, setTokenAmount] = useState('');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [rsUsername, setRsUsername] = useState('');
    const osGoldRate = 1; // 1 Gold token = 1M OS Gold for deposits
    const rs3GoldRate = 10; // 1 Gold token = 10M RS3 Gold for deposits
    const withdrawalMultiplier = 1.08;
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const [errors, setErrors] = useState({
        gameGoldAmount: '',
        rsUsername: ''
    });

    const getRate = () => {
        const baseRate = method === '07 Gold' ? osGoldRate : rs3GoldRate;
        return type === 'withdraw' ? baseRate / withdrawalMultiplier : baseRate;
    };

    useEffect(() => {
        // Update values when type changes
        if (gameGoldAmount) {
            const rate = getRate();
            setTokenAmount((parseFloat(gameGoldAmount) / rate).toFixed(2));
        } else if (tokenAmount) {
            const rate = getRate();
            setGameGoldAmount((parseFloat(tokenAmount) * rate).toFixed(2));
        }
    }, [type]);

    const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMethod((event.target as HTMLInputElement).value);
        setGameGoldAmount('');
        setTokenAmount('');
    };

    // Remove the handleWorldChange function
    // const handleWorldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setWorld((event.target as HTMLInputElement).value);
    // };

    const validateAmount = (amount: string) => {
        if (type === 'deposit' && parseFloat(amount) < 100) {
            setErrors(prev => ({
                ...prev,
                gameGoldAmount: 'Minimum deposit amount is 100M'
            }));
            return false;
        }
        setErrors(prev => ({
            ...prev,
            gameGoldAmount: ''
        }));
        return true;
    };

    const handleGameGoldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setGameGoldAmount(value);
        const rate = getRate();
        setTokenAmount(value ? (parseFloat(value) / rate).toFixed(2) : '');
        validateAmount(value);
    };

    const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setTokenAmount(value);
        const rate = getRate();
        setGameGoldAmount(value ? (parseFloat(value) * rate).toFixed(2) : '');
    };

    const handleRsUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRsUsername(event.target.value);
    };

    const openChatWithMessage = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize();
            window.Tawk_API.setAttributes({
                name: session?.user?.user_metadata?.username
            }, function(error: any) {
                if (error) {
                    console.error('Error setting Tawk attributes:', error);
                } else {
                    // Send message after attributes are set successfully
                    window.Tawk_API.addEvent('gold-transaction', {
                        amount: type + " " + gameGoldAmount + 'M ' + method,
                        tokens: tokenAmount + ' Gold Tokens',
                        IGN: rsUsername
                    }, function(error: any) {
                        if (error) {
                            console.error('Error sending Tawk event:', error);
                        }
                    });
                }
            });
        } else {
            console.error('Tawk API not available');
        }
    };

    const handleSubmit = async () => {
        if (!validateAmount(gameGoldAmount)) {
            return;
        }

        try {
            const { data, error } = await supabase.rpc('create_gold_deposit', {
                p_type: type === 'deposit' ? 'deposit' : 'withdraw',
                p_game: method,
                p_amount: parseFloat(gameGoldAmount),
                p_coins: parseFloat(tokenAmount),
                v_player_id: session.user.id,
            });

            if (!error) {
                if(type === 'withdraw'){
                   await fetchBalances();
                }
                setInfoDialogOpen(true);
                onClose();
            } else {
                throw error;
            }

        } catch (error) {
            console.error('Error creating gold order:', error);
            toast.error('An error occurred while submitting the deposit request. Please try again.');
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        setTimeout(() => {
            window.location.replace('/exchanges');
        }, 200);
    };

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white', // This is the default unfocused label color
        },
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        {type === 'deposit' ? 'Deposit Gold' : 'Withdraw Gold'}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Select a Method:
                    </Typography>
                    <RadioGroup value={method} onChange={handleMethodChange}>
                        <FormControlLabel value="07 Gold" control={<Radio />} label="07 Gold" />
                        <FormControlLabel value="RS3 Gold" control={<Radio />} label="RS3 Gold" />
                    </RadioGroup>
                    {type === 'deposit' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        <TextField
                            fullWidth
                            type="number"
                            value={gameGoldAmount}
                            onChange={handleGameGoldChange}
                            label={`Amount in ${method}`}
                            variant="outlined"
                            error={!!errors.gameGoldAmount}
                            helperText={errors.gameGoldAmount}
                            sx={textFieldSx}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography component="span" sx={{ color: '#05f783', fontWeight: 'bold', mr: 0.5 }}>M</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    ): (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        <TextField
                            fullWidth
                            type="number"
                            value={tokenAmount}
                            onChange={handleTokenChange}
                            label="Amount in Gold tokens"
                            variant="outlined"
                            sx={textFieldSx}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <GoldCoin additionalStyles="mr-1" style={{ width: '20px', height: '20px' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    )}
                    <TextField
                        fullWidth
                        value={rsUsername}
                        onChange={handleRsUsernameChange}
                        label="RS Username"
                        variant="outlined"
                        sx={{ ...textFieldSx, mt: 2, mb: 2 }}
                    />
                    {/* <div className='flex items-center'>
                        <GoldCoin additionalStyles='mr-2' />
                        <Typography variant="body2">
                            <span className='text-xl'>
                                1 Gold token = {getRate().toFixed(2)}M {method}
                                {type === 'withdraw' && ' (including 8% fee)'}
                            </span>
                        </Typography>
                    </div> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button 
                            variant="contained" 
                            onClick={handleSubmit} 
                            disabled={!gameGoldAmount || !tokenAmount || !rsUsername}
                        >
                            {type === 'deposit' ? 'Deposit' : 'Withdraw'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Dialog
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        backgroundImage: `url(${background})`,
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                        border: '8px solid black',
                        borderImage: `url(${border}) 2 stretch`,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: 'text.primary', fontWeight: 'bold', textAlign: 'center', pt: 3 }}>
                    Gold Order Created Successfully!
                </DialogTitle>
                <DialogContent sx={{ px: 4, pb: 4 }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.2rem' }}>
                            Please continue with one of our vendors on the Exchanges page.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.2rem' }}>
                            Follow the directions associated with each vendor to continue the process.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        className='rs-button' 
                        variant='contained' 
                        onClick={handleInfoDialogClose} 
                        sx={{ 
                            bgcolor: 'secondary.main',
                            color: 'text.primary',
                            fontWeight: 'bold',
                            px: 3,
                            '&:hover': {
                                bgcolor: 'secondary.dark',
                            }
                        }} 
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GoldTransactionModal;