import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';

interface LeaderboardEntry {
  rank: number;
  username: string;
  silver_balance: number;
}

export function useSilverLeaderboard() {
    const [rows, setRows] = useState<LeaderboardEntry[]>([]);

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            try {
                const { data, error } = await supabase
                    .rpc('get_top_silver_balances')
                    .select();

                if (error) {
                    console.error('Error fetching silver leaderboard:', error);
                } else {
                    setRows(formatLeaderboardData(data || []));
                }
            } catch (err) {
                console.error('Error in silver leaderboard fetch:', err);
            }
        };

        fetchLeaderboardData();
    }, []);

    return rows;
}

// Helper function to format the leaderboard data
function formatLeaderboardData(data: any[]): LeaderboardEntry[] {
    return data.map(item => ({
        rank: item.rank,
        username: item.username || 'Hidden',
        silver_balance: parseFloat(item.silver_balance.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }));
}