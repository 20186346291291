import React, { Component } from 'react';
import { debounce } from "lodash";
import { GoldCoin, SilverCoin } from "../Coins";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import VolumeSlider from "../VolumeControl";
import SpeedControl from "../SpeedControl";

interface BettingWindowProps {
  bet: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBetBlur: () => void;
  handleHalfBet: () => void;
  handleDoubleBet: () => void;
  startGame: Function;
  signedIn?: boolean;
  gameStarted?: boolean;
  risk: string;
  handleRiskChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  rows: number;
  handleRowsChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disableModifications: boolean;
  numberOfGames?: number;
  onModeChange?: (mode: 'Manual' | 'Auto') => void;
  setNumberOfGames?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startAutoPlay?: () => void;
  stopAutoPlay?: () => void;
  autoBetTimeOut?: boolean;
  shake?: boolean;
  balanceType?: string;
  speed?: number;
  setSpeed?: Function;
}

interface BettingWindowState {
  mode: 'Manual' | 'Auto';
  isMediumOrLarger: boolean;
}

class PlinkoBettingWindow extends Component<BettingWindowProps, BettingWindowState> {
  constructor(props: BettingWindowProps) {
    super(props);
    this.state = {
      mode: 'Manual',
      isMediumOrLarger: window.innerWidth >= 768,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => { // Using lodash debounce function to limit the rate of state updates
    this.setState({ isMediumOrLarger: window.innerWidth >= 768 });
  }, 100);

  handleModeChange = (mode: 'Manual' | 'Auto') => {
    this.setState({ mode });
    this.props.onModeChange(mode);
  };


  render() {
    const isBetValid = /^\d*(\.\d{0,2})?$/.test(this.props.bet.toString()) && this.props.bet >= 1 && !this.props.gameStarted;
    const isAutoValid = this.props.numberOfGames > 0;
    const buttonBgClass = !isBetValid ? 'bg-gray-400 cursor-not-allowed' : '';
    const disableModificationsClass = this.props.disableModifications ? "cursor-not-allowed" : "cursor-pointer";
    const { mode } = this.state;

    return (
      <div
        className="betting-window"
        style={{
          border: "5px solid black",
          backgroundImage: `url(${background})`,
          borderImage: `url(${border}) 2 stretch`,
          backgroundSize: "cover",
        }}
        id="betting"
      >
        <div className="flex justify-center order-5 mt-2 mb-6 md:order-1">
          <div className="toggle-container">
            <input
              type="radio"
              id="manual"
              name="mode"
              value="Manual"
              checked={mode === 'Manual'}
              onChange={() => this.handleModeChange('Manual')}
              disabled={this.props.disableModifications}
            />
            <label htmlFor="manual" className={`toggle-label ${disableModificationsClass}`}>Manual</label>
            <input
              type="radio"
              id="auto"
              name="mode"
              value="Auto"
              checked={mode === 'Auto'}
              onChange={() => this.handleModeChange('Auto')}
              disabled={this.props.disableModifications}
            />
            <label htmlFor="auto" className={`toggle-label ${disableModificationsClass}`}>Auto</label>
            <div className="toggle-slider"></div>
          </div>
        </div>
        <div className="order-1 md:order-2">
          <p>Amount</p>
          <div className="flex items-center justify-center w-full">
            <div className="relative flex w-full">
              <input
                data-testid='bet-amount'
                autoComplete="on"
                type="number"
                value={this.props.bet === 0 ? '' : this.props.bet}
                onChange={this.props.handleAmountChange}
                onBlur={this.props.handleBetBlur}
                placeholder="Enter bet"
                className={`w-full ${disableModificationsClass}`}
                min="1"
                max="1000"
                disabled={this.props.disableModifications}
              ></input>
              {this.state.isMediumOrLarger && (
                this.props.balanceType === "gold" ? (
                  <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                ) : (
                  <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                )
              )}
            </div>
            <button onClick={this.props.handleHalfBet} className={`rs-button-small flex ml-2 px-2 pt-1 ${disableModificationsClass}`} disabled={this.props.disableModifications}>
              <text>½</text>
            </button>
            <button onClick={this.props.handleDoubleBet} className={`rs-button-small flex ml-2 px-2 pt-1 ${disableModificationsClass}`} disabled={this.props.disableModifications}>
              <text>2x</text>
            </button>
          </div>
        </div>
        {mode === 'Manual' ? (
          <button
            data-testid="play-button"
            onClick={() => this.props.startGame()}
            disabled={!isBetValid || !this.props.signedIn || this.props.gameStarted || this.props.autoBetTimeOut}
            className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''} order-3 md:order-4`}
          >
            {this.props.signedIn ? this.props.balanceType === "gold" ? "Bet" : "Free Play" : "Sign In To Play"}
          </button>
        ) : (
          <>
            {this.props.gameStarted ? (
              <button
                data-testid="play-button"
                onClick={this.props.stopAutoPlay}
                className={`mines-start-btn ${buttonBgClass} order-[-1] md:order-4`}
                disabled={false}
              >
                Stop Autobet
              </button>
            ) : (
              <button
                data-testid="play-button"
                onClick={this.props.startAutoPlay}
                disabled={!isBetValid || !isAutoValid || !this.props.signedIn || this.props.gameStarted || this.props.autoBetTimeOut}
                className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''} order-[-1] md:order-4`}
              >
                {this.props.signedIn ? "Start Autobet" : "Sign In To Play"}
              </button>
            )}
          </>
        )}
        <div className="flex flex-col items-left justify-center w-full mt-2 order-4 md:order-2">
          <p>Risk</p>
          <select id="risk" value={this.props.risk} onChange={this.props.handleRiskChange} disabled={this.props.disableModifications} className={`text-primary-900 p-2 rounded-lg w-full ${disableModificationsClass} `}>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
        <div className="flex flex-col items-left justify-center w-full mt-2 order-4 md:order-2">
          <p>Rows</p>
          <select id="rows" value={this.props.rows} onChange={this.props.handleRowsChange} disabled={this.props.disableModifications} className={`text-primary-900 p-2 rounded-lg w-full ${disableModificationsClass} `}>
            <option value="8">8</option>
            <option value="10">10</option>
            <option value="12">12</option>
            <option value="14">14</option>
            <option value="16">16</option>
          </select>
        </div>
        {mode === 'Auto' && (
          <div className="flex flex-col items-left justify-center w-full mt-2 order-2 md:order-2">
            <p>Number of Games</p>
            <input
              type="number"
              placeholder="Enter number of games"
              value={this.props.numberOfGames === 0 ? '' : this.props.numberOfGames}
              onChange={this.props.setNumberOfGames}
              className={` text-primary-900 rounded-lg w-full ${disableModificationsClass}`}
              disabled={this.props.disableModifications}
            />
          </div>
        )}
        <VolumeSlider gameId="plinko" disableModification={this.props.disableModifications} additionalStyles="absolute bottom-2.5 left-2.5" />
        <SpeedControl gameId="plinko" disableModification={this.props.disableModifications} speed={this.props.speed} setSpeed={this.props.setSpeed} maxSpeed={2} additionalStyles="absolute bottom-2.5 right-2.5"/>
      </div>
    );
  }
}

export default PlinkoBettingWindow;