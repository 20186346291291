import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import GeneralBettingWindow from "../GeneralBettingWindow";
import EnhancedWinningsComponent from "../EnhancedWinningsComponent";
import withFadeInDelay from "../withFadeInDelay";
import { supabase, fetchAndUpdateBalance } from "../../api/supabaseClient.js";
import Slider from '@mui/material/Slider';
import "./Dice.css";
import Odds from "./Odds.jsx";
import RollArrow from "./RollArrow.jsx";
import thumbImage from "../../assets/zoom_slider_thumb.png";
import tickSound from "../../assets/tick.mp3";
import rollSound from "../../assets/dice_roll.mp3";
import { toast } from 'react-toastify';
import bankpinfail from '../../assets/bankpinfail.mp3';
import { useVolume } from '../../contexts/VolumeContext'; // Import useVolume hook
import { useBet } from '../../hooks/useBet'; // Import useBet hook
import BettingWindow from "../BettingWindow"; //Removing this line somehow breaks everything lol
import confetti from 'canvas-confetti';

const FadedInWinningsComponent = withFadeInDelay(EnhancedWinningsComponent, 50);

const calculateMultiplier = (value) => {
  const houseEdge = 0.04;
  const p = value / 100;
  const baseMultiplier = 1 + (-houseEdge + p) / (1 - p);
  return baseMultiplier.toFixed(4);
};

const Dice = () => {
  const {
    bet,
    setBet,
    handleBetChange: handleMainBetChange,
    handleBetBlur: handleMainBetBlur,
    handleHalfBet: handleMainHalfBet,
    handleDoubleBet: handleMainDoubleBet,
  } = useBet(0);

  const [value, setValue] = useState(50);
  const [multiplier, setMultiplier] = useState(calculateMultiplier(50));
  const [gameStarted, setGameStarted] = useState(false);
  const [roll, setRoll] = useState(100);
  const [winnings, setWinnings] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(0);

  const [mode, setMode] = useState('Manual');
  const [autoPlayIntervalId, setAutoPlayIntervalId] = useState(null);
  const [numberOfGames, setNumberOfGames] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const isActiveRef = useRef(false);

  const sliderRef = useRef(null);
  const tickAudio = useRef(new Audio(tickSound));
  const rollAudio = useRef(new Audio(rollSound));

  const { session, signedIn } = useAuth();
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();
  const { volumes, mutedStates, setActiveGame } = useVolume(); // Use the useVolume hook to get volumes

  const [shake, setShake] = useState(false);
  const bankPinSound = new Audio(bankpinfail);

  const moneyRef = useRef(getActiveBalance());

  useEffect(() => {
    moneyRef.current = getActiveBalance();
  }, [getActiveBalance()]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setNumberOfGames(0);
  };
  const startGame = async () => {
    if (gameStarted || isActiveRef.current) {
      if (bet > moneyRef.current) {
        toast.error("Insufficient balance. Bet cannot be higher than balance.");
        bankPinSound.play();
        setGameStarted(false);
        isActiveRef.current = false;
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return false;
      }
      const { data, error } = await supabase.rpc("start_dice_game", {
        v_user_id: session?.user.id,
        v_bet: bet,
        v_target: value,
        v_type: balanceType
      });
      if (error) {
        console.error(error);
        return false
      }
      setRoll(data[0].roll);
      setWinnings(data[0].winnings);
      setGameStarted(false);
      await fetchBalances();
      setGameOver(true);
      confetti.reset();
      return true;
    }
  };
  const startAutoPlay = async () => {
    isActiveRef.current = true;
    setGameStarted(true);
    const success = await startGame();
    if(!success) return;
    let gamesCount = 1;
    const intervalId = setInterval(async () => {
      if (gamesCount >= numberOfGames || !isActiveRef.current || bet > moneyRef.current) {
        clearInterval(intervalId);
        setAutoPlayIntervalId(null);
        setGameStarted(false);
        setGameOver(true);
        isActiveRef.current = false;
        if (bet > moneyRef.current) {
          toast.error("Insufficient balance. Bet cannot be higher than balance.");
          bankPinSound.play();
          setShake(true);
          setTimeout(() => {
            setShake(false);
          }, 200);
        }
        return;
      }
      setGameStarted(true);
      setGameOver(false);
      await startGame();
      gamesCount++;
      setGamesPlayed(gamesCount);
    }, 1000); // Adjust interval as needed

    setAutoPlayIntervalId(intervalId);
  };
  const stopAutoPlay = () => {
    if (autoPlayIntervalId) {
      clearInterval(autoPlayIntervalId);
      setAutoPlayIntervalId(null);
      isActiveRef.current = false;
      setGameStarted(false);
      setGameOver(true);
    }
  };

  useEffect(() => {
    setMultiplier(calculateMultiplier(value));
  }, [value]);

  const handleSliderChange = (event, newValue) => {
    tickAudio.current.volume = mutedStates["dice"] ? 0 : volumes["dice"] || 1; // Set the volume for tick sound
    tickAudio.current.play(); // Play the tick sound
    if (newValue < 5) {
      setValue(5);
    } else if (newValue > 99) {
      setValue(99);
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (!isActiveRef.current) {
      setGameOver(false);
      startGame();
    }
  }, [gameStarted]);
  useEffect(() => {
    return () => {
      if (autoPlayIntervalId) {
        clearInterval(autoPlayIntervalId);
      }
    };
  }, [autoPlayIntervalId]); // Cleanup on component unmount

  useEffect(() => {
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const markerWidth = 20; // Adjust this value based on your marker's width
      const newPosition = (roll / 100) * sliderWidth - markerWidth / 2;
      rollAudio.current.currentTime = 0; // Reset the audio playback
      rollAudio.current.volume = mutedStates["dice"] ? 0 : volumes["dice"] || 1; // Set the volume for roll sound
      rollAudio.current.play(); // Play the roll sound
      setMarkerPosition(newPosition);
    }
  }, [roll]);

  useEffect(() => {
    setDisableModifications(isActiveRef.current);
  }, [isActiveRef.current]);
  useEffect(() => {
    setActiveGame('dice');
    return () => {
      setDisableModifications(false);
    }
  }, []);

  const marks = [
    { value: 0, label: '0' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
  ];

  return (
    <div className="main-container">
      <GeneralBettingWindow
        bets={[
          {
            name: "Amount",
            bet: bet,
            handleAmountChange: handleMainBetChange,
            handleBlur: handleMainBetBlur,
            handleHalfBet: handleMainHalfBet,
            handleDoubleBet: handleMainDoubleBet,
            valid: bet > 0,
            winnings: winnings,
          }]}
        startGame={() => setGameStarted(true)}
        signedIn={signedIn}
        gameStarted={isActiveRef.current}
        gameOver={gameOver}
        autoPlay={true}
        numberOfGames={numberOfGames}
        onModeChange={(newMode) => handleModeChange(newMode)}
        setNumberOfGames={(e) => { setNumberOfGames(parseInt(e.target.value)) }}
        startAutoPlay={startAutoPlay}
        stopAutoPlay={stopAutoPlay}
        shake={shake}
        balanceType={balanceType}
        gameId="dice"
      />
      <div className="game-container border-2 bg-primary-500 flex items-center justify-center w-full relative">
        {gameOver && (
          <FadedInWinningsComponent
            winnings={winnings}
            push={false}
            gameOver={gameOver}
            shouldPlaySound={true}
            additionalStyles={'w-24 h-24'}
          />
        )}
        <div className="p-6 w-full mx-8">
          <div className="w-full space-y-12" ref={sliderRef}>
            <Slider
              value={value}
              min={0}
              max={100}
              step={1}
              onChange={handleSliderChange}
              marks={marks}
              valueLabelDisplay="on"
              track={false}
              disabled={isActiveRef.current}
              aria-labelledby="continuous-slider"
              sx={{
                '& .MuiSlider-thumb': {
                  backgroundColor: 'transparent',
                  width: 36,
                  height: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::before': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${thumbImage})`,
                    backgroundSize: 'cover',
                    borderRadius: 0, // Remove border-radius to avoid cutting off the image
                  },
                  '&:hover, &:focus, &:active': {
                    boxShadow: 'none', // Remove hover effect
                  },
                },
                '& .MuiSlider-rail': {
                  background: `linear-gradient(to right, hsla(0, 100%, 25%, 1), hsla(0, 100%, 50%, 1) ${value}%, hsla(120, 100%, 50%, 1) ${value}%, hsla(120, 100%, 25%, 1))`,
                  width: '100%',
                  height: '28px',
                  opacity: 1,
                  borderRadius: 0, // Make the rail square
                },
                '& .MuiSlider-mark': {
                  width: 2,
                  height: 24,
                  backgroundColor: 'white',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                },
                '& .MuiSlider-markLabel': {
                  color: 'white',
                  fontSize: '2rem',
                  fontFamily: 'CustomFont, system-ui', // Apply custom font
                },
                '& .MuiSlider-valueLabel': {
                  fontFamily: 'CustomFont, system-ui', // Apply custom font
                  fontSize: '2rem',
                },
              }}
            />
            <div
              className="marker"
              style={{
                left: `${markerPosition}px`,
                transition: 'left 1s ease-in-out',
              }}
            >
              <div className="marker-label">
                {roll}
              </div>
            </div>
            <Odds value={value} multiplier={multiplier} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dice;