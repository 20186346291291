import React, { useEffect, useState } from 'react';
import PayoutDisplay from '../Ultimate-Texas/PayoutDisplay';

export default function SidebetsDisplay() {
    const twentyOnePlusThree = [
        "S Three of a Kind: 100 to 1",
        "Straight Flush: 50 to 1",
        "Three of a Kind: 33 to 1",
        "Straight:	10 to 1",
        "Flush:	5 to 1"
    ]
    const perfectPairs = [
        "Perfect Pair: 30 to 1",
        "Colored Pair: 12 to 1",
        "Mixed Pair: 5 to 1"
    ]

    return (
        <>
            <div className="hidden lg:block absolute bottom-0 left-0 ml-4 mb-4 flex flex-col items-center justify-center z-10">
                <PayoutDisplay name="21+3" payouts={twentyOnePlusThree} />
            </div>
            <div className="hidden lg:block absolute bottom-0 right-0 mr-4 mb-4 flex flex-col items-center justify-center z-10">
                <PayoutDisplay name="Perfect Pairs" payouts={perfectPairs} additionalStyles='min-w-[237.43px] min-h-[200px] px-2' />
            </div>
        </>
    )
}