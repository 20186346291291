import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, InputAdornment } from '@mui/material';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { useBalance } from '../../contexts/BalanceContext';
import { toast } from 'react-toastify';
import { GoldTransactionData } from '../../hooks/useGoldTransactions';
import { GoldCoin } from '../Coins';

interface ClaimGoldWithdrawalModalProps {
    open: boolean;
    onClose: () => void;
    withdrawal: GoldTransactionData;
}

const ClaimGoldWithdrawalModal: React.FC<ClaimGoldWithdrawalModalProps> = ({ open, onClose, withdrawal }) => {
    const { session } = useAuth();
    const [goldAmount, setGoldAmount] = useState(withdrawal.amount.toString());
    const { fetchBalances } = useBalance();
    const handleGoldAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGoldAmount(event.target.value);
    };

    const handleClaim = async () => {
        try {
            const { data, error } = await supabase.rpc('claim_gold_withdrawal', {
                p_withdrawal_id: withdrawal.id,
                v_vendor_id: session.user.id,
                p_amount: parseFloat(goldAmount)
            });

            if (!error) {
                toast.success('Withdrawal claimed successfully.');
                
                onClose();
                await fetchBalances();
            } else {
                throw error;
            }
        } catch (error) {
            console.error('Error claiming withdrawal:', error);
            toast.error('An error occurred while claiming the withdrawal. Please try again.');
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Claim Gold Withdrawal
                </Typography>
                <TextField
                    fullWidth
                    value={goldAmount}
                    onChange={handleGoldAmountChange}
                    label={`Amount in ${withdrawal.game}`}
                    variant="outlined"
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography component="span" sx={{ color: '#05f783', fontWeight: 'bold', mr: 0.5 }}>M</Typography>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    value={withdrawal.coins}
                    label="Gold Token Amount"
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <GoldCoin style={{ width: '20px', height: '20px' }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ mb: 2 }}
                />
                
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleClaim}>
                        Claim
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ClaimGoldWithdrawalModal;