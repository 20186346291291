import React, { Component } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import '../App.css';
import background from "../assets/background.png";
import border from "../assets/borders.png";

interface BettingWindowProps {
  bet: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  startGame: Function;
  handleHalfBet: () => void;
  handleDoubleBet: () => void;
  gameStarted?: boolean;
  shake?: boolean;
}
class BettingWindow extends Component<BettingWindowProps> {
  render() {
    const isBetValid = /^\d*(\.\d{0,2})?$/.test(this.props.bet.toString()) && this.props.bet >= 1 && this.props.bet <= 1000 && !this.props.gameStarted;
    const buttonBgClass = !isBetValid ? "bg-disabled"
      : "bg-enabled hover:bg-enabled";
    return (
      <div
        className="betting-window"
        style={{
          border: "5px solid black",
          backgroundImage: `url(${background})`,
          borderImage: `url(${border}) 2 stretch`,
          backgroundSize: "cover",
        }}
        id="betting"
      >
        <p>Amount</p>
        <div className="flex items-center justify-center w-full mb-2">
          <input
            data-testid='bet-amount'
            autoComplete="on"
            type="number"
            value={this.props.bet === 0 ? '' : this.props.bet}
            onChange={this.props.handleAmountChange}
            placeholder="Enter bet"
            min="1"
            max="1000"
            className=""
          ></input>
          <button onClick={this.props.handleHalfBet} className="rs-button-small hidden sm:flex ml-2 px-2 pt-1">
            <text>½</text>
          </button>
          <button onClick={this.props.handleDoubleBet} className="rs-button-small hidden sm:flex ml-1 px-2 py-1">
            <text>2x</text>
          </button>
        </div>
        <div className="flex w-full items-center justify-center">
          <button
            data-testid='play-button'
            onClick={() => this.props.startGame()}
            disabled={!isBetValid}
            className={`normal-button ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''}`}
          >
            Start Game
          </button>
        </div>
      </div>
    );
  }
}

export default BettingWindow;
