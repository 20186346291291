import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../api/supabaseClient.js';
import { useAuth } from '../contexts/AuthContext.js';

export const useVendorStatus = () => {
  const { session } = useAuth();
  const [isVendor, setIsVendor] = useState(false);
  const [isGoldVendor, setIsGoldVendor] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkUserRole = useCallback(async (userId) => {
    if (!userId) return false;

    try {
      const { data, error } = await supabase.rpc('get_user_role', { p_user_id: userId });
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error checking vendor status:', error);
      return false;
    }
  }, []);

  useEffect(() => {
    const checkVendorStatus = async () => {
      if (session?.user?.id) {
        const userRole = await checkUserRole(session.user.id);
        if (userRole === 'vendor') {
          setIsVendor(true);
        } else if (userRole === 'rsvendor') {
          setIsGoldVendor(true);
        }
      }
      setLoading(false);
    };

    checkVendorStatus();
  }, [session, checkUserRole]);

  return { isVendor, isGoldVendor, loading };
};