import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

export interface PaymentData {
  id: number;
  user_id: string;
  invoice_id: string;
  invoice_link: string;
  amountusd: number;
  coins: number;
  status: string;
  created_at: string;
  // Add any other fields that exist in your payments table
}
function formatDateTime(isoString: string): string {
    const date = new Date(isoString);
    
    // Format date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    
    // Format time
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

export function usePayments() {
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const { session } = useAuth();
  const fetchPaymentData = async () => {
    const { data, error } = await supabase
      .from('payments')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(20);
    if (error) {
      console.error('Error fetching payments:', error);
    } else {
      setPayments(data.map((payment: PaymentData) => ({
        ...payment,
        created_at: formatDateTime(payment.created_at)
      })));
    }
  };

  useEffect(() => {
    // Function to fetch payment data
    // Fetch initial data
    fetchPaymentData();

  }, []);

  return {payments, fetchPaymentData};
}