import React from 'react';
import background from '../assets/background.png';
import logo from '../assets/newlogomaybe2.png';

interface FooterProps {
  removeTopMargin?: boolean;
}

const Footer: React.FC<FooterProps> = ({ removeTopMargin = false }) => {
    return (
        <footer className={`bg-primary-900 text-white py-4 text-center w-full ${removeTopMargin ? '' : 'mt-20'}`} style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container mx-auto px-4">
                <div className="flex justify-center items-center">
                    <img src={logo} alt="Company Logo" className="h-12 mr-4 mb-2" style={{ height: 'auto', width: '100%', maxWidth: '90px', scale: '1.4' }} />
                    <p className="">&copy; 2024 Sand Casino. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
