import React from "react";

export default function Balls() {
  return (
    <>
      <div
        id="ball00"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0   translate-y-[90px] translate-x-[73px]"
      >
      </div>
      <div
        id="ball0"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[90px] -translate-x-[70px]"
      >
      </div>
      <div
        id="ball1"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 translate-y-[75px] translate-x-[87px]"
      >
      </div>
      <div
        id="ball2"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[76px] -translate-x-[85px]"
      >
      </div>
      <div
        id="ball3"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 translate-y-[6px] translate-x-[113px]"
      >
      </div>
      <div
        id="ball4"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[8px] -translate-x-[115px]"
      >
      </div>
      <div
        id="ball5"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[65px] translate-x-[95px]"
      >
      </div>
      <div
        id="ball6"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 translate-y-[65px] -translate-x-[95px]"
      >
      </div>
      <div
        id="ball7"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[107px] translate-x-[35px]"
      >
      </div>
      <div
        id="ball8"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 translate-y-[108px] -translate-x-[34px]"
      >
      </div>
      <div
        id="ball9"
        class="absolute w-4 h-4 bg-white rounded-full  opacity-0 -translate-y-[107px] -translate-x-[38px]"
      >
      </div>
      <div
        id="ball10"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[108px] translate-x-[40px]"
      >
      </div>
      <div
        id="ball11"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[112px] translate-x-[17px]"
      >
      </div>
      <div
        id="ball12"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[113px] -translate-x-[16px]"
      >
      </div>
      <div
        id="ball13"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[60px] translate-x-[98px]"
      >
      </div>
      <div
        id="ball14"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[60px] -translate-x-[95px]"
      >
      </div>
      <div
        id="ball15"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[12px] translate-x-[113px]"
      >
      </div>
      <div
        id="ball16"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[11px] -translate-x-[115px]"
      >
      </div>
      <div
        id="ball17"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[80px] translate-x-[84px]"
      >
      </div>
      <div
        id="ball18"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[80px] -translate-x-[83px]"
      >
      </div>
      <div
        id="ball19"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[100px] -translate-x-[50px]"
      >
      </div>
      <div
        id="ball20"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[100px] translate-x-[52px]"
      >
      </div>
      <div
        id="ball21"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[48px] -translate-x-[105px]"
      >
      </div>
      <div
        id="ball22"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[50px] translate-x-[105px]"
      >
      </div>
      <div
        id="ball23"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0 -translate-y-[27px] -translate-x-[113px]"
      >
      </div>
      <div
        id="ball24"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[25px] translate-x-[110px]"
      >
      </div>
      <div
        id="ball25"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[113px] translate-x-[22px]"
      >
      </div>
      <div
        id="ball26"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[112px] -translate-x-[20px]"
      >
      </div>
      <div
        id="ball27"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[100px] translate-x-[58px]"
      >
      </div>
      <div
        id="ball28"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[100px] -translate-x-[55px]"
      >
      </div>
      <div
        id="ball29"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[115px] translate-x-[3px]"
      >
      </div>
      <div
        id="ball30"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[115px] -translate-x-[2px]"
      >
      </div>
      <div
        id="ball31"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[90px] -translate-x-[67px]"
      >
      </div>
      <div
        id="ball32"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[90px] translate-x-[68px]"
      >
      </div>
      <div
        id="ball33"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[30px] -translate-x-[110px]"
      >
      </div>
      <div
        id="ball34"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[32px] translate-x-[110px]"
      >
      </div>
      <div
        id="ball35"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  -translate-y-[45px] -translate-x-[105px]"
      >
      </div>
      <div
        id="ball36"
        class="absolute w-4 h-4 bg-white rounded-full opacity-0  translate-y-[43px] translate-x-[105px]"
      >
      </div>
    </>
  );
}
