import { useEffect, useState } from "react";


export default function withFadeInDelay(Component: React.ComponentType<any>, delay?:number) {
  return function FadeInWrapper(props: any) {
    const [show, setShow] = useState(false);
    delay = delay || 1000;
    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(true);
      }, delay);

      return () => clearTimeout(timer);
    }, []);

    const fadeInClass = `z-10 transition-opacity duration-1000 ${show ? "opacity-100" : "opacity-0"}`;

    return <div className={fadeInClass}>
      <Component {...props} />
    </div>;
  }
}

