export const preloadCardImages = () => {
    return new Promise((resolve, reject) => {
      const suits = ["hearts", "diamonds", "clubs", "spades"];
      const values = [
        "ace", "2", "3", "4", "5", "6", "7", "8", "9", "10", "jack", "queen", "king",
      ];
      const images = {};
  
      let loadedCount = 0;
      const totalImages = suits.length * values.length + 1; // 52 cards + 1 back card
  
      suits.forEach((suit) => {
        values.forEach((value) => {
          const img = new Image();
          img.onload = () => {
            loadedCount++;
            if (loadedCount === totalImages) {
              resolve(images);
            }
          };
          img.onerror = reject;
          img.src = `/cards/${suit}_${value}.svg`;
          images[`card_${suit}_${value}`] = img;
        });
      });
  
      const backImg = new Image();
      backImg.onload = () => {
        loadedCount++;
        if (loadedCount === totalImages) {
          resolve(images);
        }
      };
      backImg.onerror = reject;
      backImg.src = "/cards/back_card.svg";
      images["card_back"] = backImg;
    });
  };
