import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import { GameData } from '../objects/GameData';

export function useMyGames() {
    const [rows, setRows] = useState<GameData[]>([]);
    const [user, setUser] = useState<any>(null);
    const { session } = useAuth();

    useEffect(() => {
        // Get the current user
        const getCurrentUser = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            setUser(user);
        };

        getCurrentUser();
    }, []);

    useEffect(() => {
        if (!user) return;

        // Function to fetch initial data for the current user
        const fetchInitialData = async () => {
            const { data, error } = await supabase
                .from('recent_bets')
                .select('*')
                .eq('username', session?.user?.user_metadata?.username)
                .order('time', { ascending: false })
                .limit(10);

            if (error) {
                console.error('Error fetching user games:', error);
            } else {
                setRows(formatGameData(data));
            }
        };

        // Function to handle real-time updates
        const handleRealtimeUpdate = (payload: any) => {
            if (payload.new.user_id === user.id) {
                setRows(currentRows => {
                    const updatedRows = [formatGameData([payload.new])[0], ...currentRows.slice(0, 9)];
                    return updatedRows;
                });
            }
        };

        // Fetch initial data
        fetchInitialData();

        // Set up real-time subscription
        const subscription = supabase
            .channel('user_bets_changes')
            .on('INSERT', handleRealtimeUpdate)
            .subscribe();

        // Cleanup function
        return () => {
            subscription.unsubscribe();
        };
    }, [user]);

    return rows;
}

// Helper function to format the game data
function formatGameData(data: any[]): GameData[] {
    return data.map(item => ({
        game: item.game,
        user: item.hide_username ? 'Hidden' : (item.username || 'Hidden'),
        time: new Date(item.time).toLocaleTimeString(),
        amount: item.amount.toFixed(2),
        multiplier: item.multiplier.toFixed(2) + 'x',
        result: item.result.toFixed(2),
        type: item.type
    }));
}